.window {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	--padding: 0.8rem 1.5rem 0 2rem;
}

.window__inner {
	background: white;
	height: 100vh;
	max-width: 1100px;
	position: relative;
	overflow-y: auto;

	padding-top: 40px;
}

.window__body {
	padding: var(--padding);
}

.window__close {
	--close-width: 3rem;
	position: absolute;
	top: 40px;
	right: 0;
	width: var(--close-width);
	height: var(--close-width);
}

.window__close:hover {
	text-decoration: none;
}

.window__close::before {
	content: '\00d7';
	font-family: var(--font-family-serif);
	font-size: var(--font-size-ml);
	width: var(--close-width);
	height: var(--close-width);
	display: flex;
	justify-content: center;
	align-items: center;
}

.window .newsletter-teaser {
	--font-size-xl: var(--font-size-ll);
	--font-size-xll: var(--font-size-ll);
	margin: 0;
}

.window .newsletter-teaser {
	margin-bottom: -.6rem;
	padding-right: 10px;
}

/* Newsletter Overlay */
.newsletter-overlay iframe {
	height: 80vh;
	min-height: 800px;
}

@media (min-width: 768px) {
	.window {
		--padding: 1rem 3.5rem 0 2rem;
	}

	.newsletter-overlay iframe {
		height: 500px;
	}

	.window__inner {
		box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
		padding-top: 0;
		height: auto;
		max-height: 100vh;
	}

	.window__close {
		--close-width: 4rem;
		top: 0;
	}

	/* Newsletter Overlay */
	.newsletter-overlay iframe {
		min-height: 500px;
	}
}

@media (min-width: 1921px) {
	.window__inner {
		max-width: max(1100px, 50vw);
	}
}
