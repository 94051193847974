.showrooms .image figcaption {
	text-align: left;
	font-weight: var(--font-weight-medium);
	font-size: 18px;
}

.showrooms .image {
	margin: calc(var(--figcaption-spacer) * 2) 0 0 0;
}

.showrooms .image img {
	width: 100%;
	aspect-ratio: 570 / 920; /* 570 x 920px */
	object-fit: cover;
}

/* exception showroom h1 is visible */
.showroom-heading .h1.h1 {
	display: block;
}

/* fallback for aspect-ratio */
@supports not (aspect-ratio: 570 / 920) {
	.showrooms .image-inner {
	    padding-bottom: calc(920 / 570 * 100%);
	    position: relative;
	    height: 0;
	    overflow: hidden;
	}

	.showrooms .image img {
	    width: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    height: 100%;
	}
}

@media (min-width: 1921px) {
	.showrooms .image figcaption {
		font-size: max(18px, 0.9375vw);
	}
}
