.download-list {
	--font-cut: var(--tile-header-font-cut-m);
}

.download-list .download-list-item {
	position: relative;
	left: 1em;
	top: -1.3em;
	width: calc(100% - 1em);
}

.download-list .download-list-item span {
	position: relative;
	font-size: var(--font-size-xm);
}

.download-list .download-list-item figure {
	margin-top: var(--font-cut);
}

.download-list .link-big {
	font-size: var(--font-size-xm);
}
