/* mobile */
:root {
	--spacer-xxl: 7.5rem; /* 120px */
	--spacer-xl: 5rem; /* 80px */
	--spacer-l: 3.75rem; /* 60px */
	--spacer-m: 2.5rem; /* 40px */
	--spacer-s: 2.188rem; /* 35px */
	--spacer-xs: 1.875rem; /* 30px */
	--spacer-xxs: 1.25rem; /* 20px */

	/* 20px | 30px | 60px */ --spacer-responsive: var(--spacer-xxs); /* the only spacer that changes via @media */
	/* 80px | 112px | 120px */ --spacer-responsive-l: var(--spacer-xl); /* the only spacer that changes via @media */

	--spacer-font-based: 1em;
	--spacer-font-based-s: .5em;

	--padding: 1.125rem; /* 18px */ /* @deprecated */
	--padding-s: .625rem; /* 10px */ /* @deprecated */

	--page-padding: var(--spacer-responsive);
	--page-max-width: 1440px;
	--page-content-max-width: calc(var(--page-max-width) - var(--page-padding) * 2);

	/* colors */
	--color-bg: #fff;
	--color-fg: #000;
	--color-light-text: #c8c8c8;
	--color-border: var(--color-fg);

	/* arrows */
	--arrow-offset: .2em;
	--arrow-b64: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NTQgMzcyIj4KICA8Zz4KICAgIDxwYXRoIGQ9Ik0yNjkgMGwtNDMgNDYgMTEwIDEwOUgwdjY0aDMzNEwyMjYgMzI3bDQzIDQ1IDE4NS0xODZMMjY5IDB6Ii8+CiAgPC9nPgo8L3N2Zz4K);
	--arrow-b64-white: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NTQgMzcyIj4KICA8Zz4KICAgIDxwYXRoIGZpbGw9IiNjOGM4YzgiIGQ9Ik0yNjkgMGwtNDMgNDYgMTEwIDEwOUgwdjY0aDMzNEwyMjYgMzI3bDQzIDQ1IDE4NS0xODZMMjY5IDB6Ii8+CiAgPC9nPgo8L3N2Zz4K);
	--arrow-b64-back: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDAyIiB3aWR0aD0iNDgzLjIiPjxnPjxwYXRoIGQ9Ik0yNTAuMjUgMzg3bDM4LjM4LTQzLjgtOTguMDgtMTEwLjRINDgzLjJ2LTY0LjJIMTkxLjA4bDk3LjU1LTEwOS44TDI1MC4yNSAxNSA4NSAyMDF6TTQ1IDB2NDAySDBWMHoiLz48L2c+PC9zdmc+Cg==);

	/* font */
	--font-family-sans: 'Suisse Intl', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	--font-family-serif: 'Noto Serif KR', serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

	/*--font-family-sans: 'Suisse Intl', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';*/
	/*--font-family-serif: 'Suisse Works', serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';*/

	--font-family-sans-light: var(--font-family-sans);

	--font-weight-light: 300;
	--font-weight-normal: 400;
	--font-weight-medium: 500;
	--font-weight-bold: 600;
	--font-weight-black: 900;

	/* 42px | 42px | 42px */ --font-size-xxxl: 2.625rem; /*@deprecated */
	/* 26px | 40px | 58px */ --font-size-xxl: 1.625rem; /*@deprecated */
	/* 22px | 26px | 52px */ --font-size-xll: 1.375rem; /*@deprecated */
	/* 18px | 24px | 48px */ --font-size-xl: var(--font-size-m); /*@deprecated */
	/* 22px | 26px | 39px */ --font-size-ll: 1.375rem; /*@deprecated */
	/* 20px | 20px | 20px */ --font-size-ml: 1.25rem; /*@deprecated */
	/* 18px | 24px | 36px */ --font-size-l: var(--font-size-m); /*@deprecated */
	/* 18px | 20px | 26px */ --font-size-xxm: var(--font-size-m); /*@deprecated */
	/* 18px | 18px | 20px */ --font-size-xm: var(--font-size-m); /*@deprecated */
	/* 18px | 16px | 18px */ --font-size-m: 1.125rem; /*@deprecated */
	/* 16px | 16px | 14px */ --font-size-s: 1rem; /*@deprecated */
	/* 14px | 16px | 16px */ --font-size-xs: .875rem;

	--h1-font-size: 1.625rem; /* 26px | 40px | 58px */
	--h1-line-height: 1.625rem; /* 26px */
	--h1-padding-y: 20px;

	--h2-font-size: 18px;
	--h2-line-height: 22px;

	--h3-font-size: 18px;
	--h3-line-height: 22px;
	--h3-margin-bottom: var(--spacer-s);

	--text-font-size: 16px; /* 16px | 18px | 18px */
	--text-line-height: 1.16;

	--figcaption-font-size: 14px;
	--figcaption-line-height: 1.3;
	--figcaption-spacer: 15px;

	--centered-content-width: 100%;
}

/* tablet */
@media (min-width: 768px) {
	:root {
		--font-size-xxl: 2.5rem; /* 40px */
		--font-size-xll: 1.625rem; /* 26px */
		--font-size-xl: 1.5rem; /* 24px  */
		--font-size-ll: 1.625rem; /* 26px */
		--font-size-l: 1.5rem; /* 24px */
		--font-size-xxm: 1.25rem; /* 20px */
		--font-size-xm: 1.125rem; /* 18px (navigation) */
		--font-size-m: 1rem; /* 16px */
		--font-size-s: 1rem; /* 16px */
		--font-size-xs: 1rem; /* 16px */

		--spacer-responsive: var(--spacer-xs);
		/* 80px | 112px | 120px */ --spacer-responsive-l: 112px; /* the only spacer that changes via @media */

		--h1-font-size: 2.5rem; /* 40px */
		--h1-line-height: 2.5rem;

		--h2-font-size: 24px;
		--h2-line-height: 28px;

		--h3-font-size: 24px;
		--h3-line-height: 28px;
		--h3-margin-bottom: var(--spacer-l);

		--text-font-size: 18px;

		--heading-width: calc(100% - 100% / 6 * 4);

		--centered-content-width: calc(100% / 6 * 4);
	}
}

/* desktop */
@media (min-width: 1200px) {
	:root {
		--font-size-xxl: 3.625rem; /* 58px */
		--font-size-xll: 3.25rem; /* 52px */
		--font-size-xl: 3rem; /* 48px */
		--font-size-ll: 2.438rem; /* 39px */
		--font-size-l: 2.25rem; /* 36px */
		--font-size-xxm: 1.625rem; /* 26px (sollten 28px) */
		--font-size-xmm: 1.5rem; /* 24px (navigation) */
		--font-size-xm: 1.25rem; /* 20px (navigation) */
		--font-size-m: 1.125rem; /* 18px */
		--font-size-s: .875rem; /* 14px */

		--spacer-responsive: var(--spacer-l);
		/* 80px | 112px | 120px */ --spacer-responsive-l: var(--spacer-xxl); /* the only spacer that changes via @media */

		--h1-font-size: 3.625rem; /* 58px */
		--h1-line-height: 3.625rem; /* 58px */

		--h2-font-size: 36px;
		--h2-line-height: 40px;

		--h3-font-size: 26px;
		--h3-line-height: 26px;
		--h3-margin-bottom: var(--spacer-xxl);

		--text-font-size: 18px;

		--figcaption-font-size: 16px;

		--heading-width: calc(100% - 100% / 6 * 5);
	}
}

@media (min-width: 1921px) {
	:root {
		--text-font-size: max(18px, 0.9375vw);
		--font-size-l: max(2.25rem, 1.3vw); /* 36px */
		--font-size-xmm: max(1.5rem, 0.85vw); /* 24px (navigation) */
		--font-size-xm: max(1.25rem, 1.04vw); /* 20px (navigation) */
		--font-size-m: max(1.125rem, 0.85vw); /* 18px */
		--font-size-s: max(.875rem, 0.7vw); /* 14px */
		--font-size-xs: max(1rem, 0.75vw); /* 16px */

		--page-max-width: max(1440px, 75vw);
		--figcaption-font-size: max(16px, 0.66vw);

		--h1-font-size: max(3.625rem, 2.5vw); /* 58px */
	}
}
