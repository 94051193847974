.tag-list {
	--tag-list-font-size: 26px;
	--tag-list-line-height: 26px;
	--tag-list-spacer: 40px;
	--tag-list-outer-spacer: var(--spacer-responsive-l);

	font-size: var(--tag-list-font-size);
	line-height: var(--tag-list-line-height);
	font-weight: var(--font-weight-medium);
}

.tag-list h1 {
	font-size: var(--tag-list-font-size);
	font-weight: var(--font-weight-medium);
	line-height: var(--tag-list-line-height);
	margin-top: 0;
}

.tag-list ul {
	list-style: none;
	padding: 0;
}

.tag-list li {
	margin-top: var(--tag-list-spacer);
	margin-bottom: var(--tag-list-spacer);
}

@media (min-width: 768px) {
	.tag-list {
		--tag-list-font-size: 40px;
		--tag-list-line-height: 40px;
		--tag-list-spacer: 30px;
	}
}

@media (min-width: 1200px) {
	.tag-list {
		--tag-list-font-size: 58px;
		--tag-list-line-height: 58px;
	}
}
