.heading {
	display: flex;
	justify-content: space-between;
}

.heading .arrows {
	line-height: 1;
}

.heading .arrows, .heading aside {
	font-size: var(--h1-font-size);
	margin: auto 0 auto auto;
}

.heading a:active,
.heading a:hover,
.heading a:focus {
	text-decoration: none;
	user-select: none;
}

.arrow-before + .arrow-before { margin-left: .7em }

.heading .arrow-before::before { margin-right: 0 }

/* arrow grey disabled */
a:not([href]).arrow-before::before,
a:not([href]).arrow-after::after {
	background-image: var(--arrow-b64-white);
}

.heading .arrow-after {
	margin-right: 8px;
}

.heading .arrow--back {
	margin-right: 8px;
}

@media (min-width: 765px) {
	.arrow-before + .arrow-before { margin-left: .5em }
}

@media (min-width: 1200px) {
	.arrow-before + .arrow-before { margin-left: .175em }
}
