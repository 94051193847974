.d-none {display: none !important}

.d-block {display: block !important}

.d-flex {display: flex !important}

.d-inline-block {display: inline-block !important;}

/* */
@media (min-width: 768px) {
	.d-md-none { display: none !important}

	.d-md-block { display: block !important}

	.d-md-flex { display: flex !important}

	.d-md-inline-block {display: inline-block !important;}
}

@media (min-width: 1200px) {
	.d-lg-none { display: none !important}

	.d-lg-block { display: block !important}

	.d-lg-flex { display: flex !important}

	.d-lg-inline-block {display: inline-block !important;}
}

