.page-404 {
	--min-width: 320px;
	--max-width: 1200px;
	--font-size: 47vw;
	--min-font-size: 72px;
	--max-font-size: 690px;
}

.page-404 p {
	font-size: var(--min-font-size);
	text-align: center;
	font-family: var(--font-family-sans);
	margin: 0;
	background: transparent url('../images/svg/404.svg') center center no-repeat;
	background-size: contain;
}

@media screen and (min-width: 320px) {
	.page-404 p {
		font-size: var(--font-size);
	}
}

@media screen and (min-width: 1200px) {
	.page-404 p {
		font-size: var(--max-font-size);
	}
}
