:root {
	--search-spacer: 20px;
	--search-font-size: var(--h1-font-size);
}

.search label {
	font-size: var(--search-font-size);
}

.search input {
	margin-top: var(--search-spacer);
	margin-bottom: var(--search-spacer);
	border: 0;
	border-left: 2px solid var(--color-border);
	padding: 7px;
	width: 100%;
	font-size: var(--search-font-size);
	height: calc(var(--search-font-size) + 12px);
}

.search input::placeholder {
	overflow: visible;
}

.search input::placeholder {
	color: var(--color-light-text);
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.search label {
		display: none;
	}
}
