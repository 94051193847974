.video-extern.video-extern {
	position: relative;
	width: 100%;
	height: auto;
	max-width: 1050px;
	margin: var(--spacer-m) auto;
}

.video-extern:after {
	content: "";
	display: block;
	padding-bottom: 56.25%;
}

.video-extern iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-extern figcaption {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
}
