a.link-big {
	line-height: 1.3;
	font-size: var(--font-size-xxm);
	text-transform: uppercase;
	display: block;
	font-family: var(--font-family-sans);
}

a.link-download::before,
a.link-external::before {
	text-transform: capitalize;
}

a.link-download::before {
	transform: rotate(90deg);
	height: 0.6em;
	line-height: inherit;
	padding-right: 0.9em;
	vertical-align: middle;
	position: relative;
	top: -.15em;
	border-right: 2px solid currentColor;
}
