/* grid */
:root {
	--grid-col-span-xl: 12;
	--grid-col-span-l: 12;
	--grid-col-span-m: 12;
	--grid-col-span-s: 12;
	--grid-row-span-xl: 1;
	--grid-row-span-l: 1;
	--grid-row-span-m: 1;
	--grid-row-span-s: 1;
}

/* grid tablet */
@media (min-width: 768px) {
	:root {
		--grid-col-span-xl: 12;
		--grid-col-span-l: 12;
		--grid-col-span-m: 6;
		--grid-col-span-s: 6;
		--grid-row-span-xl: 2;
		--grid-row-span-l: 2;
		--grid-row-span-m: 2;
		--grid-row-span-s: 1;
	}
}

/* grid desktop */
@media (min-width: 1200px) {
	:root {
		--grid-col-span-xl: 12;
		--grid-col-span-l: 6;
		--grid-col-span-m: 3;
		--grid-col-span-s: 3;
		--grid-row-span-xl: 2;
		--grid-row-span-l: 2;
		--grid-row-span-m: 2;
		--grid-row-span-s: 1;
	}
}

.grid {
	display: grid;
	grid-auto-flow: dense;
	grid-template-columns: repeat(12, 1fr);
}

.grid.grid-scroll {
	overflow-x: auto;
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: calc(87.5% / 12); /* 280 * 100 / 320 = 87.5%  */
	grid-template-columns: initial;
}

/* layouts with predefined tile sizes */
.grid-layout-1 > *,
.grid-layout-2 > *,
.grid-layout-4 > * {
	grid-column: span var(--grid-col-span-m);
	grid-row: span var(--grid-row-span-m);
}

.grid-layout-3 > * {
	grid-column: span var(--grid-col-span-s);
	grid-row: span var(--grid-row-span-s);
}

.grid-layout-1 > :first-child,
.grid-layout-4 > :first-child {
	--font-size: var(--font-size-xl);
	--font-cut: var(--tile-header-font-cut-xl);
	grid-column: span var(--grid-col-span-xl);
	grid-row: span var(--grid-row-span-xl);
	padding: var(--spacer-responsive);
}

.grid-layout-1 > :nth-child(8n+4),
.grid-layout-1 > :nth-child(8n+5),
.grid-layout-2 > :nth-child(7),
.grid-layout-2 > :nth-child(19n+8),
.grid-layout-2 > :nth-child(19n+12),
.grid-layout-2 > :nth-child(19n+18),
.grid-layout-2 > :nth-child(28),
.grid-layout-3 > :nth-child(5n+1),
.grid-layout-4 > :nth-child(4),
.grid-layout-4 > :nth-child(5) {
	--font-size: var(--font-size-l);
	--font-cut: var(--tile-header-font-cut-l);
	grid-column: span var(--grid-col-span-l);
	grid-row: span var(--grid-row-span-l);
	padding: var(--spacer-responsive);
}

.grid-layout-1 > :nth-child(8n+7),
.grid-layout-1 > :nth-child(8n+8),
.grid-layout-4 > :nth-child(7),
.grid-layout-4 > :nth-child(8),
.grid-layout-2 > :nth-child(19n+14),
.grid-layout-2 > :nth-child(19n+15),
.grid-layout-2 > :nth-child(19n+16),
.grid-layout-2 > :nth-child(19n+17) {
	grid-column: span var(--grid-col-span-s);
	grid-row: span var(--grid-row-span-s);
}

/* https://websemantics.uk/tools/responsive-font-calculator/ */
@supports (font-size: clamp(1px,2px,3px)) {
	/* Grid dynamische Schriftgroesse */

	@media (max-width: 767px) {
		/* alle Teaser */
		.grid-layout-1 > .tile,
		.grid-layout-2 > .tile,
		.grid-layout-3 > .tile,
		.grid-layout-4 > .tile,
		.grid.grid-scroll > .tile:not(.teaser) {
			/* clamp(18px, calc(1.125rem + (24 - 18) * ((100vw - 450px) / (767 - 450))), 24px) */
			--font-size: clamp(1.125rem, calc(1.125rem + (1.5 - 1.125) * ((100vw - 28.125rem) / (48 - 28.125))), 1.5rem); /* 450-768, 18-24px (1.125-1.5rem) */
		}
		.home .ddoi {
			--ddoi-header-font-size: clamp(1.125rem, calc(1.125rem + (1.5 - 1.125) * ((100vw - 28.125rem) / (48 - 28.125))), 1.5rem); /* 450-768, 18-24px (1.125-1.5rem) */
		}

		/* 1. Teaser */
		.grid-layout-1 > :first-child,
		.grid-layout-4 > :first-child,
		.detail .tile.tile-xl {
			--font-size: clamp(1.125rem, calc(1.125rem + (1.5 - 1.125) * ((100vw - 28.125rem) / (48 - 28.125))), 1.5rem); /* 450-768, 18-24px (1.125-1.5rem) */
		}
	}

	@media (min-width: 768px) and (max-width: 1200px) {

		/* alle Teaser */
		.grid-layout-1 > .tile,
		.grid-layout-2 > .tile,
		.grid-layout-3 > .tile,
		.grid-layout-4 > .tile,
		.grid.grid-scroll > .tile:not(.teaser) {
			--font-size: clamp(1rem, calc(1rem + (1.5 - 1) * ((100vw - 48rem) / (75 - 48))), 1.5rem); /* 768-1200, 16-24px (1-1.5rem) */
		}

		/* 1. Teaser */
		.grid-layout-1 > :first-child,
		.grid-layout-4 > :first-child,
		.detail .tile.tile-xl {
			--font-size: clamp(1.5rem, calc(1.5rem + (3 - 1.5) * ((100vw - 48rem) / (75 - 48))), 3rem); /* 768-1200, 24-48px (1.5-3rem) */
			padding: clamp(30px, calc(1.875rem + (60 - 30) * ((100vw - 768px) / (1200 - 768))), 60px);
		}

		/* grosse Teaser */
		.grid-layout-1 > :nth-child(8n+4),
		.grid-layout-1 > :nth-child(8n+5),
		.grid-layout-2 > :nth-child(7),
		.grid-layout-2 > :nth-child(19n+8),
		.grid-layout-2 > :nth-child(19n+12),
		.grid-layout-2 > :nth-child(19n+18),
		.grid-layout-2 > :nth-child(28),
		.grid-layout-3 > :nth-child(5n+1),
		.grid-layout-4 > :nth-child(4),
		.grid-layout-4 > :nth-child(5) {
			--font-size: clamp(1.5rem, calc(1.5rem + (2.25 - 1.5) * ((100vw - 48rem) / (75 - 48))), 2.25rem); /* 768-1200px, 24-36px (1.5-2.25rem) */
			padding: clamp(30px, calc(1.875rem + (60 - 30) * ((100vw - 768px) / (1200 - 768))), 60px);
		}
	}

	@media (min-width: 1281px) {
		/* 1. Teaser header */
		.grid-layout-1 > :first-child header,
		.grid-layout-4 > :first-child header,
		.detail .tile.tile-xl header {
			padding-right: calc(min(100% - 1140px, 33%));  /* 1440 - 2*60 = 1320 - 180 = 1140, ab 2.400 wächst die Schrift um --font-size: max(3rem, 2vw); */
		}

		/* kleine Teaser */
		.grid-layout-1 .tile:not(.tile-xl):not(:first-child) .image-inner img, .grid-layout-1 .tile:not(.tile-xl):not(:first-child) .image-inner canvas,
		.grid-layout-4 .tile:not(.tile-xl):not(:first-child) .image-inner img, .grid-layout-4 .tile:not(.tile-xl):not(:first-child) .image-inner canvas,
		.grid-layout-2 .tile:not(.tile-xl) .image-inner img, .grid-layout-2 .tile:not(.tile-xl) .image-inner canvas,
		.grid-scroll .image-inner img, .grid-scroll .image-inner canvas {
			max-width: min(100%, 19vw);
		}

		/* grosse Teaser header */
		/*.grid-layout-1 > .tile:nth-child(8n+4) .tile-inner header,
		.grid-layout-1 > .tile:nth-child(8n+5) .tile-inner header,
		.grid-layout-2 > .tile:nth-child(7) .tile-inner header,
		.grid-layout-2 > .tile:nth-child(19n+8) .tile-inner header,
		.grid-layout-2 > .tile:nth-child(19n+12) .tile-inner header,
		.grid-layout-2 > .tile:nth-child(19n+18) .tile-inner header,
		.grid-layout-2 > .tile:nth-child(28) .tile-inner header,
		.grid-layout-3 > .tile:nth-child(5n+1) .tile-inner header {
			padding-right: calc(100% - max(520px, 22vw));
		}*/

		/* grosse Teaser Bilder */
		.grid-layout-1 > :nth-child(8n+4) .tile-inner .image .image-inner canvas, .grid-layout-1 > :nth-child(8n+4) .tile-inner .image .image-inner img,
		.grid-layout-1 > :nth-child(8n+5) .tile-inner .image .image-inner canvas, .grid-layout-1 > :nth-child(8n+5) .tile-inner .image .image-inner img,
		.grid-layout-4 > :nth-child(4) .tile-inner .image .image-inner canvas, .grid-layout-4 > :nth-child(4) .tile-inner .image .image-inner img,
		.grid-layout-4 > :nth-child(5) .tile-inner .image .image-inner canvas, .grid-layout-4 > :nth-child(5) .tile-inner .image .image-inner img,
		.grid-layout-2 > :nth-child(7)  .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(7)  .tile-inner .image .image-inner img,
		.grid-layout-2 > :nth-child(19n+8) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+8) .tile-inner .image .image-inner img,
		.grid-layout-2 > :nth-child(19n+12) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+12) .tile-inner .image .image-inner img,
		.grid-layout-2 > :nth-child(19n+18) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+18) .tile-inner .image .image-inner img,
		.grid-layout-2 > :nth-child(28)  .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(28)  .tile-inner .image .image-inner img,
		.grid-layout-3 > :nth-child(5n+1) .tile-inner .image .image-inner canvas, .grid-layout-3 > :nth-child(5n+1) .tile-inner .image .image-inner img,
		.tile.header-big-lg .image-inner canvas, .tile.header-big-lg .image-inner img {
			max-width: min(100%, 40vw);
		}

		/* grosse Teaser */
		.grid-layout-1 > :nth-child(8n+4),
		.grid-layout-1 > :nth-child(8n+5),
		.grid-layout-4 > :nth-child(4),
		.grid-layout-4 > :nth-child(5),
		.grid-layout-2 > :nth-child(7),
		.grid-layout-2 > :nth-child(19n+8),
		.grid-layout-2 > :nth-child(19n+12),
		.grid-layout-2 > :nth-child(19n+18),
		.grid-layout-2 > :nth-child(28),
		.grid-layout-3 > :nth-child(5n+1),
		.grid-scroll[data-slider="stories"] .grid-col-lg-6 {
			padding: max(60px, 2vw) max(60px, 3vw);
		}
	}

	@media (min-width: 1921px) {
		/* alle Teaser */
		.grid-layout-1 > .tile,
		.grid-layout-2 > .tile,
		.grid-layout-3 > .tile,
		.grid-layout-4 > .tile,
		.grid.grid-scroll > .tile:not(.teaser) {

			/* 18 / 19.2 = 0.9375 -> 18px bei 1920px, ab dann mitwachsend */
			--font-size: 0.9375vw;
			--tile-spacer: max(30px, 1.5vw);
		}

		/* 1. Teaser */
		.grid-layout-1 > :first-child,
		.grid-layout-4 > :first-child,
		.detail .tile.tile-xl {
			/* > 48px */
			--font-size: max(3rem, 2vw);
		}

		/* grosse Teaser */
		.grid-layout-1 > :nth-child(8n+4),
		.grid-layout-1 > :nth-child(8n+5),
		.grid-layout-4 > :nth-child(4),
		.grid-layout-4 > :nth-child(5),
		.grid-layout-2 > :nth-child(7),
		.grid-layout-2 > :nth-child(19n+8),
		.grid-layout-2 > :nth-child(19n+12),
		.grid-layout-2 > :nth-child(19n+18),
		.grid-layout-2 > :nth-child(28),
		.grid-layout-3 > :nth-child(5n+1) {
			--font-size: max(2.25rem, 1.5vw);
		}
	}

	/* andere Kacheln siehe .tile */
}

/*
@media (min-width: 481px) and (max-width: 767px) {
	.grid-layout-2 {
		--grid-col-span-l: 6;
		--grid-col-span-m: 4;
		--grid-col-span-s: 4;
		--grid-row-span-s: 2;
	}

	.grid-layout-1 > :nth-child(8n+5) {
		--font-size: var(--font-size-m);
		--font-cut: var(--tile-header-font-cut-m);
		grid-column: span var(--grid-col-span-m);
		grid-row: span var(--grid-row-span-m);
	}

	.grid-layout-2 > :nth-child(19n+13) {
		--font-size: var(--font-size-l);
		--font-cut: var(--tile-header-font-cut-l);
		grid-column: span var(--grid-col-span-l);
		grid-row: span var(--grid-row-span-l);
	}
}
*/

@media (min-width: 481px) {
	/* variant: large font */
	.grid-layout-3.tiles-large > .tile {
		--font-size: var(--font-size-l);
		--font-cut: var(--tile-header-font-cut-l);
	}

	.grid-layout-3.tiles-large > :nth-child(5n+1) {
		--font-size: var(--font-size-xxl);
		--font-cut: var(--tile-header-font-cut-l);
	}
}

/* cols */
[class*='grid-col-'] {grid-column: span 12 !important}

.grid-col-1 {grid-column: span 1 !important}

.grid-col-2 {grid-column: span 2 !important}

.grid-col-3 {grid-column: span 3 !important}

.grid-col-4 {grid-column: span 4 !important}

.grid-col-5 {grid-column: span 5 !important}

.grid-col-6 {grid-column: span 6 !important}

.grid-col-7 {grid-column: span 7 !important}

.grid-col-8 {grid-column: span 8 !important}

.grid-col-9 {grid-column: span 9 !important}

.grid-col-10 {grid-column: span 10 !important}

.grid-col-11 {grid-column: span 11 !important}

.grid-col-12 {grid-column: span 12 !important}

/* cols tablet */
@media (min-width: 768px) {

	.grid.grid-scroll {grid-auto-columns: calc(100% / 12)}

	.grid-col-md-1 {grid-column: span 1 !important}

	.grid-col-md-2 {grid-column: span 2 !important}

	.grid-col-md-3 {grid-column: span 3 !important}

	.grid-col-md-4 {grid-column: span 4 !important}

	.grid-col-md-5 {grid-column: span 5 !important}

	.grid-col-md-6 {grid-column: span 6 !important}

	.grid-col-md-7 {grid-column: span 7 !important}

	.grid-col-md-8 {grid-column: span 8 !important}

	.grid-col-md-9 {grid-column: span 9 !important}

	.grid-col-md-10 {grid-column: span 10 !important}

	.grid-col-md-11 {grid-column: span 11 !important}

	.grid-col-md-12 {grid-column: span 12 !important}
}

/* cols desktop */
@media (min-width: 1200px) {
	.grid-col-lg-1 {grid-column: span 1 !important}

	.grid-col-lg-2 {grid-column: span 2 !important}

	.grid-col-lg-3 {grid-column: span 3 !important}

	.grid-col-lg-4 {grid-column: span 4 !important}

	.grid-col-lg-5 {grid-column: span 5 !important}

	.grid-col-lg-6 {grid-column: span 6 !important}

	.grid-col-lg-7 {grid-column: span 7 !important}

	.grid-col-lg-8 {grid-column: span 8 !important}

	.grid-col-lg-9 {grid-column: span 9 !important}

	.grid-col-lg-10 {grid-column: span 10 !important}

	.grid-col-lg-11 {grid-column: span 11 !important}

	.grid-col-lg-12 {grid-column: span 12 !important}
}

/* rows */
.grid-row-1 {grid-row: span 1 !important}

.grid-row-2 {grid-row: span 2 !important}

.grid-row-3 {grid-row: span 3 !important}

.grid-row-4 {grid-row: span 4 !important}

.grid-row-5 {grid-row: span 5 !important}

.grid-row-6 {grid-row: span 6 !important}

.grid-row-7 {grid-row: span 7 !important}

.grid-row-8 {grid-row: span 8 !important}

.grid-row-9 {grid-row: span 9 !important}

.grid-row-10 {grid-row: span 10 !important}

.grid-row-11 {grid-row: span 11 !important}

.grid-row-12 {grid-row: span 12 !important}

/* rows tablet */
@media (min-width: 768px) {
	.grid-row-md-1 {grid-row: span 1 !important}

	.grid-row-md-2 {grid-row: span 2 !important}

	.grid-row-md-3 {grid-row: span 3 !important}

	.grid-row-md-4 {grid-row: span 4 !important}

	.grid-row-md-5 {grid-row: span 5 !important}

	.grid-row-md-6 {grid-row: span 6 !important}

	.grid-row-md-7 {grid-row: span 7 !important}

	.grid-row-md-8 {grid-row: span 8 !important}

	.grid-row-md-9 {grid-row: span 9 !important}

	.grid-row-md-10 {grid-row: span 10 !important}

	.grid-row-md-11 {grid-row: span 11 !important}

	.grid-row-md-12 {grid-row: span 12 !important}
}

/* rows desktop */
@media (min-width: 1200px) {
	.grid-row-lg-1 {grid-row: span 1 !important}

	.grid-row-lg-2 {grid-row: span 2 !important}

	.grid-row-lg-3 {grid-row: span 3 !important}

	.grid-row-lg-4 {grid-row: span 4 !important}

	.grid-row-lg-5 {grid-row: span 5 !important}

	.grid-row-lg-6 {grid-row: span 6 !important}

	.grid-row-lg-7 {grid-row: span 7 !important}

	.grid-row-lg-8 {grid-row: span 8 !important}

	.grid-row-lg-9 {grid-row: span 9 !important}

	.grid-row-lg-10 {grid-row: span 10 !important}

	.grid-row-lg-11 {grid-row: span 11 !important}

	.grid-row-lg-12 {grid-row: span 12 !important}
}
