.text-cols {
	--text-col-cap: 60px;
}

.text-cols-span-all {
	column-span: all;
}

@media (min-width: 1200px) {
	.text-cols {
		column-count: 2;
		column-gap: var(--text-col-cap);
	}

	.text-cols p:first-child {
		margin-top: 0;
	}
}
