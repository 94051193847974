/* ########## Text über dem Newsletter ###############*/
.newsletter-h1 {
	width: 100%;
}

.newsletter-teaser {
	margin: 1rem var(--padding);
	font-size: var(--font-size-xll);
	font-weight: var(--font-weight-normal);
	text-transform: uppercase;
	font-family: var(--font-family-serif);
}

.newsletter-teaser > span {
	font-family: var(--font-family-sans);
	font-size: var(--font-size-xl);
}

/* ######  Formular  ########## */

.newsletter-form {
	margin-top: var(--spacer-l);
	display: flex;
}

.newsletter-form fieldset {
	border: 0;
	margin: 0 auto;
	max-width: 100%;
}

.newsletter-form input,
.newsletter-form .field,
.newsletter-form .field.field-required {
	border: 0;
	font-size: var(--font-size-xxl);
}

.newsletter-form .field {
	border-top: 1px solid #000;
	max-width: 100%;
	margin: 0 auto;
}

.newsletter-form .field:nth-child(3) {
	border-bottom: 1px solid #000;
}

.newsletter-form .field input {
	padding: var(--padding-s) 0;
}

.newsletter-form label {
	font-weight: var(--font-weight-normal);
	font-size: var(--font-size-m);
	padding-left: 0;
	color: #000;
}

.newsletter-footer {
	display: grid;
	grid-template-columns: repeat(2,1fr);
	grid-template-rows: repeat(2,auto);
	margin-top: var(--spacer-xs);
}

.newsletter-footer p.form-hint {
	grid-column: 1;
	grid-row: 1;
	margin: 0 10px 0 0;
}

.newsletter-footer button {
	grid-column: 1;
	grid-row: 2;
	margin-top: var(--spacer-xs);
}

.form-error.is-visible {
    font-size: 24px;
    align-self: center;
}

@media (min-width: 481px) {

	.newsletter-footer p.form-hint {
		grid-column: 1;
		grid-row: 1;
	}

	.newsletter-footer button {
		grid-column: 2;
		grid-row: 1;
		justify-items: start;
		margin-top: 0;
	}
}
