.pageheader {
	--page-header-logo-height: 23px;
	--partner-max-width: 100px;
	--partner-font-size: 12px;
	--page-header-height: 3.5rem;
	--page-header-padding: 0;
	--page-header-icon-height: 22px;
	--page-header-text-menu-spacer: 2.5vw;
	--page-partner-height: 60px;

	line-height: 1;
	background-color: var(--color-bg);
	position: sticky;
	top: 0;
	z-index: 10;
}

.pageheader.sticky .pageheader-partner > div:last-child {
	display: none;
}

.pageheader .pageheader-content {
	display: flex;
	align-items: center;
	height: var(--page-header-height);
	padding-bottom: var(--page-header-padding);
}

.pageheader h1 a {
	text-decoration: inherit;
	color: inherit;
}

.pageheader h1 a::before {
	content: none;
}

/* logo */
.pageheader-logo {
	display: flex;
}

.pageheader-logo > a {
	margin-top: auto;
}

.pageheader-logo img {
	width: auto;
	height: var(--page-header-logo-height);
	vertical-align: bottom;

	/* stick animation */
	/*transition: all 0.6s cubic-bezier(0.22, 0.61, 0.36, 1) 0.1s; */
}

.pageheader-logo .long {
	display: none;
}

.pageheader-logo .short {
	display: inline-block;
}

.pageheader .toggle-icons [data-toggle='nav.navigation'],
.pageheader .toggle-icons [data-toggle='.search'] {
	margin-left: 25px;
}

.pageheader .toggle-icons [data-toggle-state='opened'] .closed,
.pageheader .toggle-icons :not([data-toggle-state='opened']) .open {
	display: none;
}

/* navigation and search */
.pageheader-toggles {
	flex: 1;
	margin-left: 1em;
	font-size: var(--font-size-xmm);
}

.pageheader-toggles.toggle-text {
	display: none;
	white-space: nowrap;
	flex: 0;
}

.pageheader-toggles.toggle-text a {
	text-transform: uppercase;
	margin-right: var(--spacer-font-based-s);
}

.pageheader-toggles.toggle-icons {
	display: flex;
	flex: 0 1;
	margin-left: auto;
}

.pageheader-toggles.toggle-icons [data-toggle]::after {
	content: none;
}

.pageheader-toggles.toggle-icons img {
	height: var(--page-header-icon-height);
}

/* partner */
.pageheader-partner {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	position: relative;
	/*flex: 1;*/
	height: var(--page-partner-height);
	overflow: hidden;
}

.pageheader-partner ul,
.pageheader-partner .caption {
	/*margin-left: var(--spacer-xxs);*/
	margin: 0;
}

.pageheader .partner-list-item {
	padding: 0;
}

.pageheader-partner div + div {
	margin-left: 65px;
}

.pageheader.sticky .pageheader-partner > div {
	position: relative;
}

.pageheader-partner .caption {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	font-size: 10px;
	color: #666;
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.pageheader {
		--page-header-logo-height: 58px;
		--page-header-padding: 36px;
		--page-header-height: var(--spacer-responsive-l);
	}

	.pageheader ~ main {
		margin-top: 0;
	}

	.pageheader .pageheader-content {
		align-items: flex-end;
	}

	.pageheader.sticky {
		--page-header-logo-height: 23px;
		--page-header-padding: 18px;
		top: -55px;
	}

	.pageheader.sticky .pageheader-partner {
		top: 10px;
	}

	.pageheader.sticky .pageheader-partner .caption {
		left: -60px;
		top: initial;
		bottom: 8px;
	}

	.pageheader-logo {
		position: relative;
		bottom: 2px;
	}

	.pageheader-toggles.toggle-text {
		display: inline;
	}

	.pageheader-toggles.toggle-icons {
		display: none;
	}

	.pageheader-toggles {
		display: block;
		margin-left: var(--page-header-text-menu-spacer);
	}

	/* partner */
	.pageheader-partner {
		height: var(--page-header-logo-height);
		justify-content: flex-end;
	}

	.pageheader .partner-list {
		margin: auto 0;
		padding-left: 0;
		max-width: 110px;
	}

	/*
	.pageheader .partner-list:last-of-type {
		margin-left: var(--spacer-xxs);
	}
	*/

	.pageheader-partner .caption {
		font-size: var(--partner-font-size);
		position: absolute;
		top: 7px;
		font-weight: var(--font-weight-medium);

		left: initial;
		transform: initial;
	}

	.pageheader-partner img {
		vertical-align: bottom;
		max-width: var(--partner-max-width);
		height: auto;
	}

	.pageheader-partner + .pageheader-partner {
		margin-left: var(--spacer-m);
	}
}

@media (min-width: 1200px) {
	.pageheader {
		--page-header-logo-height: 58px;
		--page-header-padding: 40px;
		--page-header-text-menu-spacer: 1.1vw; /* Sprachwechsler sichtbar */
	}

	.pageheader.sticky {
		--page-header-logo-height: 35px;
		--page-header-padding: 23px;
		top: -51px;
	}

	.pageheader.sticky .pageheader-logo {
		bottom: -6px;
	}

	.pageheader.sticky .pageheader-partner {
		top: 8px;
	}

	/*
	.pageheader-partner .caption {
		top: 7px;
	}
	*/

	/*
	.pageheader-toggles.toggle-text {
		position: relative;
		top: 14px;
	}
	*/

	.pageheader .short {
		display: none;
	}

	.pageheader .long {
		display: inline-block;
	}

	.pageheader-logo {
		position: relative;
		bottom: -11px;
	}

	/*
	.pageheader-partner .caption {
		top: 0;
	}
	*/
}

@media (min-width: 1440px) {
	.pageheader {
		--page-header-logo-height: 65px;
	}
}

/* logo dynamisch wachsend mit der Headline */
@media (min-width: 2300px) {
	.pageheader {
		--page-header-height: calc(2.5vw + 120px - 58px);
		--page-header-logo-height: calc(2.5vw + 65px - 58px);
	}

	.pageheader-logo {
		bottom: -16%;
	}

	.pageheader.sticky {
		--page-header-height: max(2.5vw, 70px);
		--page-header-logo-height: calc(0.5 * max(2.5vw, 70px)); /* 50% von der Gesamthoehe */
		top: -1px;
	}

	.pageheader.sticky .pageheader-content {
		align-items: center;
		padding-bottom: 0;
	}

	.pageheader.sticky .pageheader-logo {
		align-self: center;
		bottom: 0;
	}
}

@media (min-width: 1921px) {
	.pageheader {
		--partner-font-size: max(12px, 0.4vw);
	}
}

/* deaktiviere Animationen auf Benutzerwunsch */
/*
@media (prefers-reduced-motion) {
	.pageheader-logo img {
		transition: none;
	}
}
*/

/* Conword Sprachwechlser */
.cnw_translationbox {
	display: none !important;
}

/*
.bnid-language-switch {
	display: flex;
	flex-wrap: nowrap;
	font-size: var(--font-size-xmm);
}

@media (min-width: 768px) {
	.bnid-language-switch {
		margin: 0;
	}
}

@media (min-width: 1200px) {
	.bnid-language-switch {
		margin-left: 14px;
	}
}

.bnid-language-switch a:not(.active) {
	color: #999;
}
*/
