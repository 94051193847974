.share {
	--font-size: 20px;
	--line-height: 20px;
	--share-spacer: 80px;

	text-align: center;
	font-size: var(--font-size);
	margin: var(--share-spacer) auto;
}

.share > a {
	display: inline-block;
	padding-left: var(--spacer-s);
	padding-right: var(--spacer-s);
	white-space: nowrap;
}

.share > a + a {
	margin-top: var(--spacer-m);
}

.share a {
	font-weight: var(--font-weight-medium);
	text-transform: uppercase;
	font-size: var(--font-size-xxm);
	font-family: var(--font-family-sans);
}

@media (min-width: 481px) {
	.share > a {
		padding-left: var(--spacer-m);
		padding-right: var(--spacer-m);
	}
}

@media (min-width: 768px) {
	.share {
		--share-spacer: 60px;
	}
}

@media (min-width: 1200px) {
	.share {
		--font-size: 26px;
		--line-height: 30px;
		--share-spacer: 120px;
	}

	.share > a + a {
		margin-top: 0;
	}
}

@media (min-width: 1921px) {
	.share img {
		width: auto;
		height: max(25px, 1.15vw);
	}
}
