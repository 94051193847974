.text,
[data-module="textblock"] {
	font-family: var(--font-family-serif);
	font-weight: 300;
	line-height: 1.4;
}

.text a,
[data-module="textblock"] a {
	text-decoration: underline;
}

[data-module="textblock"] img {
	max-width: 100%;
}
