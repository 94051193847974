fieldset {
	margin: 0 auto;
	border: none;
	padding: 0;
	max-width: 630px;
}

.field,
.field-checkbox,
fieldset button {
	--field-color: var(--color-fg);
	--field-height: 3.4rem;
	--field-border-width: 3px;
	position: relative;
	margin-bottom: var(--spacer-font-based);
	min-height: var(--field-height);
}

.field-checkbox label {
	word-break: break-all;
}

.field-inline {
	display: flex;
	border: var(--field-border-width) solid var(--field-color);
}

.field label {
	align-self: center;
	color: var(--field-color);
}

.field input,
.field select {
	padding-left: var(--spacer-font-based);
}

input,
select,
textarea,
button {
	appearance: none;
	outline: none;
}

.field input,
.field select,
.field textarea,
fieldset button {
	background: transparent;
	font-family: var(--font-family-sans);
	font-size: 1rem;
}

.field input,
.field select,
.field textarea {
	flex: 1;
	border: none;
}

.field input::placeholder,
.field textarea::placeholder {
	text-indent: var(--spacer-font-based-s);
	color: transparent;
	opacity: 1;
}

.field input:focus::placeholder,
.field textarea:focus::placeholder {
	color: var(--color-light-text);
}

input:invalid {
	box-shadow: none;
}

.field select {
	padding-right: 2em;
}

.field select + label {
	pointer-events: none;
	width: 2em;
	padding-left: 2px;
	/*
	overflow: hidden;
	position: absolute;
	right: 0;
	color: transparent;
	*/
}

.field select + label::before {
	content: '';
	display: inline-block;
	width: 10px;
	height: 14px;
	background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyODAuNzIgNDUwIj4KCTxnPgoJCTxwYXRoIGQ9Ik0xNDAuMzYgMEwwIDE0MC4zNmwzMy4wNSAzMi42IDgyLjg2LTgzLjMxVjM1OS45bC04Mi44Ni04Mi44NkwwIDMwOS42NCAxNDAuMzYgNDUwbDE0MC4zNi0xNDAuMzYtMzMuMDUtMzIuNi04My4zMSA4My4zMVY4OS4ybDgzLjMxIDgzLjc2IDMzLjA1LTMyLjZMMTQwLjM2IDB6Ii8+Cgk8L2c+Cjwvc3ZnPgo=) no-repeat;
	color: var(--field-color);
	margin-left: -28px;
}

.field select + label::after,
.field-checkbox.field-checkbox label::after {
	content: '';
}

.field textarea {
	display: block;
	resize: vertical;
	padding: var(--spacer-font-based);
	min-height: var(--field-height);
	max-height: 50vh;
}

.field-textarea::after {
	--textarea-resizer-offset: var(--field-border-width);
	position: absolute;
	right: 0;
	bottom: 2px;
	pointer-events: none;

	content: '\200b';
	width: .75em;
	height: .75em;
	background: transparent var(--arrow-b64) center center no-repeat;
	background-size: contain;
	display: inline-block;
	transform: rotate(45deg);
}

/*
.field-textarea:not(.field-required)::after {
	filter: invert(92%) hue-rotate(135deg) brightness(91%) contrast(85%);
}
*/

.field textarea::-webkit-resizer {
	display: none;
}

.field-textarea label {
	align-self: start;
	padding-top: calc(var(--spacer-font-based) + .125em);
}

.field-required {
	--field-color: var(--color-fg);
}

.field-required:not(.field-checkbox) label,
.field-required select,
.field-required-hint,
fieldset button {
	font-weight: var(--font-weight-bold);
	text-transform: uppercase;
}

.field-required label::after {
	content: '*';
}

.field-required-hint,
.form-hint {
	font-family: var(--font-family-sans);
}

.field-required-hint {
	font-style: italic;
}

.form-hint {
	margin: var(--padding) 0;
	line-height: 1.4;
}

fieldset button {
	--field-color: var(--color-fg);
	--field-border-width: 10px;
	--field-height: 5rem;
	display: block;
	width: 100%;
	border: var(--field-border-width) solid var(--field-color);
	font-size: var(--font-size-xxl);
}

.fieldgroup {
	margin: 2rem 0 1rem;
}

.field-checkbox {
	position: relative;
	line-height: 1.5;
	margin-bottom: 0;
	min-height: 3rem;
}

.field-checkbox label {
	cursor: pointer;
}

.field-checkbox input[type=checkbox] {
	position: absolute;
}

.field-required.field-checkbox span {
	--field-color: var(--color-fg);
	border: var(--field-border-width) solid var(--field-color);
}

.field-checkbox span {
	display: inline-block;
	width: 1.8rem;
	height: 1.8rem;
	margin-right: 10px;
	vertical-align: bottom;
	border: var(--field-border-width) solid var(--field-color);
	background-color: transparent;
	line-height: 1;
}

.field-checkbox input[type=checkbox]:checked+label span:before {
	content: "\00d7"; /* &times; */
	speak: none;
	font-size: 27px;
	font-family: var(--font-family-sans);
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 0.75;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding-left: 2px;
}

#website, input[name=website] {
	display: none;
}

@media (max-width: 480px) {
	.field label:first-child {
		display: block;
		margin-bottom: var(--spacer-font-based-s);
	}

	.field-inline,
	.field input {
		--field-height: 2.5rem;
	}

	.field input,
	.field textarea {
		border: var(--field-border-width) solid var(--field-color);
		width: 100%;
		min-height: var(--field-height);
	}
}

@media (max-width: 800px) {
	.field-group .field {
		width: 100%;
	}

	.field-group .field label {
		width: auto;
	}
}

@media (min-width: 481px) {
	.fieldgroup {
		display: grid;
		grid-template-columns: 1fr;
	}

	.field {
		--field-height: 3.4rem;
		display: flex;
		border: var(--field-border-width) solid var(--field-color);
	}

	.field-inline {
		display: inline-flex;
	}

	.field label {
		padding-left: var(--spacer-font-based);
		width: 9em;
	}

	.field textarea {
		min-height: calc(var(--field-height) - 2 * var(--field-border-width));
	}

	.field-textarea::after {
		--textarea-resizer-offset: 0;
	}

	fieldset button {
		--field-height: 7.5rem;
		font-size: var(--font-size-l);
	}

	.field-group {
		display: flex;
	}

	.field-group > :not(:first-child) {
		margin-left: var(--spacer-font-based);
	}
}

@media (min-width: 768px) {
	.detail .product-contact-form .field-spacer {
		margin-top: 80px;
	}
}

@media (min-width: 1200px) {
	.fieldgroup {
		display: grid;
		grid-template-columns: 50% 50%;
	}
}

.product-contact-form fieldset {
	max-width: initial;
	margin: initial;
}

.product-contact-form .field-group .field {
	width: 100%;
}

.product-contact-form .field-group .field label {
	width: auto;
}

.product-contact-form .field input,
.product-contact-form .field select,
.product-contact-form .field textarea {
	width: 100%;
}

.detail .product-contact-form input,
.detail .product-contact-form textarea,
.detail .product-contact-form select,
.detail .product-contact-form label
{
	font-size: var(--font-size-s);
}

.detail fieldset button {
	font-size: var(--font-size-xm);
}


.product-contact-form small,
.product-contact-form small p {
	font-family: var(--font-family-sans);
}

.detail .product-contact-form small,
.detail .product-contact-form small p {
	font-size: var(--font-size-s);
}

.product-contact-form small a {
	text-decoration: underline;
}

.detail .product-contact-form__header {
	margin-top: 80px;
}

.product-contact-form__header {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	margin-top: 15px;
	margin-bottom: 15px;
}

.product-contact-form__header a {
	font-size: var(--h2-font-size);
	line-height: 1.1;
	padding-top: 0;
}

.product-contact-form__header img {
	margin: 25px 0 0 25px;
}

.w-100 {
	width: 100%;
}

/* Honeypot Felder ausblenden, werden meist von SPAM-Bots ausgefuellt */
.form-sec {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}
