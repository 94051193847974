.designers {
	--designers-margin-top: 80px;
	--designers-margin-bottom: var(--spacer-responsive-l);
}

.designers .intro {
	margin-top: var(--designers-margin-top);
	margin-bottom: var(--designers-margin-bottom);
}

.designer-teaser .image {
	margin-top: 0;
	margin-bottom: 0;
}

.designer-teaser figcaption {
	margin-bottom: 15px;
}

.designer-teaser .tile img {
	padding-left: 0;
	padding-right: 0;
}

@media (max-width: 767px) {
	.designer-teaser .tile img,
	.designer-teaser.container,
	.designer-teaser [class*=col-], .designer-teaser .col {
		padding-left: 0;
		padding-right: 0;
	}

	.designer-teaser .row {
		margin-left: 0;
		margin-right: 0;
	}
}

@media (min-width: 768px) {
	.designers {
		--designers-margin-top: 86px;
	}
}

@media (min-width: 1200px) {
	.designers {
		--designers-margin-top: 133px;
	}
}
