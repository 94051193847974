.news {
	--image-spacer-bottom: 40px;
}

.news article > a {
	text-decoration: none;
}

.news .image {
	margin-bottom: var(--image-spacer-bottom);
}

.news .image:nth-child(1) {
	margin-top: 0;
}

.news .image img {
	margin-left: 0;
	margin-right: 0;
}

@media (min-width: 768px) {
	.news {
		--image-spacer-bottom: 30px;

		margin-left: calc(var(--col-gap) / -2);
		margin-right: calc(var(--col-gap) / -2);
	}

	.news article {
		width: 50%;
		float: left;
		padding-right: calc(var(--col-gap) / 2);
		padding-left: calc(var(--col-gap) / 2);
	}

	.news .image:nth-child(1),
	.news .image:nth-child(2) {
		margin-top: 0;
	}

	.news article:nth-child(2n) {
		float: right;
	}
}

@media (min-width: 1200px) {
	.news {
		--image-spacer-bottom: 60px;
	}
}
