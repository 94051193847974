.dossier {
	--link-font-size: 20px;
	--big-link-spacer: var(--spacer-responsive);
	--text-font-size: 18px;
	font-size: var(--text-font-size);
}

.dossier .intro {
	--font-size: 22px;
}

.dossier .grid-layout-1 {
	padding-bottom: var(--spacer-xl);
}

.dossier a.link-big {
	margin-top: var(--spacer-responsive);
	/*--text-font-size: 28px;*/
	/*--text-line-height: 30px;*/
}

.dossier .main-dossier .tile header,
.dossier .grid.tiles-bold.tiles-large header {
	--font-cut: -.6em;
	margin-left: 0;
}

.dossier .main-dossier .tile header {
	--font-cut: -.8em;
}

.dossier .main-dossier {
	padding-bottom: var(--spacer-responsive-l);
}

.dossier .main-dossier h1 {
	font-size: var(--h1-font-size);
}

.dossier .main-dossier .tile {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
}

.dossier .main-dossier {

}

.dossier .link-download {
	font-weight: var(--font-weight-medium);
	font-size: var(--link-font-size);
}

@media (min-width: 768px) {
	.dossier {
		--link-font-size: 26px;
		--text-font-size: 20px;
	}

	.dossier .intro {
		--font-size: 26px;
		margin-top: 55px;
	}

	.dossier .grid-layout-1 {
		padding-bottom: var(--spacer-l);
	}

	.dossier .main-dossier .tile header  {
		--font-cut: -1.5em;
	}
}

@media (min-width: 1200px) {
	.dossier {
		--link-font-size: 20px;
	}

	.dossier .intro {
		--font-size: 39px;
		margin-top: 70px;
	}

	.dossier .text-cols .intro + p {
		margin-top: 0;
	}

	.dossier .main-dossier .tile header  {
		--font-cut: -1.8em;
	}
}

@media (min-width: 1921px) {
	.dossier {
		--text-font-size: max(20px, 1.04vw);
		--link-font-size: max(20px, 1.04vw);
	}

	.dossier .intro {
		--font-size: max(39px, 2.03vw);
	}

	.dossier .main-dossier .tile header  {
		/*--font-cut: -1.8em;*/
	}
}
