.filter-category ul {
	list-style: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	line-height: 30px;
	font-size: 20px;
}

.filter-category li {
	white-space: nowrap;
	margin-right: 15px;
}

/*
.filter-category.selected li.active a {
	font-weight: var(--font-weight-bold);
	font-style: italic;
}
*/

/*
@media (max-width: 767px) {
	.filter-category.selected li:not(.active) {
		display: none;
	}

	.filter-category.selected li.active a::after {
		font-style: normal;
		content: '✕';
		margin-left: var(--arrow-offset);
		font-weight: var(--font-weight-bold);
	}
}
*/

.filter-category.selected li.active {
	text-decoration: underline;
}
