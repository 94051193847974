:root {
	--slider-arrow-width: .8em;
}

[data-slider] {
	scroll-snap-type: both mandatory;
	/* position relative is required, without it the position values deviate */
	position: relative;
	overflow-x: auto;
}

[data-slider]:not(.-scrolling) > * {
	scroll-snap-align: start;
}

[data-slider].safari > * {
	scroll-snap-align: initial;
}

[data-slider]::-webkit-scrollbar {
	display: none;
}

/* prevent ghosting in safari */
[data-slider] > a {
	user-drag: none;
}

[data-slider] > a img {
	pointer-events: none;
}

.slider-buttons {
	text-align: center;
	margin-bottom: var(--spacer-xxs);
}

.slider-buttons > a,
button[data-slider-next],
button[data-slider-previous] {
	font-size: var(--h1-font-size);
	cursor: pointer;
	appearance: none;
	overflow: hidden;
	border: none;
	padding: 0;
	width: var(--slider-arrow-width);
	height: 1em;
	background: transparent;
	font-family: var(--font-family-sans-light);
}

button[data-slider-next]::before,
button[data-slider-previous]::before {
	content: '\200b';
	width: .75em;
	background: transparent var(--arrow-b64) center center no-repeat;
	background-size: contain;
	display: inline-block;
}

button[data-slider-previous]::before {
	transform: rotate(180deg);
}

button[data-slider-previous][aria-disabled='true'],
button[data-slider-next][aria-disabled='true'],
button[data-slider-previous][disabled],
button[data-slider-next][disabled] {
	opacity: .25;
}

.slider[data-slider] {
	flex-wrap: nowrap;
	height: 100%;
}

@media (max-width: 767px) {
	.more[data-slider] .tile {
		width: 87.5%;
		flex: 0 0 87.5%;
	}

	.row.slider-xs, .row.slider-xs .row {
		flex-wrap: nowrap;
		height: 100%;
	}

	.slider-buttons button + button {
		margin-left: var(--spacer-responsive);
	}
}


@media (min-width: 768px) {
	.slider-buttons {
		position: absolute;
		right: 30px;
		top: 0;
	}

	.section-heading .slider-buttons {
		padding-top: var(--h1-padding-y);
	}
	.slider-buttons button + button {
		margin-left: .175em;
	}
}

@media (min-width: 1200px) {
	[data-slider] .tile.col-lg-6 {
		--font-size: var(--font-size-l);
		--font-cut: var(--tile-header-font-cut-l);
		padding: var(--spacer-l) !important; /* override col paddings */
	}
}
