/* SPACER TOP */

.mb-0 {margin-bottom: 0 !important}

.mt-0 {margin-top: 0 !important}

.mt-xxs { margin-top: var(--spacer-xxs) !important}

.mt-xs {margin-top: var(--spacer-xs) !important}

.mt-s {margin-top: var(--spacer-s) !important}

.mt-m {margin-top: var(--spacer-m) !important}

.mt-l {margin-top: var(--spacer-l) !important}

.mt-xl {margin-top: var(--spacer-xl) !important}

.mt-xxl {margin-top: var(--spacer-xxl) !important}

@media (min-width: 768px) {

	.mb-md-0 {margin-bottom: 0 !important}

	.mt-md-0 {margin-top: 0 !important}

	.mt-md-xxs { margin-top: var(--spacer-xxs) !important}

	.mt-md-xs { margin-top: var(--spacer-xs) !important}

	.mt-md-s { margin-top: var(--spacer-s) !important}

	.mt-md-m { margin-top: var(--spacer-m) !important}

	.mt-md-l { margin-top: var(--spacer-l) !important}

	.mt-md-xl { margin-top: var(--spacer-xl) !important}

	.mt-md-xxl { margin-top: var(--spacer-xxl) !important}
}

@media (min-width: 1200px) {
	.mb-lg-0 {margin-bottom: 0 !important}

	.mt-lg-0 {margin-top: 0 !important}

	.mt-lg-xxs { margin-top: var(--spacer-xxs) !important}

	.mt-lg-xs { margin-top: var(--spacer-xs) !important}

	.mt-lg-s { margin-top: var(--spacer-s) !important}

	.mt-lg-m { margin-top: var(--spacer-m) !important}

	.mt-lg-l { margin-top: var(--spacer-l) !important}

	.mt-lg-xl { margin-top: var(--spacer-xl) !important}

	.mt-lg-xxl { margin-top: var(--spacer-xxl) !important}
}
