.pagefooter {
	--margin: var(--spacer-xxs);
	--footer-font-size: 18px;

	text-transform: uppercase;
	line-height: 1;
	font-size: var(--footer-font-size);
	margin-top: var(--margin);
	margin-bottom: var(--margin);
}

.pagefooter__content {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: flex-end;
}

.pagefooter .partner-list {
	margin: var(--spacer-responsive) auto;
}

.sociallinks {
	margin-top: 25px;
}

.sociallinks img {
	max-height: 25px;
	width: auto;
	margin-right: 16px;
}

.legal-notice-links {
	margin-top: 25px;
}

.pagefooter .partner-list-item img {
	height: auto;
}

@media (min-width: 768px) {
	.pagefooter {
		--margin: var(--spacer-xs);
	}
}

@media (min-width: 1200px) {
	.pagefooter {
		--margin: var(--spacer-l);
	}
}

@media (min-width: 1200px) {
	.pagefooter {
		--footer-font-size: max(18px, 0.8vw);
	}

	.sociallinks img {
		max-height: initial;
		height: max(25px, 1.15vw);
		margin-right: max(16px, 0.9vw);
	}
}
