.filter-dropdown {
	--dropdown-spacer: 15px;

	position: relative;
	margin-bottom: var(--dropdown-spacer);
	/*grid-column: span 3;*/
}

.filter-dropdown select {
	border: none;
	width: 100%;
}

.filter-dropdown select::before {
	content: attr(data-placeholder);
}

.filter-dropdown a.chosen-single {
	text-decoration: none;
	display: flex;
	/*padding: var(--padding-s);*/
}

.filter-dropdown .chosen-container-single-nosearch .chosen-search {
	display: none;
}

.filter-dropdown a.chosen-single div {
	display: inline;
}

.filter-dropdown a.chosen-single.chosen-default div b::after {
	content: '';
	display: inline-block;
	margin-left: 4px;
	width: 10px;
	height: 12px;
	background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyODAuNzIgNDUwIj4KCTxnPgoJCTxwYXRoIGQ9Ik0xNDAuMzYgMEwwIDE0MC4zNmwzMy4wNSAzMi42IDgyLjg2LTgzLjMxVjM1OS45bC04Mi44Ni04Mi44NkwwIDMwOS42NCAxNDAuMzYgNDUwbDE0MC4zNi0xNDAuMzYtMzMuMDUtMzIuNi04My4zMSA4My4zMVY4OS4ybDgzLjMxIDgzLjc2IDMzLjA1LTMyLjZMMTQwLjM2IDB6Ii8+Cgk8L2c+Cjwvc3ZnPgo=) no-repeat;
}

.filter-dropdown a.chosen-single div b::after {
	content: none;
}

.filter-dropdown .search-choice-close:after {
	content: '×';
	padding: 0 3px;
	font-family: var(--font-family-serif);
	font-weight: var(--font-weight-bold);
}

/*
.filter-dropdown .filter-dropdown-count {
	display: inline-block;
	margin-left: auto;
	font-style: normal;
}
*/

.filter-dropdown .chosen-drop {
	display: none;
	position: absolute;
	z-index: 100;
	padding: var(--padding-s);
	width: 100%;
	background: var(--color-bg);
}

.filter-dropdown .chosen-results {
	cursor: pointer;
}

.filter-dropdown .chosen-container-active .chosen-drop {
	display: block;
}

.filter-dropdown input {
	margin-bottom: var(--spacer-font-based);
	border: 1px solid var(--color-border);
	padding: var(--spacer-font-based-s) var(--spacer-font-based);
	width: 100%;
}

.filter-dropdown ul {
	list-style: none;
	overflow: auto;
	margin: 0 0 0 var(--spacer-font-based);
	padding: 1px 0;
	max-height: 50vh;
}

/* mobil - native Ansicht wiederherstellen */
.filter-dropdown .chosen-unsupported {
	appearance: textfield !important;
	outline: initial !important;
	width: 100%;
}

.filter-dropdowns {
    padding-left: 20px;
}

@media (min-width: 768px) {
	.filter-dropdowns {
	    padding-left: 10px;
	}

	/* Dropdown */
	.filter-container .filter-dropdown .chosen-drop {
		width: max-content;
		max-width: 345px;
		padding-right: 20px;
	}

	.filter-dropdown {
		max-width: fit-content;
	}
}

@media (min-width: 1200px) {
	.filter-dropdowns.filter-dropdowns {
	    padding-left: 0;
		margin-left: -8px !important;
	}

	.filter-dropdown {
		--dropdown-spacer: 30px;
	}
}

@media (min-width: 1921px) {
	.filter-dropdown a.chosen-single.chosen-default div b::after {
		width: max(10px, 1vw);
		height: max(12px, 0.6vw);
	}
}
