.intro {
	--margin-top: 40px;
	--margin-bottom: 40px;
	--font-size: 19px;
	--line-height: 1.3;

	font-family: var(--font-family-serif);
	font-size: var(--font-size);
	font-weight: var(--font-weight-bold);
	margin-top: var(--margin-top);
	margin-bottom: var(--margin-bottom);
	line-height: var(--line-height);
}

.intro p {
	margin: 0;
}

@media (min-width: 768px) {
	.intro {
		--margin-top: 30px;
		--margin-bottom: 30px;
		--font-size: 26px;
	}
}

@media (min-width: 1200px) {
	.intro {
		--margin-top: 60px;
		--margin-bottom: 20px;
		--font-size: 40px;
		--line-height: 1.2;
		--font-weight-bold: 500;
	}
}

@media (min-width: 1921px) {
	.intro {
		--font-size: max(40px, 2.083vw);
	}
}
