:root {
	--navigation-font-size: 24px;
	--subnavigation-font-size: 14px;
	--navigation-line-height: 2;
	--navigation-li-height: calc(var(--navigation-line-height) * var(--navigation-font-size))
}

/* Subnavigation */
.heading-nav {
	padding: 0 24px;
}

.heading .arrows,
.heading-nav .heading .arrows {
	font-size: 2rem;
}

/* Footer */
.heading-footer .arrows {
	font-size: 2rem;
	margin-right: 24px;
}

.navigation ul {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	margin: 0;
	line-height: 2;
	font-weight: var(--font-weight-medium);
	font-size: var(--navigation-font-size);
	height: 250px;
}

.navigation a.meta {
	font-size: var(--subnavigation-font-size);
	/*line-height: var(--navigation-li-height);*/
}

.navigation li {
	display: inline-block;
	height: var(--navigation-li-height);
	margin: 0 var(--spacer-font-based-s) 0 0;
}

.navigation .active {
	text-decoration: underline;
}

.navigation a {
	white-space: nowrap;
	font-size: var(--font-size-xm);
}

.navigation a.meta {
	font-size: var(--font-size-s);
}

.heading-nav .filter-dropdowns {
	padding-left: 0;
}

.heading-nav .filter-dropdowns .filter-dropdown {
	margin-left: -8px;
}

.heading-nav > .arrows {
	width: 90px;
}

@media (max-width: 767px) {
	.heading-nav .filter-dropdowns {
		padding-left: 0;
	}

	.navigation ul {
		flex: 1 0 50%;
	}
}

@media (min-width: 768px) {
	.navigation {
		display: block !important;
	}

	/*
	 * Zwischenueberschriften ausblenden
	 */
	main.home > h1,
	main.detail > .heading,
	.heading .h1,
	.heading h1 {
		display: none;
	}

	.navigation ul {
		height: auto;
		padding: 12px 0 2px 24px;
	}

	.navigation ul {
		flex-direction: row;
	}

	/*
	 * Subnavigation
	 */
	.heading-nav {
		padding: 0 24px 0 0;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
	}

	.heading-nav .heading {
		order: 2;
		width: 110px;
		align-self: start;
	}

	.heading-nav .filter-container {
		order: 1;
		width: 100%;
		z-index: 2;
		transform: translateX(9px);
	}

	.heading-nav > .arrows {
		order: 2;
		width: auto;
		font-size: 2rem;
		justify-self: end;
		margin-bottom: 15px;
	}

	.heading-nav > .arrows .arrow--right {
		margin-left: 0;
	}

	.heading-nav .filter-dropdown {
		--dropdown-spacer: 15px;
	}

	.heading-nav .heading .arrows {
		margin-bottom: 15px;
	}

	.heading-nav .product-filter-list {
		padding-left: 24px;
		width: 100%;
	}

	.heading-nav .product-filter-list ul {
		margin-top: 0;
		margin-bottom: 10px;
	}

	.heading-nav .filter-dropdowns .filter-dropdown {
		margin-left: 0;
	}

	.product-filter-nav {
		margin-top: -7px;
	}
}

@media (min-width: 1200px) {
	.heading-nav .filter-dropdowns .filter-dropdown {
		margin-left: inherit;
	}

	.filter-dropdowns.filter-dropdowns {
	    padding-left: 0;
		margin-left: 0 !important;
	}
}

@media (min-width: 1921px) {
	.navigation ul {
		display: block;
	}
}
