.inline-slider .next, .inline-slider .previous {
	display: flex;
}

.inline-slider [data-slider-previous],
.inline-slider [data-slider-next] {
	margin-top: auto;
	margin-bottom: auto;
}

.inline-slider.row [data-slider] {
	flex-wrap: nowrap;
	height: 100%;
}

.inline-slider figure {
	margin-top: auto;
	margin-bottom: auto;
	width: 100%;
	flex: 0 0 100%;
}

.inline-slider .row {
	flex-wrap: nowrap;
}

@media (max-width: 767px) {
	.inline-slider .slider {
		margin-top: var(--spacer-xxs);
	}

	.inline-slider .image {
		padding: 0 !important;
	}

	.inline-slider .next,
	.inline-slider .previous {
		padding: 0 .3em !important;
	}
}

@media (max-width: 767px) {
	.inline-slider [data-slider-previous] {
		margin-left: auto;
	}

	.inline-slider .slider {
		flex-basis: 100%;
		padding-left: var(--col-gap);
		padding-right: var(--col-gap);
	}
}

@media (min-width: 768px) {
	.inline-slider .next {
		order: 9999;
	}

	.inline-slider [data-slider-previous] { margin-right: auto; }

	.inline-slider [data-slider-next] { margin-left: auto; }
}
