.image {
	--image-spacer: var(--spacer-m);

	margin: var(--image-spacer) auto;
}

.image.image-teaser {
	margin-top: 0;
}

.image.teaser-logo {
	--image-spacer: var(--spacer-responsive-l);
	margin-top: calc(var(--image-spacer) / 2);
	margin-bottom: calc(var(--image-spacer) / 2);
}

.image img,
.image .gallery-icon,
.image .gallery-counter {
	margin: 0 auto;
	max-width: 100%;
	display: flex;
}

.image.gallery img {
	margin: auto;
	display: block;
}

.image.gallery .gallery-wrapper {
	display: flex;
}

.image.gallery .gallery-inner {
	margin: 0 auto;
	position: relative;
	cursor: pointer;
}

.image.gallery .gallery-inner:before {
	content: '';
	background: transparent;
	will-change: background;
}

/* Hover "Bildergalerie ansehen" Overlay */
.image.gallery .gallery-inner:hover:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	transition: background 0.3s ease-in;
	pointer-events: none;
}

.image.gallery .gallery-inner:hover:after {
	content: 'BILDERGALERIE ANSEHEN';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	z-index: 2;
	color: var(--color-bg);
	border: 1px solid var(--color-bg);
	padding: 4px 7px 4px 8px;
	pointer-events: none;
	font-family: var(--font-family-sans);
	font-size: var(--font-size-xs);
	text-align: center;
}

/* Bildhöhe begrenzen ausschliesslich Querformate: Startseite 1. Bild, 1. Bild Artikeldetailseite  */
/* https://stackoverflow.com/a/55475793/270935 */
.image-inner {
	display: grid;
}

/* canvas als Platzhalter für das Bild */
/* svg leider nicht vollständig geeignet wegen max-width */
.image-inner canvas,
.image-inner svg {
	pointer-events: none;
	visibility: hidden;
	grid-area: 1 / 1;

	/* same like .image img */
	display: flex;
	max-width: 100%;
	margin: 0 auto;
}

.image-inner img {
	grid-area: 1 / 1;
}

.grid-layout-1 > :first-child .image img,
.grid-layout-1 > :first-child .image canvas,
.grid-layout-1 > :first-child .image svg,
.grid-layout-4 > :first-child .image img,
.grid-layout-4 > :first-child .image canvas,
.grid-layout-4 > :first-child .image svg,
.detail .tile .image img,
.detail .tile .image canvas,
.detail .tile .image svg,
.ddoi .tile .image img,
.ddoi .tile .image canvas,
.accordion .image img,
.accordion .image canvas,
.accordion .image svg {
	max-height: calc(75vh - 56px);
}

@media (max-width: 768px) and (orientation: portrait) {
	.image-inner img, .image-inner canvas {
		object-fit: contain;
		width: 100%;
	}

	/* kleine Teaser */
	.grid-layout-1 .tile:not(.tile-xl):not(:first-child) .image-inner img, .grid-layout-1 .tile:not(.tile-xl):not(:first-child) .image-inner canvas,
	.grid-layout-4 .tile:not(.tile-xl):not(:first-child) .image-inner img, .grid-layout-4 .tile:not(.tile-xl):not(:first-child) .image-inner canvas,
	.grid-layout-2 .tile:not(.tile-xl) .image-inner img, .grid-layout-2 .tile:not(.tile-xl) .image-inner canvas,
	.grid-scroll .image-inner img, .grid-scroll .image-inner canvas {
		max-height: calc(70vh - 56px);
	}

	/* grosse Teaser Bilder */
	.grid-layout-1 > :nth-child(8n+4) .tile-inner .image .image-inner canvas, .grid-layout-1 > :nth-child(8n+4) .tile-inner .image .image-inner img,
	.grid-layout-1 > :nth-child(8n+5) .tile-inner .image .image-inner canvas, .grid-layout-1 > :nth-child(8n+5) .tile-inner .image .image-inner img,
	.grid-layout-4 > :nth-child(4) .tile-inner .image .image-inner canvas, .grid-layout-4 > :nth-child(4) .tile-inner .image .image-inner img,
	.grid-layout-4 > :nth-child(5) .tile-inner .image .image-inner canvas, .grid-layout-4 > :nth-child(5) .tile-inner .image .image-inner img,
	.grid-layout-2 > :nth-child(7)  .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(7)  .tile-inner .image .image-inner img,
	.grid-layout-2 > :nth-child(19n+8) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+8) .tile-inner .image .image-inner img,
	.grid-layout-2 > :nth-child(19n+12) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+12) .tile-inner .image .image-inner img,
	.grid-layout-2 > :nth-child(19n+18) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+18) .tile-inner .image .image-inner img,
	.grid-layout-2 > :nth-child(28)  .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(28)  .tile-inner .image .image-inner img,
	.grid-layout-3 > :nth-child(5n+1) .tile-inner .image .image-inner canvas, .grid-layout-3 > :nth-child(5n+1) .tile-inner .image .image-inner img,
	.tile.header-big-lg .image-inner canvas, .tile.header-big-lg .image-inner img {
		max-height: calc(70vh - 56px);
	}
}

@media (max-width: 768px) and (orientation: landscape) {
	.image-inner img, .image-inner canvas {
		object-fit: contain;
		width: 100%;
		max-height: calc(75vh - 56px);
	}

	.showrooms .image-inner img, .showrooms .image-inner canvas {
		object-fit: inherit;
		width: inherit;
		max-height: inherit;
	}

	/* kleine Teaser */
	.grid-layout-1 .tile:not(.tile-xl):not(:first-child) .image-inner img, .grid-layout-1 .tile:not(.tile-xl):not(:first-child) .image-inner canvas,
	.grid-layout-4 .tile:not(.tile-xl):not(:first-child) .image-inner img, .grid-layout-4 .tile:not(.tile-xl):not(:first-child) .image-inner canvas,
	.grid-layout-2 .tile:not(.tile-xl) .image-inner img, .grid-layout-2 .tile:not(.tile-xl) .image-inner canvas,
	.grid-scroll .image-inner img, .grid-scroll .image-inner canvas {
		max-height: calc(75vh - 56px);
	}

	/* grosse Teaser Bilder */
	.grid-layout-1 > :nth-child(8n+4) .tile-inner .image .image-inner canvas, .grid-layout-1 > :nth-child(8n+4) .tile-inner .image .image-inner img,
	.grid-layout-1 > :nth-child(8n+5) .tile-inner .image .image-inner canvas, .grid-layout-1 > :nth-child(8n+5) .tile-inner .image .image-inner img,
	.grid-layout-4 > :nth-child(4) .tile-inner .image .image-inner canvas, .grid-layout-4 > :nth-child(4) .tile-inner .image .image-inner img,
	.grid-layout-4 > :nth-child(5) .tile-inner .image .image-inner canvas, .grid-layout-4 > :nth-child(5) .tile-inner .image .image-inner img,
	.grid-layout-2 > :nth-child(7)  .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(7)  .tile-inner .image .image-inner img,
	.grid-layout-2 > :nth-child(19n+8) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+8) .tile-inner .image .image-inner img,
	.grid-layout-2 > :nth-child(19n+12) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+12) .tile-inner .image .image-inner img,
	.grid-layout-2 > :nth-child(19n+18) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+18) .tile-inner .image .image-inner img,
	.grid-layout-2 > :nth-child(28)  .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(28)  .tile-inner .image .image-inner img,
	.grid-layout-3 > :nth-child(5n+1) .tile-inner .image .image-inner canvas, .grid-layout-3 > :nth-child(5n+1) .tile-inner .image .image-inner img,
	.tile.header-big-lg .image-inner canvas, .tile.header-big-lg .image-inner img {
		max-height: calc(75vh - 56px);
	}
}

.image.inverse-caption {
	display: flex;
	flex-direction: column-reverse;
}

.image.inverse-caption figcaption {
	margin-top: 0;
	margin-bottom: var(--figcaption-spacer);
}

.tile .image.gallery {
	padding: 0 var(--spacer-font-based);
}

.image .gallery-icon,
.image .gallery-counter {
	display: none;
}

.image.gallery .gallery-counter {
	display: block;
	position: absolute;
	line-height: 0;
	top: 30px;
	right: 14px;
	z-index: 1;
	color: white;
	font-family: var(--font-family-sans);
	font-size: var(--font-size-s);
	text-align: right;
	pointer-events: none;
}

.image.gallery .gallery-icon {
	display: block;
	position: absolute;
	bottom: 14px;
	right: 14px;
	z-index: 1;
	pointer-events: none;
	width: 28px;
	height: 28px;
	background: url('../images/svg/arrow-lightbox-white.svg') no-repeat top right;
}

@media (min-width: 768px) {

	.grid-layout-1 > :first-child .image img,
	.grid-layout-1 > :first-child .image canvas,
	.grid-layout-1 > :first-child .image svg,
	.grid-layout-4 > :first-child .image img,
	.grid-layout-4 > :first-child .image canvas,
	.grid-layout-4 > :first-child .image svg,
	.detail .tile .image img,
	.detail .tile .image canvas,
	.detail .tile .image svg,
	.ddoi .tile .image img,
	.ddoi .tile .image canvas,
	.ddoi .tile .image svg {
		max-height: 645px;
	}

	.accordion .image img,
	.accordion .image canvas,
	.accordion .image svg {
		max-height: initial;
	}

	.image.gallery .gallery-icon {
		width: 32px;
		height: 30px;
	}

}

@media (min-width: 1200px) {

	.image.gallery .gallery-counter {
		font-size: var(--font-size-m);
	}

	.image.gallery .gallery-icon {
		width: 38px;
		height: 36px;
	}

}

@media (min-width: 1921px) {
	.grid-layout-1 > :first-child .image img,
	.grid-layout-1 > :first-child .image canvas,
	.grid-layout-1 > :first-child .image svg,
	.grid-layout-4 > :first-child .image img,
	.grid-layout-4 > :first-child .image canvas,
	.grid-layout-4 > :first-child .image svg,
	.detail .tile .image img,
	.detail .tile .image canvas,
	.detail .tile .image svg,
	.ddoi .tile .image img,
	.ddoi .tile .image canvas {
		max-height: max(645px,30vw);
	}
}
