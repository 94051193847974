.date-list {
	--date-font-size: 22px; /* 22px | 16px | 36px */
	--heading-font-size: 22px; /* 22px | 24px | 37px */
	--heading-spacer: calc(var(--spacer-xxs) / 2);

	margin-top: var(--spacer-responsive);
	margin-bottom: var(--spacer-responsive);
	line-height: 1.1;
}

.date-list header { margin-top: var(--heading-spacer) }

.date-list header h1, .date-list header h2 {
	display: inline;
	margin: 0;
	font-size: var(--heading-font-size);
}

.date-list header h2 {
	font-family: var(--font-family-serif);
	text-transform: none;
}

.date-list .date { font-size: var(--date-font-size) }

@media (min-width: 768px) {
	.date-list {
		--date-font-size: 16px; /* 22px | 16px | 36px */
		--heading-font-size: 24px; /* 22px | 24px | 37px */
		--heading-spacer: 0;
	}
}

@media (min-width: 1200px) {
	.date-list {
		--date-font-size: 36px; /* 22px | 16px | 36px */
		--heading-font-size: 37px; /* 22px | 24px | 37px */
	}
}

@media (min-width: 1200px) {
	.date-list {
		--date-font-size: max(36px, 1.6vw);
		--heading-font-size: max(37px, 1.7vw);
	}
}
