:root {
	--accordion-offset: var(--spacer-responsive-l);
	--accordion-spacer: 26px;
	--accordion-link-font-size: 26px;
	--accordion-text-font-size: 16px; /* 16px | 18px | 18px */
	--accordion-line-height: 26px;
}

.accordion-wrapper {
	margin-top: var(--accordion-offset);
	margin-bottom: var(--accordion-offset);
}

.accordion {
	border-top: 1px solid #000000;
	border-bottom: 1px solid #000000;
	margin: -1px 0;
	line-height: 1.3;
}

.accordion-content * {
	font-family: var(--font-family-sans);
}

a:hover.accordion-link,
a:active.accordion-link,
a:focus.accordion-link {
	text-decoration: none;
	user-select: none;
}

.accordion-link {
	font-size: var(--accordion-link-font-size);
	text-transform: uppercase;
	line-height: var(--accordion-line-height);
	padding-top: var(--accordion-spacer);
	padding-bottom: var(--accordion-spacer);
	font-weight: var(--font-weight-medium);
	display: block;
}

.accordion-content {
	padding-top: .5em;
	padding-bottom: var(--accordion-spacer);
	font-size: var(--accordion-text-font-size);
}

.accordion-content a:not(.arrow-before) {
	text-decoration: underline;
}

.accordion-content table {
	width: 100%;
}

.accordion-content table td {
	vertical-align: top;
}

.accordion-content table td:first-child {
	font-weight: var(--font-weight-medium);
	width: 25%;
}

.accordion a.link-download {
	margin-bottom: 1em;
}

.accordion address {
	font-weight: var(--font-weight-medium) !important;
}

.accordion .image {
	margin-top: 0;
	margin-bottom: 0;
}

/* information */
.accordion-information h2 {
	margin-top: var(--spacer-responsive);
}

.accordion-information h3 {
	margin-top: 0;
	margin-bottom: 1em;
}

.accordion-information .image {
	margin-bottom: calc(var(--spacer-responsive) / 2);
}

.accordion-information .row + .row {
	margin-top: var(--spacer-responsive);
}

.accordion-information .text-cols {
	margin-top: var(--spacer-responsive);
}

.accordion .accordion-content p,
.accordion .accordion-content table {
	margin: 0 0 1.3em 0;
	max-width: none;
}

.accordion li,
.accordion p + p {
	margin-top: 1em;
}

@media (max-width: 767px) {
	.accordion .contact [class*=col-] + [class*=col-],
	.accordion .contact .col + .col {
		margin-top: 1.3em;
	}
}

@media (min-width: 768px) {
	:root {
		--accordion-spacer: 35px;
		--accordion-link-font-size: 40px;
		--accordion-line-height: 40px;
		--accordion-text-font-size: 18px; /* 16px | 18px | 18px */
	}
}

@media (min-width: 1200px) {
	:root {
		--accordion-spacer: 30px;
		--accordion-link-font-size: 58px;
		--accordion-line-height: 58px;
	}
}

@media (min-width: 1921px) {
	:root {
		--accordion-text-font-size: max(18px, 0.9375vw);
	}
}
