blockquote {
	--bq-font-size: 22px;
	--bq-line-height: 26px;
	--bq-spacer: 80px;

	font-size: var(--bq-font-size);
	line-height: var(--bq-line-height);
	font-family: var(--font-family-serif);
	text-align: center;
	margin: var(--bq-spacer) auto;
}

.quote::before { content: '„' }

.quote::after { content: '“' }

cite {
	--cite-font-size: 14px;
	--cite-line-height: 18px;
	--cite-spacer: 10px;

	margin-top: var(--cite-spacer);
	display: block;
	width: 100%;
	font-style: normal;
	font-family: var(--font-family-sans);
	font-size: var(--cite-font-size);
}

@media (min-width: 768px) {
	blockquote {
		--bq-font-size: 26px;
		--bq-line-height: 30px;
		--bq-spacer: 60px;

		max-width: calc(100% / 6 * 4);
	}

	cite {
		--cite-spacer: 15px;
	}
}

@media (min-width: 1200px) {
	blockquote {
		--bq-font-size: 39px;
		--bq-line-height: 42px;
		--bq-spacer: 120px;
	}

	cite {
		--cite-font-size: 18px;
		--cite-line-height: 18px;
		--cite-spacer: 30px;
	}
}

@media (min-width: 1921px) {
	blockquote {
		--bq-font-size: max(39px, 2vw);
		--bq-line-height: 1.2;
	}

	cite {
		--cite-font-size: max(18px, 0.8vw);
		--cite-line-height: 1;
	}
}
