.copyright {
	--copyright-spacer: 60px;
	--copyright-font-size: 14px;

	margin-top: var(--copyright-spacer);
	margin-bottom: var(--copyright-spacer);
	font-family: var(--font-family-sans);
	font-size: var(--copyright-font-size);
	font-weight: var(--font-weight-light);
	text-align: center;
}

@media (min-width: 768px) {
	.copyright {
		--copyright-spacer: 70px;
		--copyright-font-size: 14px;
	}
}

@media (min-width: 1200px) {
	.copyright {
		--copyright-spacer: 90px;
		--copyright-font-size: 16px;
	}
}

@media (min-width: 1921px) {
	.copyright {
		--copyright-font-size: max(16px, 0.8vw);
	}
}
