

.images.row {
	margin-top: calc(var(--spacer-responsive) * 2);
	margin-bottom: calc(var(--spacer-responsive) * 2);
}

.images [class*='col-'] {
	display: flex;
	margin-top: calc(var(--spacer-responsive) / 2);
	margin-bottom: calc(var(--spacer-responsive) / 2);
}

.images.row .image {
	margin-top: auto;
	margin-bottom: auto;
}

@media (max-width: 767px) {
	.images.row {
		flex-wrap: nowrap;
	}

	.images.row [class*='col-'] {
		width: 87.5%;
		flex: 0 0 87.5%;
	}
}
