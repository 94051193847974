*,
::after,
::before {
	box-sizing: border-box;
	/*outline: 1px solid red;*/
}

html {
	font-family: var(--font-family-sans);
	line-height: 1.4;
}

body {
	margin: 0;
	font-size: var(--text-font-size);
	text-size-adjust: 100%;
}

em, address {
	font-style: normal;
	font-weight: var(--font-weight-medium);
}

main > :not(.full-width),
.pageheader .pageheader-body, .pagefooter {
	max-width: var(--page-max-width);
	margin-left: auto;
	margin-right: auto;
	padding-left: var(--page-padding);
	padding-right: var(--page-padding);
}

h1, .h1 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: var(--h1-font-size);
	font-weight: var(--font-weight-medium);
	text-transform: uppercase;
	padding-top: var(--h1-padding-y);
	padding-bottom: var(--h1-padding-y);
}

/*h1 a::before {*/
/*	content: '\200b';*/
/*	width: .75em;*/
/*	background: transparent var(--arrow-b64) center center no-repeat;*/
/*	background-size: contain;*/
/*	margin-right: var(--arrow-offset);*/
/*}*/

h2, .h2 {
	font-size: var(--h2-font-size);
	font-weight: var(--font-weight-normal);
	text-transform: uppercase;
}

h3, .h3 {
	font-size: var(--h3-font-size);
	line-height: var(--h3-line-height);
	font-family: var(--font-family-sans);
	margin-bottom: var(--h3-margin-bottom);
	font-weight: var(--font-weight-medium);
	text-transform: uppercase;
}

main > h2, main > h3 {
	/*width: var(--heading-width);*/
}

a.no-icon::before {
	content: '' !important;
	margin-right: inherit !important;
}

a {
	color: inherit;
	text-decoration: none;
}

a:hover,
a:focus {
	text-decoration: underline;
}

a::before,
a::after {
	display: inline-block;
	text-decoration: none;
}

p {
	font-family: var(--font-family-serif);
}

b {
	font-weight: bold;
}

figcaption, cite {
	font-family: var(--font-family-sans);
	font-size: var(--figcaption-font-size);
	line-height: var(--figcaption-line-height);
	margin-top: var(--figcaption-spacer);
	font-weight: var(--font-weight-light);
	text-align: center;
}

figure {
	margin: 0;
}

input,
button,
select,
optgroup,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

select, a, button {
	cursor: pointer;
}

.section-heading {
	position: relative;
}

.uppercase {
	text-transform: uppercase !important;
}

.sans {
	font-family: var(--font-family-sans) !important;
}

.serif {
	font-family: var(--font-family-serif) !important;
}

/* narrow centered content */
.narrow,
.detail > p:not(.intro),
.detail [data-module="textblock"],
.detail .product-contact-form {
	max-width: 660px;
	margin-left: auto;
	margin-right: auto;
}

.clearfix:after {
	content: '';
	clear: both;
	display: block;
	visibility: hidden;
	height: 0;
}

.hide, [hidden] {
	display: none !important;
}

.jsPageDot {
    position: fixed;
    top: 101vh;
    left: 101vw;
}

.hide-mobile {
	display: none;
}

@media (min-width: 768px) {
	.hide-mobile {
		display: inherit;
	}
	.hide-md, .hide-desktop {
		display: none;
	}

	main > h2, main > h3 {
		padding-right: var(--heading-width) !important;
	}

	.narrow,
	.detail > p:not(.intro),
	.detail [data-module="textblock"],
	.detail .product-contact-form {
		max-width: 768px;
	}
}

@media (min-width: 1200px) {
	.narrow,
	.detail > p:not(.intro),
	.detail [data-module="textblock"],
	.detail .product-contact-form {
		max-width: 900px;
	}
}

@media (min-width: 1921px) {
	.narrow,
	.detail > p:not(.intro),
	.detail [data-module="textblock"],
	.detail .product-contact-form {
		max-width: max(900px, 46vw);
	}
}
