.appointments {
	--appointment-grid-gap: 30px;
	--appointment-image-width: 100%;
	--appointment-spacer: 20px;
}

.appointments .marquee {
	margin-top: var(--appointment-spacer);
	margin-bottom: var(--appointment-spacer);
}

.appointment-count {
	display: none;
	float: right;
}

.appointment {
	border-bottom: 1px solid #000;
	padding-top: var(--appointment-spacer);
	padding-bottom: var(--appointment-spacer);
	margin-top: -1px;
}

.appointment a:hover,
.appointment a:active,
.appointment a:focus {
	text-decoration: none;
}

.appointment img {
	width: var(--appointment-image-width);
	height: auto;
}

.appointment h1, .appointment h2 {
	display: inline;
}

.appointment h1 {
	margin: var(--spacer-xxs) 0 0;
	font-size: var(--font-size-ml);
}

.appointment h2 {
	padding: 0;
	text-transform: none;
	margin: 0;
	font-family: var(--font-family-serif);
	font-size: var(--font-size-ml);
}

.appointment-date {
	font-size: var(--font-size-s);
	margin: var(--spacer-xxs) 0;
}

.appointment-date .date-from {
	margin-right: 0.75rem;
}

.appointment-date .date-to {
	margin-left: 0.75rem;
}

@media (min-width: 768px) {

	.appointment-count {
		display: block;
	}

	.appointments {
		--appointment-image-width: 216px;
		--appointment-spacer: 15px;
	}

	.appointment a {
		display: grid;
		/*grid-column-gap: var(--appointment-grid-gap);*/
		grid: auto auto / var(--appointment-image-width) auto;
		/*align-items: start;*/
		/*justify-items: start;*/
	}

	.appointment-detail-teaser.row {
		flex-direction: row-reverse;
	}

	.appointment-teaser {
		margin-left: var(--appointment-grid-gap);
	}

	.appointment-teaser h1 {
		margin: 0;
		font-size: var(--font-size-ll);
	}

	.appointment-teaser h2 {
		font-size: var(--font-size-ll);
	}

	.appointment-date {
		font-size: var(--font-size-m);
		margin-top: 15px;
	}

	.appointment-location {
		font-size: var(--font-size-m);
		margin-top: 15px;
		margin-left: var(--appointment-grid-gap);
	}
}

@media (min-width: 1200px) {

	.appointments {
		--appointment-grid-gap: 60px;
		--appointment-image-width: 285px;
		--appointment-spacer: 30px;
	}

	.appointment a {
		grid: auto auto / var(--appointment-image-width) auto 1fr;
	}

	.appointment-image {
		grid-row: span 2;
	}

	.appointment-teaser {
		grid-column: span 2;
	}

	.appointment-teaser h1 {
		font-size: 39px;
	}

	.appointment-teaser h2 {
		font-size: 39px;
	}

	.appointment-date {
		font-size: 18px;
		margin: 15px 30px 0 var(--appointment-grid-gap);
		align-self: end;
	}

	.appointment-location {
		font-size: 18px;
		margin: 0;
		align-self: end;
	}
}

@media (min-width: 1921px) {
	.appointment-teaser h1,
	.appointment-teaser h2 {
		font-size: max(39px, 2vw);
	}

	.appointment-date,
	.appointment-location {
		font-size: max(18px, 0.9375vw);
	}
}
