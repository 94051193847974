/* page styles that do only appear on home for example margins */

.home .showrooms[data-slider] {
	flex-wrap: nowrap;
	height: 100%;
}

/* DOSSIERS */
.home .dossiers header {
	--font-cut: -0.58em;
}

@media (max-width: 767px) {
	.home .showrooms[data-slider] .col {
		width: 87.5% !important;
		flex: 0 0 87.5% !important;
	}

	.home .dossiers .grid .tile:nth-child(n+2) {
		display: none;
	}

	.home .slider-buttons {
		margin-bottom: 20px;
	}
}

@media (max-width: 1199px) {
	.home .dossiers .grid .tile:nth-child(n+4) {
		display: none;
	}
}

@media (min-width: 768px) {
	.home .dossiers .grid .tile {

	}

	.home .dossiers .grid .tile:first-child {
		font-size: var(--h1-font-size);
		grid-column: span 6;
		grid-row: span 4;
	}
}
