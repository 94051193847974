[data-toggle-initial="closed"] {
	display: none;
}

[data-toggle]::after {
	content: '\200b';
	width: .75em;
	background: transparent var(--arrow-b64) center center no-repeat;
	background-size: contain;
	transform: rotate(90deg);
	margin-left: var(--arrow-offset);
}

[data-toggle][data-toggle-state="opened"]::after {
	transform: rotate(-90deg);
}

/* no arrow before link if it is a toggle */
a[data-toggle]::before {
	content: none;
}
