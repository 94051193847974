:root {
	--col-gap: var(--spacer-responsive);
	--col-count: 12;
}

.container {
	display: block;
	max-width: var(--page-max-width);
	padding-left: var(--col-gap);
	padding-right: var(--col-gap);
	margin-left: auto;
	margin-right: auto;
}

.row.no-gutters {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

/*.no-gutters.row {*/
/*	margin-left: 0 !important;*/
/*	margin-right: 0 !important;*/
/*}*/

.no-gutters > [class*=col-], .no-gutters > .col {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.full-width > .row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin-left: calc(var(--col-gap) * -.5) !important;
	margin-right: calc(var(--col-gap) * -.5) !important;
}

[class*=col-], .col {
	flex-grow: 1;
	padding-left: calc(var(--col-gap) / 2) !important;
	padding-right: calc(var(--col-gap) / 2) !important;
	flex-basis: 100%;
	width: 100%;
}

/* OptInMonster Reset */
.Campaign [class*=col-], .Campaign .col {
	padding-left: revert !important;
	padding-right: revert !important;
}

.col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}

/* mobile */

.col-1 { max-width: calc(100% / var(--col-count)); flex: 0 0 calc(100% / var(--col-count)); }

.col-2 { max-width: calc(100% / var(--col-count) * 2); flex: 0 0 calc(100% / var(--col-count) * 2); }

.col-3 { max-width: calc(100% / var(--col-count) * 3); flex: 0 0 calc(100% / var(--col-count) * 3); }

.col-4 { max-width: calc(100% / var(--col-count) * 4); flex: 0 0 calc(100% / var(--col-count) * 4); }

.col-5 { max-width: calc(100% / var(--col-count) * 5); flex: 0 0 calc(100% / var(--col-count) * 5); }

.col-6 { max-width: 50%; flex: 0 0 50%; }

.col-6 { max-width: calc(100% / var(--col-count) * 6); flex: 0 0 calc(100% / var(--col-count) * 6); }

.col-7 { max-width: calc(100% / var(--col-count) * 7); flex: 0 0 calc(100% / var(--col-count) * 7); }

.col-8 { max-width: calc(100% / var(--col-count) * 8); flex: 0 0 calc(100% / var(--col-count) * 8); }

.col-9 { max-width: calc(100% / var(--col-count) * 9); flex: 0 0 calc(100% / var(--col-count) * 9); }

.col-10 { max-width: calc(100% / var(--col-count) * 10); flex: 0 0 calc(100% / var(--col-count) * 10); }

.col-11 { max-width: calc(100% / var(--col-count) * 11); flex: 0 0 calc(100% / var(--col-count) * 11); }

.col-12 { max-width: 100%; flex: 0 0 100%; }

/* tablet */
@media (min-width: 768px) {
	.col-md-1 { max-width: calc(100% / var(--col-count)); flex: 0 0 calc(100% / var(--col-count)); }

	.col-md-2 { max-width: calc(100% / var(--col-count) * 2); flex: 0 0 calc(100% / var(--col-count) * 2); }

	.col-md-3 { max-width: calc(100% / var(--col-count) * 3); flex: 0 0 calc(100% / var(--col-count) * 3); }

	.col-md-4 { max-width: calc(100% / var(--col-count) * 4); flex: 0 0 calc(100% / var(--col-count) * 4); }

	.col-md-5 { max-width: calc(100% / var(--col-count) * 5); flex: 0 0 calc(100% / var(--col-count) * 5); }

	.col-md-6 { max-width: 50%; flex: 0 0 50%; }

	.col-md-7 { max-width: calc(100% / var(--col-count) * 7); flex: 0 0 calc(100% / var(--col-count) * 7); }

	.col-md-8 { max-width: calc(100% / var(--col-count) * 8); flex: 0 0 calc(100% / var(--col-count) * 8); }

	.col-md-9 { max-width: calc(100% / var(--col-count) * 9); flex: 0 0 calc(100% / var(--col-count) * 9); }

	.col-md-10 { max-width: calc(100% / var(--col-count) * 10); flex: 0 0 calc(100% / var(--col-count) * 10); }

	.col-md-11 { max-width: calc(100% / var(--col-count) * 11); flex: 0 0 calc(100% / var(--col-count) * 11); }

	.col-md-12 { max-width: 100%; flex: 0 0 100%; }

}

/* desktop */
@media (min-width: 1200px) {
	.col-lg-1 { max-width: calc(100% / var(--col-count)); flex: 0 0 calc(100% / var(--col-count)); }

	.col-lg-2 { max-width: calc(100% / var(--col-count) * 2); flex: 0 0 calc(100% / var(--col-count) * 2); }

	.col-lg-3 { max-width: calc(100% / var(--col-count) * 3); flex: 0 0 calc(100% / var(--col-count) * 3); }

	.col-lg-4 { max-width: calc(100% / var(--col-count) * 4); flex: 0 0 calc(100% / var(--col-count) * 4); }

	.col-lg-5 { max-width: calc(100% / var(--col-count) * 5); flex: 0 0 calc(100% / var(--col-count) * 5); }

	.col-lg-6 { max-width: 50%; flex: 0 0 50%; }

	.col-lg-7 { max-width: calc(100% / var(--col-count) * 7); flex: 0 0 calc(100% / var(--col-count) * 7); }

	.col-lg-8 { max-width: calc(100% / var(--col-count) * 8); flex: 0 0 calc(100% / var(--col-count) * 8); }

	.col-lg-9 { max-width: calc(100% / var(--col-count) * 9); flex: 0 0 calc(100% / var(--col-count) * 9); }

	.col-lg-10 { max-width: calc(100% / var(--col-count) * 10); flex: 0 0 calc(100% / var(--col-count) * 10); }

	.col-lg-11 { max-width: calc(100% / var(--col-count) * 11); flex: 0 0 calc(100% / var(--col-count) * 11); }

	.col-lg-12 { max-width: 100%; flex: 0 0 100%; }

}
