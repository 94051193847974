.slider {
	background-color: transparent;
	color: inherit;
}

.slider img {
	margin: 0 auto;
	max-width: 100%;
	display: flex;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
}

.slider figcaption {
	text-align: center;
	font-size: var(--font-size-m);
	font-family: var(--font-family-sans);
}

