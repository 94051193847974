/* Glossar Overlay */
.glossar-overlay {
	font-family: var(--font-family-serif);
	font-size: var(--font-size-s);
}

.glossar-overlay .window__close {
	top: 0;
}

.glossar-overlay .window__content {
	border-top: 1px solid #000;
	margin-top: 1rem;
	max-width: 500px;
}

.glossar-overlay h1 {
	font-family: var(--font-family-sans);
	font-size: max(30px, 1.6vw);
	hyphens: auto;
}

.glossar-overlay {
	--padding: 0.8rem 1rem;
}

@media (min-width: 768px) {
	.glossar-overlay {
		--padding: 1rem 3.5rem 0 2rem;
	}

	.glossar-overlay .window__inner {
		min-width: 600px;
		max-width: 1100px;
		padding-bottom: 2rem;
	}
}

/* Glossar Link */
[data-module='textblock'] a[href^="/glossar/"] {
	text-decoration: none;
	background: url('data:image/gif;base64,R0lGODlhAQAFAIAAAM3NzQAAACH5BAAAAAAALAAAAAABAAUAAAIChF0AOw==') repeat-x transparent 0 95%;
}

[data-module='textblock'] a[href^="/glossar/"]:hover,
[data-module='textblock'] a[href^="/glossar/"]:focus,
[data-module='textblock'] a[href^="/glossar/"]:active {
	background-color: #cdcdcd;
}

/* Glossar Bildergalerie */
.glossar-gallery {
	position: relative;
	display: grid;
	grid-template-columns: 15px 1fr 15px;
	grid-template-areas: 'prev main next';
}

.glossar-gallery [data-slider-previous] {
	height: 100%;
	align-content: center;
	grid-area: prev;
	font-size: var(--font-size-l);
}

.glossar-gallery [data-slider-next] {
	height: 100%;
	align-content: center;
	grid-area: next;
	font-size: var(--font-size-l);
	justify-self: end;
}

.glossar-footer {
	margin-top: 2rem;
	padding-top: 1rem;
	border-top: 1px solid #000;
}

/* Glossar Liste */
.glossar-az-list {
	font-family: var(--font-family-sans);
	font-size: var(--font-size-xxl);
	list-style: none;
	padding: 0;
}

.glossar-az-list span {
	color: var(--color-light-text);
}

.glossar-az-list li {
	display: inline-block;
}

.glossar-letters {
	overflow-x: hidden;
}

.glossar-has-article .glossar-letters {
	display: none;
}

.glossar-has-article .glossar-letters.is-visible {
	display: block;
}

/* Glossar Begriff */
.glossar-letter a {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.glossar-letter a.active {
	font-weight: bold;
}

.glossar-letter h2 {
	font-family: var(--font-family-sans);
	font-size: var(--font-size-xxl);
	font-weight: 400;
	margin: 0;
}

.glossar-article {
	position: relative;
	padding: 30px 15px 30px 0;
	width: 100%;
}

.glossar-article h1 {
	border-top: 1px solid #000;
	hyphens: auto;
}

.glossar-article .close {
	--close-width: 50px;
	--close-height: 50px;
	position: absolute;
	top: 42px;
	right: 0;
	text-decoration: none;
}

.glossar-article .close::before {
	content: '\00d7';
	font-family: var(--font-family-serif);
	font-size: var(--font-size-ml);
	width: var(--close-width);
	height: var(--close-width);
	display: flex;
	align-items: center;
	justify-content: center;
}

.glossar-overlay .container {
	padding-left: 0;
	padding-right: 0;
}

.glossar-overlay .inline-slider .previous,
.glossar-overlay .inline-slider .next {
	z-index: 1;
}

/* Fragezeichen Platzhalter */
.glossar-placeholder-img {
	max-width: 100%;
	height: 83%;
	color: #000;
	font-family: var(--font-family-sans-light);
	background-color: #fff;
	justify-content: center;
	align-items: center;
	font-size: 10rem;
	font-weight: 500;
	display: flex;
}

@media (min-width: 1000px) {
	.glossar-placeholder-img {
		font-size: max(15rem, 10vw);
	}

	.glossar-has-article .glossar-letters {
		display: block;
	}

	.glossar-section {
		display: flex;
		flex-flow: row nowrap;
		gap: 20px;
	}

	.glossar-article .close {
		display: none;
	}

	.glossar-letters {
		min-width: 150px;
	}
}

@media (min-width: 1200px) {
	.glossar-section {
		gap: 80px;
	}

	.glossar-article {
		box-shadow: 20px 20px 10px 0 #999;
		padding: 30px 40px 30px 0;
	}
}

@media (min-width: 1400px) {
	.glossar-letters {
		max-width: max(300px, 20%);
		flex-shrink: 0;
	}
}
