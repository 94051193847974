.arrow-before::before,
.arrow-after::after {
	content: '\200b' !important;
	width: .75em;
	background: transparent var(--arrow-b64) center center no-repeat;
	background-size: contain;
	display: inline-block;
}

.arrow-before.arrow--back::before {
	background-image: var(--arrow-b64-back) !important;
	width: 0.8em;
}

.arrow-before.invert::before,
.arrow-after.invert::after {
	filter: invert();
}

.arrow-before::before { margin-right: var(--arrow-offset) }

.arrow-after::after { margin-left: var(--arrow-offset) }

/* right */
.arrow--right.arrow-before::before {transform: rotate(0deg) }

.arrow--right.arrow-after::after {transform: rotate(0deg) }

/* left(optional) */
.arrow--left.arrow-before::before { transform: rotate(180deg)}

.arrow--left.arrow-after::after { transform: rotate(180deg)}

/* top */
.arrow--top.arrow-before::before {transform: rotate(-90deg) }

.arrow--top.arrow-after::after {transform: rotate(-90deg) }

/* bottom */
.arrow--bottom.arrow-before::before {transform: rotate(90deg) }

.arrow--bottom.arrow-after::after {transform: rotate(90deg) }
