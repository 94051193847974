.marquee {
	--marquee-font-size: 26px; /* 26 | 42 | 62 */
	--marquee-spacer: 27px; /* 27 | 36 | 25*/

	font-size: var(--marquee-font-size);
	line-height: 1;
	background: #000;
	color: #fff;
	padding-top: var(--marquee-spacer);
	padding-bottom: var(--marquee-spacer);
	overflow: hidden;
	white-space: nowrap;
	animation: marquee 20s linear infinite;
}

.marquee:hover {
	animation-play-state: paused;
}

.marquee a {
	margin-right: var(--spacer-responsive);
}

.marquee .arrow-before::before {
	width: 31px; /* rounding with em will otherwise result in flicker */
}

@keyframes marquee {
	0% { text-indent: 100% }
	100% { text-indent: -200% }
}

@media (min-width: 768px) {
	.marquee {
		--marquee-font-size: 42px; /* 26 | 42 | 62 */
		--marquee-spacer: 36px; /* 27 | 36 | 25*/
	}
}

@media (min-width: 1200px) {
	.marquee {
		--marquee-font-size: 62px; /* 26 | 42 | 62 */
		--marquee-spacer: 25px; /* 27 | 36 | 25*/
	}
}
