:root {
  --spacer-xxl: 7.5rem;
  --spacer-xl: 5rem;
  --spacer-l: 3.75rem;
  --spacer-m: 2.5rem;
  --spacer-s: 2.188rem;
  --spacer-xs: 1.875rem;
  --spacer-xxs: 1.25rem;
  --spacer-responsive: var(--spacer-xxs);
  --spacer-responsive-l: var(--spacer-xl);
  --spacer-font-based: 1em;
  --spacer-font-based-s: .5em;
  --padding: 1.125rem;
  --padding-s: .625rem;
  --page-padding: var(--spacer-responsive);
  --page-max-width: 1440px;
  --page-content-max-width: calc(var(--page-max-width)  - var(--page-padding) * 2);
  --color-bg: #fff;
  --color-fg: #000;
  --color-light-text: #c8c8c8;
  --color-border: var(--color-fg);
  --arrow-offset: .2em;
  --arrow-b64: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NTQgMzcyIj4KICA8Zz4KICAgIDxwYXRoIGQ9Ik0yNjkgMGwtNDMgNDYgMTEwIDEwOUgwdjY0aDMzNEwyMjYgMzI3bDQzIDQ1IDE4NS0xODZMMjY5IDB6Ii8+CiAgPC9nPgo8L3N2Zz4K");
  --arrow-b64-white: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NTQgMzcyIj4KICA8Zz4KICAgIDxwYXRoIGZpbGw9IiNjOGM4YzgiIGQ9Ik0yNjkgMGwtNDMgNDYgMTEwIDEwOUgwdjY0aDMzNEwyMjYgMzI3bDQzIDQ1IDE4NS0xODZMMjY5IDB6Ii8+CiAgPC9nPgo8L3N2Zz4K");
  --arrow-b64-back: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDAyIiB3aWR0aD0iNDgzLjIiPjxnPjxwYXRoIGQ9Ik0yNTAuMjUgMzg3bDM4LjM4LTQzLjgtOTguMDgtMTEwLjRINDgzLjJ2LTY0LjJIMTkxLjA4bDk3LjU1LTEwOS44TDI1MC4yNSAxNSA4NSAyMDF6TTQ1IDB2NDAySDBWMHoiLz48L2c+PC9zdmc+Cg==");
  --font-family-sans: "Suisse Intl", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-serif: "Noto Serif KR", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-sans-light: var(--font-family-sans);
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;
  --font-weight-black: 900;
  --font-size-xxxl: 2.625rem;
  --font-size-xxl: 1.625rem;
  --font-size-xll: 1.375rem;
  --font-size-xl: var(--font-size-m);
  --font-size-ll: 1.375rem;
  --font-size-ml: 1.25rem;
  --font-size-l: var(--font-size-m);
  --font-size-xxm: var(--font-size-m);
  --font-size-xm: var(--font-size-m);
  --font-size-m: 1.125rem;
  --font-size-s: 1rem;
  --font-size-xs: .875rem;
  --h1-font-size: 1.625rem;
  --h1-line-height: 1.625rem;
  --h1-padding-y: 20px;
  --h2-font-size: 18px;
  --h2-line-height: 22px;
  --h3-font-size: 18px;
  --h3-line-height: 22px;
  --h3-margin-bottom: var(--spacer-s);
  --text-font-size: 16px;
  --text-line-height: 1.16;
  --figcaption-font-size: 14px;
  --figcaption-line-height: 1.3;
  --figcaption-spacer: 15px;
  --centered-content-width: 100%;
}

@media (min-width: 768px) {
  :root {
    --font-size-xxl: 2.5rem;
    --font-size-xll: 1.625rem;
    --font-size-xl: 1.5rem;
    --font-size-ll: 1.625rem;
    --font-size-l: 1.5rem;
    --font-size-xxm: 1.25rem;
    --font-size-xm: 1.125rem;
    --font-size-m: 1rem;
    --font-size-s: 1rem;
    --font-size-xs: 1rem;
    --spacer-responsive: var(--spacer-xs);
    --spacer-responsive-l: 112px;
    --h1-font-size: 2.5rem;
    --h1-line-height: 2.5rem;
    --h2-font-size: 24px;
    --h2-line-height: 28px;
    --h3-font-size: 24px;
    --h3-line-height: 28px;
    --h3-margin-bottom: var(--spacer-l);
    --text-font-size: 18px;
    --heading-width: calc(100% - 100% / 6 * 4);
    --centered-content-width: calc(100% / 6 * 4);
  }
}

@media (min-width: 1200px) {
  :root {
    --font-size-xxl: 3.625rem;
    --font-size-xll: 3.25rem;
    --font-size-xl: 3rem;
    --font-size-ll: 2.438rem;
    --font-size-l: 2.25rem;
    --font-size-xxm: 1.625rem;
    --font-size-xmm: 1.5rem;
    --font-size-xm: 1.25rem;
    --font-size-m: 1.125rem;
    --font-size-s: .875rem;
    --spacer-responsive: var(--spacer-l);
    --spacer-responsive-l: var(--spacer-xxl);
    --h1-font-size: 3.625rem;
    --h1-line-height: 3.625rem;
    --h2-font-size: 36px;
    --h2-line-height: 40px;
    --h3-font-size: 26px;
    --h3-line-height: 26px;
    --h3-margin-bottom: var(--spacer-xxl);
    --text-font-size: 18px;
    --figcaption-font-size: 16px;
    --heading-width: calc(100% - 100% / 6 * 5);
  }
}

@media (min-width: 1921px) {
  :root {
    --text-font-size: max(18px, .9375vw);
    --font-size-l: max(2.25rem, 1.3vw);
    --font-size-xmm: max(1.5rem, .85vw);
    --font-size-xm: max(1.25rem, 1.04vw);
    --font-size-m: max(1.125rem, .85vw);
    --font-size-s: max(.875rem, .7vw);
    --font-size-xs: max(1rem, .75vw);
    --page-max-width: max(1440px, 75vw);
    --figcaption-font-size: max(16px, .66vw);
    --h1-font-size: max(3.625rem, 2.5vw);
  }
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-block {
  display: inline-block !important;
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }
}

:root {
  --col-gap: var(--spacer-responsive);
  --col-count: 12;
}

.container {
  max-width: var(--page-max-width);
  padding-left: var(--col-gap);
  padding-right: var(--col-gap);
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.row.no-gutters {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.no-gutters > [class*="col-"], .no-gutters > .col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.full-width > .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.row {
  flex-wrap: wrap;
  display: flex;
  margin-left: calc(var(--col-gap) * -.5) !important;
  margin-right: calc(var(--col-gap) * -.5) !important;
}

[class*="col-"], .col {
  width: 100%;
  flex-grow: 1;
  flex-basis: 100%;
  padding-left: calc(var(--col-gap) / 2) !important;
  padding-right: calc(var(--col-gap) / 2) !important;
}

.Campaign [class*="col-"], .Campaign .col {
  padding-left: revert !important;
  padding-right: revert !important;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.col-1 {
  max-width: calc(100% / var(--col-count));
  flex: 0 0 calc(100% / var(--col-count));
}

.col-2 {
  max-width: calc(100% / var(--col-count) * 2);
  flex: 0 0 calc(100% / var(--col-count) * 2);
}

.col-3 {
  max-width: calc(100% / var(--col-count) * 3);
  flex: 0 0 calc(100% / var(--col-count) * 3);
}

.col-4 {
  max-width: calc(100% / var(--col-count) * 4);
  flex: 0 0 calc(100% / var(--col-count) * 4);
}

.col-5 {
  max-width: calc(100% / var(--col-count) * 5);
  flex: 0 0 calc(100% / var(--col-count) * 5);
}

.col-6 {
  max-width: 50%;
  max-width: calc(100% / var(--col-count) * 6);
  flex: 0 0 50%;
  flex: 0 0 calc(100% / var(--col-count) * 6);
}

.col-7 {
  max-width: calc(100% / var(--col-count) * 7);
  flex: 0 0 calc(100% / var(--col-count) * 7);
}

.col-8 {
  max-width: calc(100% / var(--col-count) * 8);
  flex: 0 0 calc(100% / var(--col-count) * 8);
}

.col-9 {
  max-width: calc(100% / var(--col-count) * 9);
  flex: 0 0 calc(100% / var(--col-count) * 9);
}

.col-10 {
  max-width: calc(100% / var(--col-count) * 10);
  flex: 0 0 calc(100% / var(--col-count) * 10);
}

.col-11 {
  max-width: calc(100% / var(--col-count) * 11);
  flex: 0 0 calc(100% / var(--col-count) * 11);
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

@media (min-width: 768px) {
  .col-md-1 {
    max-width: calc(100% / var(--col-count));
    flex: 0 0 calc(100% / var(--col-count));
  }

  .col-md-2 {
    max-width: calc(100% / var(--col-count) * 2);
    flex: 0 0 calc(100% / var(--col-count) * 2);
  }

  .col-md-3 {
    max-width: calc(100% / var(--col-count) * 3);
    flex: 0 0 calc(100% / var(--col-count) * 3);
  }

  .col-md-4 {
    max-width: calc(100% / var(--col-count) * 4);
    flex: 0 0 calc(100% / var(--col-count) * 4);
  }

  .col-md-5 {
    max-width: calc(100% / var(--col-count) * 5);
    flex: 0 0 calc(100% / var(--col-count) * 5);
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: calc(100% / var(--col-count) * 7);
    flex: 0 0 calc(100% / var(--col-count) * 7);
  }

  .col-md-8 {
    max-width: calc(100% / var(--col-count) * 8);
    flex: 0 0 calc(100% / var(--col-count) * 8);
  }

  .col-md-9 {
    max-width: calc(100% / var(--col-count) * 9);
    flex: 0 0 calc(100% / var(--col-count) * 9);
  }

  .col-md-10 {
    max-width: calc(100% / var(--col-count) * 10);
    flex: 0 0 calc(100% / var(--col-count) * 10);
  }

  .col-md-11 {
    max-width: calc(100% / var(--col-count) * 11);
    flex: 0 0 calc(100% / var(--col-count) * 11);
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1 {
    max-width: calc(100% / var(--col-count));
    flex: 0 0 calc(100% / var(--col-count));
  }

  .col-lg-2 {
    max-width: calc(100% / var(--col-count) * 2);
    flex: 0 0 calc(100% / var(--col-count) * 2);
  }

  .col-lg-3 {
    max-width: calc(100% / var(--col-count) * 3);
    flex: 0 0 calc(100% / var(--col-count) * 3);
  }

  .col-lg-4 {
    max-width: calc(100% / var(--col-count) * 4);
    flex: 0 0 calc(100% / var(--col-count) * 4);
  }

  .col-lg-5 {
    max-width: calc(100% / var(--col-count) * 5);
    flex: 0 0 calc(100% / var(--col-count) * 5);
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: calc(100% / var(--col-count) * 7);
    flex: 0 0 calc(100% / var(--col-count) * 7);
  }

  .col-lg-8 {
    max-width: calc(100% / var(--col-count) * 8);
    flex: 0 0 calc(100% / var(--col-count) * 8);
  }

  .col-lg-9 {
    max-width: calc(100% / var(--col-count) * 9);
    flex: 0 0 calc(100% / var(--col-count) * 9);
  }

  .col-lg-10 {
    max-width: calc(100% / var(--col-count) * 10);
    flex: 0 0 calc(100% / var(--col-count) * 10);
  }

  .col-lg-11 {
    max-width: calc(100% / var(--col-count) * 11);
    flex: 0 0 calc(100% / var(--col-count) * 11);
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

:root {
  --grid-col-span-xl: 12;
  --grid-col-span-l: 12;
  --grid-col-span-m: 12;
  --grid-col-span-s: 12;
  --grid-row-span-xl: 1;
  --grid-row-span-l: 1;
  --grid-row-span-m: 1;
  --grid-row-span-s: 1;
}

@media (min-width: 768px) {
  :root {
    --grid-col-span-xl: 12;
    --grid-col-span-l: 12;
    --grid-col-span-m: 6;
    --grid-col-span-s: 6;
    --grid-row-span-xl: 2;
    --grid-row-span-l: 2;
    --grid-row-span-m: 2;
    --grid-row-span-s: 1;
  }
}

@media (min-width: 1200px) {
  :root {
    --grid-col-span-xl: 12;
    --grid-col-span-l: 6;
    --grid-col-span-m: 3;
    --grid-col-span-s: 3;
    --grid-row-span-xl: 2;
    --grid-row-span-l: 2;
    --grid-row-span-m: 2;
    --grid-row-span-s: 1;
  }
}

.grid {
  grid-auto-flow: dense;
  grid-template-columns: repeat(12, 1fr);
  display: grid;
}

.grid.grid-scroll {
  grid-auto-columns: 7.29167%;
  grid-auto-flow: column;
  grid-template-columns: initial;
  display: grid;
  overflow-x: auto;
}

.grid-layout-1 > *, .grid-layout-2 > *, .grid-layout-4 > * {
  grid-column: span var(--grid-col-span-m);
  grid-row: span var(--grid-row-span-m);
}

.grid-layout-3 > * {
  grid-column: span var(--grid-col-span-s);
  grid-row: span var(--grid-row-span-s);
}

.grid-layout-1 > :first-child, .grid-layout-4 > :first-child {
  --font-size: var(--font-size-xl);
  --font-cut: var(--tile-header-font-cut-xl);
  grid-column: span var(--grid-col-span-xl);
  grid-row: span var(--grid-row-span-xl);
  padding: var(--spacer-responsive);
}

.grid-layout-1 > :nth-child(8n+4), .grid-layout-1 > :nth-child(8n+5), .grid-layout-2 > :nth-child(7), .grid-layout-2 > :nth-child(19n+8), .grid-layout-2 > :nth-child(19n+12), .grid-layout-2 > :nth-child(19n+18), .grid-layout-2 > :nth-child(28), .grid-layout-3 > :nth-child(5n+1), .grid-layout-4 > :nth-child(4), .grid-layout-4 > :nth-child(5) {
  --font-size: var(--font-size-l);
  --font-cut: var(--tile-header-font-cut-l);
  grid-column: span var(--grid-col-span-l);
  grid-row: span var(--grid-row-span-l);
  padding: var(--spacer-responsive);
}

.grid-layout-1 > :nth-child(8n+7), .grid-layout-1 > :nth-child(8n+8), .grid-layout-4 > :nth-child(7), .grid-layout-4 > :nth-child(8), .grid-layout-2 > :nth-child(19n+14), .grid-layout-2 > :nth-child(19n+15), .grid-layout-2 > :nth-child(19n+16), .grid-layout-2 > :nth-child(19n+17) {
  grid-column: span var(--grid-col-span-s);
  grid-row: span var(--grid-row-span-s);
}

@supports (font-size: clamp(1px,2px,3px)) {
  @media (max-width: 767px) {
    .grid-layout-1 > .tile, .grid-layout-2 > .tile, .grid-layout-3 > .tile, .grid-layout-4 > .tile, .grid.grid-scroll > .tile:not(.teaser) {
      --font-size: clamp(1.125rem, calc(1.125rem + (1.5 - 1.125) * ((100vw - 28.125rem) / (48 - 28.125) ) ), 1.5rem);
    }

    .home .ddoi {
      --ddoi-header-font-size: clamp(1.125rem, calc(1.125rem + (1.5 - 1.125) * ((100vw - 28.125rem) / (48 - 28.125) ) ), 1.5rem);
    }

    .grid-layout-1 > :first-child, .grid-layout-4 > :first-child, .detail .tile.tile-xl {
      --font-size: clamp(1.125rem, calc(1.125rem + (1.5 - 1.125) * ((100vw - 28.125rem) / (48 - 28.125) ) ), 1.5rem);
    }
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    .grid-layout-1 > .tile, .grid-layout-2 > .tile, .grid-layout-3 > .tile, .grid-layout-4 > .tile, .grid.grid-scroll > .tile:not(.teaser) {
      --font-size: clamp(1rem, calc(1rem + (1.5 - 1) * ((100vw - 48rem) / (75 - 48) ) ), 1.5rem);
    }

    .grid-layout-1 > :first-child, .grid-layout-4 > :first-child, .detail .tile.tile-xl {
      --font-size: clamp(1.5rem, calc(1.5rem + (3 - 1.5) * ((100vw - 48rem) / (75 - 48) ) ), 3rem);
      padding: max(30px, min(1.875rem + 6.94444vw - 53.3333px, 60px));
    }

    .grid-layout-1 > :nth-child(8n+4), .grid-layout-1 > :nth-child(8n+5), .grid-layout-2 > :nth-child(7), .grid-layout-2 > :nth-child(19n+8), .grid-layout-2 > :nth-child(19n+12), .grid-layout-2 > :nth-child(19n+18), .grid-layout-2 > :nth-child(28), .grid-layout-3 > :nth-child(5n+1), .grid-layout-4 > :nth-child(4), .grid-layout-4 > :nth-child(5) {
      --font-size: clamp(1.5rem, calc(1.5rem + (2.25 - 1.5) * ((100vw - 48rem) / (75 - 48) ) ), 2.25rem);
      padding: max(30px, min(1.875rem + 6.94444vw - 53.3333px, 60px));
    }
  }

  @media (min-width: 1281px) {
    .grid-layout-1 > :first-child header, .grid-layout-4 > :first-child header, .detail .tile.tile-xl header {
      padding-right: calc(min(100% - 1140px, 33%));
    }

    .grid-layout-1 .tile:not(.tile-xl):not(:first-child) .image-inner img, .grid-layout-1 .tile:not(.tile-xl):not(:first-child) .image-inner canvas, .grid-layout-4 .tile:not(.tile-xl):not(:first-child) .image-inner img, .grid-layout-4 .tile:not(.tile-xl):not(:first-child) .image-inner canvas, .grid-layout-2 .tile:not(.tile-xl) .image-inner img, .grid-layout-2 .tile:not(.tile-xl) .image-inner canvas, .grid-scroll .image-inner img, .grid-scroll .image-inner canvas {
      max-width: min(100%, 19vw);
    }

    .grid-layout-1 > :nth-child(8n+4) .tile-inner .image .image-inner canvas, .grid-layout-1 > :nth-child(8n+4) .tile-inner .image .image-inner img, .grid-layout-1 > :nth-child(8n+5) .tile-inner .image .image-inner canvas, .grid-layout-1 > :nth-child(8n+5) .tile-inner .image .image-inner img, .grid-layout-4 > :nth-child(4) .tile-inner .image .image-inner canvas, .grid-layout-4 > :nth-child(4) .tile-inner .image .image-inner img, .grid-layout-4 > :nth-child(5) .tile-inner .image .image-inner canvas, .grid-layout-4 > :nth-child(5) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(7) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(7) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(19n+8) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+8) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(19n+12) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+12) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(19n+18) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+18) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(28) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(28) .tile-inner .image .image-inner img, .grid-layout-3 > :nth-child(5n+1) .tile-inner .image .image-inner canvas, .grid-layout-3 > :nth-child(5n+1) .tile-inner .image .image-inner img, .tile.header-big-lg .image-inner canvas, .tile.header-big-lg .image-inner img {
      max-width: min(100%, 40vw);
    }

    .grid-layout-1 > :nth-child(8n+4), .grid-layout-1 > :nth-child(8n+5), .grid-layout-4 > :nth-child(4), .grid-layout-4 > :nth-child(5), .grid-layout-2 > :nth-child(7), .grid-layout-2 > :nth-child(19n+8), .grid-layout-2 > :nth-child(19n+12), .grid-layout-2 > :nth-child(19n+18), .grid-layout-2 > :nth-child(28), .grid-layout-3 > :nth-child(5n+1), .grid-scroll[data-slider="stories"] .grid-col-lg-6 {
      padding: max(60px, 2vw) max(60px, 3vw);
    }
  }

  @media (min-width: 1921px) {
    .grid-layout-1 > .tile, .grid-layout-2 > .tile, .grid-layout-3 > .tile, .grid-layout-4 > .tile, .grid.grid-scroll > .tile:not(.teaser) {
      --font-size: .9375vw;
      --tile-spacer: max(30px, 1.5vw);
    }

    .grid-layout-1 > :first-child, .grid-layout-4 > :first-child, .detail .tile.tile-xl {
      --font-size: max(3rem, 2vw);
    }

    .grid-layout-1 > :nth-child(8n+4), .grid-layout-1 > :nth-child(8n+5), .grid-layout-4 > :nth-child(4), .grid-layout-4 > :nth-child(5), .grid-layout-2 > :nth-child(7), .grid-layout-2 > :nth-child(19n+8), .grid-layout-2 > :nth-child(19n+12), .grid-layout-2 > :nth-child(19n+18), .grid-layout-2 > :nth-child(28), .grid-layout-3 > :nth-child(5n+1) {
      --font-size: max(2.25rem, 1.5vw);
    }
  }
}

@media (min-width: 481px) {
  .grid-layout-3.tiles-large > .tile {
    --font-size: var(--font-size-l);
    --font-cut: var(--tile-header-font-cut-l);
  }

  .grid-layout-3.tiles-large > :nth-child(5n+1) {
    --font-size: var(--font-size-xxl);
    --font-cut: var(--tile-header-font-cut-l);
  }
}

[class*="grid-col-"] {
  grid-column: span 12 !important;
}

.grid-col-1 {
  grid-column: span 1 !important;
}

.grid-col-2 {
  grid-column: span 2 !important;
}

.grid-col-3 {
  grid-column: span 3 !important;
}

.grid-col-4 {
  grid-column: span 4 !important;
}

.grid-col-5 {
  grid-column: span 5 !important;
}

.grid-col-6 {
  grid-column: span 6 !important;
}

.grid-col-7 {
  grid-column: span 7 !important;
}

.grid-col-8 {
  grid-column: span 8 !important;
}

.grid-col-9 {
  grid-column: span 9 !important;
}

.grid-col-10 {
  grid-column: span 10 !important;
}

.grid-col-11 {
  grid-column: span 11 !important;
}

.grid-col-12 {
  grid-column: span 12 !important;
}

@media (min-width: 768px) {
  .grid.grid-scroll {
    grid-auto-columns: 8.33333%;
  }

  .grid-col-md-1 {
    grid-column: span 1 !important;
  }

  .grid-col-md-2 {
    grid-column: span 2 !important;
  }

  .grid-col-md-3 {
    grid-column: span 3 !important;
  }

  .grid-col-md-4 {
    grid-column: span 4 !important;
  }

  .grid-col-md-5 {
    grid-column: span 5 !important;
  }

  .grid-col-md-6 {
    grid-column: span 6 !important;
  }

  .grid-col-md-7 {
    grid-column: span 7 !important;
  }

  .grid-col-md-8 {
    grid-column: span 8 !important;
  }

  .grid-col-md-9 {
    grid-column: span 9 !important;
  }

  .grid-col-md-10 {
    grid-column: span 10 !important;
  }

  .grid-col-md-11 {
    grid-column: span 11 !important;
  }

  .grid-col-md-12 {
    grid-column: span 12 !important;
  }
}

@media (min-width: 1200px) {
  .grid-col-lg-1 {
    grid-column: span 1 !important;
  }

  .grid-col-lg-2 {
    grid-column: span 2 !important;
  }

  .grid-col-lg-3 {
    grid-column: span 3 !important;
  }

  .grid-col-lg-4 {
    grid-column: span 4 !important;
  }

  .grid-col-lg-5 {
    grid-column: span 5 !important;
  }

  .grid-col-lg-6 {
    grid-column: span 6 !important;
  }

  .grid-col-lg-7 {
    grid-column: span 7 !important;
  }

  .grid-col-lg-8 {
    grid-column: span 8 !important;
  }

  .grid-col-lg-9 {
    grid-column: span 9 !important;
  }

  .grid-col-lg-10 {
    grid-column: span 10 !important;
  }

  .grid-col-lg-11 {
    grid-column: span 11 !important;
  }

  .grid-col-lg-12 {
    grid-column: span 12 !important;
  }
}

.grid-row-1 {
  grid-row: span 1 !important;
}

.grid-row-2 {
  grid-row: span 2 !important;
}

.grid-row-3 {
  grid-row: span 3 !important;
}

.grid-row-4 {
  grid-row: span 4 !important;
}

.grid-row-5 {
  grid-row: span 5 !important;
}

.grid-row-6 {
  grid-row: span 6 !important;
}

.grid-row-7 {
  grid-row: span 7 !important;
}

.grid-row-8 {
  grid-row: span 8 !important;
}

.grid-row-9 {
  grid-row: span 9 !important;
}

.grid-row-10 {
  grid-row: span 10 !important;
}

.grid-row-11 {
  grid-row: span 11 !important;
}

.grid-row-12 {
  grid-row: span 12 !important;
}

@media (min-width: 768px) {
  .grid-row-md-1 {
    grid-row: span 1 !important;
  }

  .grid-row-md-2 {
    grid-row: span 2 !important;
  }

  .grid-row-md-3 {
    grid-row: span 3 !important;
  }

  .grid-row-md-4 {
    grid-row: span 4 !important;
  }

  .grid-row-md-5 {
    grid-row: span 5 !important;
  }

  .grid-row-md-6 {
    grid-row: span 6 !important;
  }

  .grid-row-md-7 {
    grid-row: span 7 !important;
  }

  .grid-row-md-8 {
    grid-row: span 8 !important;
  }

  .grid-row-md-9 {
    grid-row: span 9 !important;
  }

  .grid-row-md-10 {
    grid-row: span 10 !important;
  }

  .grid-row-md-11 {
    grid-row: span 11 !important;
  }

  .grid-row-md-12 {
    grid-row: span 12 !important;
  }
}

@media (min-width: 1200px) {
  .grid-row-lg-1 {
    grid-row: span 1 !important;
  }

  .grid-row-lg-2 {
    grid-row: span 2 !important;
  }

  .grid-row-lg-3 {
    grid-row: span 3 !important;
  }

  .grid-row-lg-4 {
    grid-row: span 4 !important;
  }

  .grid-row-lg-5 {
    grid-row: span 5 !important;
  }

  .grid-row-lg-6 {
    grid-row: span 6 !important;
  }

  .grid-row-lg-7 {
    grid-row: span 7 !important;
  }

  .grid-row-lg-8 {
    grid-row: span 8 !important;
  }

  .grid-row-lg-9 {
    grid-row: span 9 !important;
  }

  .grid-row-lg-10 {
    grid-row: span 10 !important;
  }

  .grid-row-lg-11 {
    grid-row: span 11 !important;
  }

  .grid-row-lg-12 {
    grid-row: span 12 !important;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-xxs {
  margin-top: var(--spacer-xxs) !important;
}

.mt-xs {
  margin-top: var(--spacer-xs) !important;
}

.mt-s {
  margin-top: var(--spacer-s) !important;
}

.mt-m {
  margin-top: var(--spacer-m) !important;
}

.mt-l {
  margin-top: var(--spacer-l) !important;
}

.mt-xl {
  margin-top: var(--spacer-xl) !important;
}

.mt-xxl {
  margin-top: var(--spacer-xxl) !important;
}

@media (min-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-xxs {
    margin-top: var(--spacer-xxs) !important;
  }

  .mt-md-xs {
    margin-top: var(--spacer-xs) !important;
  }

  .mt-md-s {
    margin-top: var(--spacer-s) !important;
  }

  .mt-md-m {
    margin-top: var(--spacer-m) !important;
  }

  .mt-md-l {
    margin-top: var(--spacer-l) !important;
  }

  .mt-md-xl {
    margin-top: var(--spacer-xl) !important;
  }

  .mt-md-xxl {
    margin-top: var(--spacer-xxl) !important;
  }
}

@media (min-width: 1200px) {
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-xxs {
    margin-top: var(--spacer-xxs) !important;
  }

  .mt-lg-xs {
    margin-top: var(--spacer-xs) !important;
  }

  .mt-lg-s {
    margin-top: var(--spacer-s) !important;
  }

  .mt-lg-m {
    margin-top: var(--spacer-m) !important;
  }

  .mt-lg-l {
    margin-top: var(--spacer-l) !important;
  }

  .mt-lg-xl {
    margin-top: var(--spacer-xl) !important;
  }

  .mt-lg-xxl {
    margin-top: var(--spacer-xxl) !important;
  }
}

.text-cols {
  --text-col-cap: 60px;
}

.text-cols-span-all {
  column-span: all;
}

@media (min-width: 1200px) {
  .text-cols {
    column-count: 2;
    column-gap: var(--text-col-cap);
  }

  .text-cols p:first-child {
    margin-top: 0;
  }
}

.text-decoration-none, .text-decoration-none[href]:hover, .text-decoration-none[href]:active, .text-decoration-none[href]:focus {
  text-decoration: none !important;
}

@font-face {
  font-family: Noto Serif KR;
  font-style: normal;
  font-weight: 200;
  src: local(Noto Serif KR ExtraLight), local(NotoSerifKR-ExtraLight), url("noto-serif-kr-v6-latin-200.7a93828b.woff2") format("woff2"), url("noto-serif-kr-v6-latin-200.33be691c.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Noto Serif KR;
  font-style: normal;
  font-weight: 300;
  src: local(Noto Serif KR Light), local(NotoSerifKR-Light), url("noto-serif-kr-v6-latin-300.e720293a.woff2") format("woff2"), url("noto-serif-kr-v6-latin-300.8dd87ce4.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Noto Serif KR;
  font-style: normal;
  font-weight: 400;
  src: local(Noto Serif KR), local(NotoSerifKR-Regular), url("noto-serif-kr-v6-latin-regular.84931d36.woff2") format("woff2"), url("noto-serif-kr-v6-latin-regular.ece96466.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Noto Serif KR;
  font-style: normal;
  font-weight: 500;
  src: local(Noto Serif KR Medium), local(NotoSerifKR-Medium), url("noto-serif-kr-v6-latin-500.56db13e3.woff2") format("woff2"), url("noto-serif-kr-v6-latin-500.5e8626fe.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Noto Serif KR;
  font-style: normal;
  font-weight: 600;
  src: local(Noto Serif KR SemiBold), local(NotoSerifKR-SemiBold), url("noto-serif-kr-v6-latin-600.ad701502.woff2") format("woff2"), url("noto-serif-kr-v6-latin-600.fc08b642.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Noto Serif KR;
  font-style: normal;
  font-weight: 700;
  src: local(Noto Serif KR Bold), local(NotoSerifKR-Bold), url("noto-serif-kr-v6-latin-700.f98c1a81.woff2") format("woff2"), url("noto-serif-kr-v6-latin-700.a2ebeba0.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Noto Serif KR;
  font-style: normal;
  font-weight: 900;
  src: local(Noto Serif KR Black), local(NotoSerifKR-Black), url("noto-serif-kr-v6-latin-900.ed28d74e.woff2") format("woff2"), url("noto-serif-kr-v6-latin-900.31f07377.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Noto Sans TC;
  font-style: normal;
  font-weight: 100;
  src: local(Noto Sans TC Thin), local(NotoSansTC-Thin), url("noto-sans-tc-v10-latin-100.1a5a2f3b.woff2") format("woff2"), url("noto-sans-tc-v10-latin-100.055669b3.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Noto Sans TC;
  font-style: normal;
  font-weight: 400;
  src: local(Noto Sans TC Regular), local(NotoSansTC-Regular), url("noto-sans-tc-v10-latin-regular.0484dbf9.woff2") format("woff2"), url("noto-sans-tc-v10-latin-regular.fca94f48.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Noto Sans TC;
  font-style: normal;
  font-weight: 500;
  src: local(Noto Sans TC Medium), local(NotoSansTC-Medium), url("noto-sans-tc-v10-latin-500.856e66a9.woff2") format("woff2"), url("noto-sans-tc-v10-latin-500.1c65e3fb.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Noto Sans TC;
  font-style: normal;
  font-weight: 300;
  src: local(Noto Sans TC Light), local(NotoSansTC-Light), url("noto-sans-tc-v10-latin-300.4f5c1efa.woff2") format("woff2"), url("noto-sans-tc-v10-latin-300.ec367bf5.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Noto Sans TC;
  font-style: normal;
  font-weight: 700;
  src: local(Noto Sans TC Bold), local(NotoSansTC-Bold), url("noto-sans-tc-v10-latin-700.663a315d.woff2") format("woff2"), url("noto-sans-tc-v10-latin-700.084c99d9.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Noto Sans TC;
  font-style: normal;
  font-weight: 900;
  src: local(Noto Sans TC Black), local(NotoSansTC-Black), url("noto-sans-tc-v10-latin-900.60c0ef7f.woff2") format("woff2"), url("noto-sans-tc-v10-latin-900.1cc23246.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Suisse Intl;
  font-style: normal;
  font-weight: 400;
  src: local(Suisse Intl), url("SuisseIntl-Book-WebXL.8593fb66.woff2") format("woff2"), url("SuisseIntl-Book-WebXL.0f3168ab.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Suisse Intl;
  font-style: normal;
  font-weight: 500;
  src: local(Suisse Intl), url("SuisseIntl-Medium-WebXL.5b33aa57.woff2") format("woff2"), url("SuisseIntl-Medium-WebXL.d7ad916a.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Suisse Intl;
  font-style: normal;
  font-weight: 800;
  src: local(Suisse Intl), url("SuisseIntl-Black-WebXL.4168ea50.woff2") format("woff2"), url("SuisseIntl-Black-WebXL.b4630b6d.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Suisse Works;
  font-style: normal;
  font-weight: 400;
  src: local(Suisse Works), url("SuisseWorks-Regular-WebXL.ff47f064.woff2") format("woff2"), url("SuisseWorks-Regular-WebXL.c131c1c2.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Suisse Works;
  font-style: normal;
  font-weight: 700;
  src: local(Suisse Intl), url("SuisseWorks-Bold-WebXL.524a44e7.woff2") format("woff2"), url("SuisseWorks-Bold-WebXL.f96db0de.woff") format("woff");
  font-display: swap;
}

*, :after, :before {
  box-sizing: border-box;
}

html {
  font-family: var(--font-family-sans);
  line-height: 1.4;
}

body {
  font-size: var(--text-font-size);
  text-size-adjust: 100%;
  margin: 0;
}

em, address {
  font-style: normal;
  font-weight: var(--font-weight-medium);
}

main > :not(.full-width), .pageheader .pageheader-body, .pagefooter {
  max-width: var(--page-max-width);
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
  margin-left: auto;
  margin-right: auto;
}

h1, .h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  padding-top: var(--h1-padding-y);
  padding-bottom: var(--h1-padding-y);
  margin-top: 0;
  margin-bottom: 0;
}

h2, .h2 {
  font-size: var(--h2-font-size);
  font-weight: var(--font-weight-normal);
  text-transform: uppercase;
}

h3, .h3 {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
  font-family: var(--font-family-sans);
  margin-bottom: var(--h3-margin-bottom);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

a.no-icon:before {
  content: "" !important;
  margin-right: inherit !important;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: underline;
}

a:before, a:after {
  text-decoration: none;
  display: inline-block;
}

p {
  font-family: var(--font-family-serif);
}

b {
  font-weight: bold;
}

figcaption, cite {
  font-family: var(--font-family-sans);
  font-size: var(--figcaption-font-size);
  line-height: var(--figcaption-line-height);
  margin-top: var(--figcaption-spacer);
  font-weight: var(--font-weight-light);
  text-align: center;
}

figure {
  margin: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

select, a, button {
  cursor: pointer;
}

.section-heading {
  position: relative;
}

.uppercase {
  text-transform: uppercase !important;
}

.sans {
  font-family: var(--font-family-sans) !important;
}

.serif {
  font-family: var(--font-family-serif) !important;
}

.narrow, .detail > p:not(.intro), .detail [data-module="textblock"], .detail .product-contact-form {
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
}

.clearfix:after {
  content: "";
  clear: both;
  visibility: hidden;
  height: 0;
  display: block;
}

.hide, [hidden] {
  display: none !important;
}

.jsPageDot {
  position: fixed;
  top: 101vh;
  left: 101vw;
}

.hide-mobile {
  display: none;
}

@media (min-width: 768px) {
  .hide-mobile {
    display: inherit;
  }

  .hide-md, .hide-desktop {
    display: none;
  }

  main > h2, main > h3 {
    padding-right: var(--heading-width) !important;
  }

  .narrow, .detail > p:not(.intro), .detail [data-module="textblock"], .detail .product-contact-form {
    max-width: 768px;
  }
}

@media (min-width: 1200px) {
  .narrow, .detail > p:not(.intro), .detail [data-module="textblock"], .detail .product-contact-form {
    max-width: 900px;
  }
}

@media (min-width: 1921px) {
  .narrow, .detail > p:not(.intro), .detail [data-module="textblock"], .detail .product-contact-form {
    max-width: max(900px, 46vw);
  }
}

.images.row {
  margin-top: calc(var(--spacer-responsive) * 2);
  margin-bottom: calc(var(--spacer-responsive) * 2);
}

.images [class*="col-"] {
  margin-top: calc(var(--spacer-responsive) / 2);
  margin-bottom: calc(var(--spacer-responsive) / 2);
  display: flex;
}

.images.row .image {
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 767px) {
  .images.row {
    flex-wrap: nowrap;
  }

  .images.row [class*="col-"] {
    width: 87.5%;
    flex: 0 0 87.5%;
  }
}

a.link-big {
  line-height: 1.3;
  font-size: var(--font-size-xxm);
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  display: block;
}

a.link-download:before, a.link-external:before {
  text-transform: capitalize;
}

a.link-download:before {
  height: .6em;
  line-height: inherit;
  vertical-align: middle;
  border-right: 2px solid;
  padding-right: .9em;
  position: relative;
  top: -.15em;
  transform: rotate(90deg);
}

fieldset {
  max-width: 630px;
  border: none;
  margin: 0 auto;
  padding: 0;
}

.field, .field-checkbox, fieldset button {
  --field-color: var(--color-fg);
  --field-height: 3.4rem;
  --field-border-width: 3px;
  margin-bottom: var(--spacer-font-based);
  min-height: var(--field-height);
  position: relative;
}

.field-checkbox label {
  word-break: break-all;
}

.field-inline {
  border: var(--field-border-width) solid var(--field-color);
  display: flex;
}

.field label {
  color: var(--field-color);
  align-self: center;
}

.field input, .field select {
  padding-left: var(--spacer-font-based);
}

input, select, textarea, button {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}

.field input, .field select, .field textarea, fieldset button {
  font-family: var(--font-family-sans);
  background: none;
  font-size: 1rem;
}

.field input, .field select, .field textarea {
  border: none;
  flex: 1;
}

.field input::placeholder, .field textarea::placeholder {
  text-indent: var(--spacer-font-based-s);
  color: #0000;
  opacity: 1;
}

.field input:focus::placeholder, .field textarea:focus::placeholder {
  color: var(--color-light-text);
}

input:invalid {
  box-shadow: none;
}

.field select {
  padding-right: 2em;
}

.field select + label {
  pointer-events: none;
  width: 2em;
  padding-left: 2px;
}

.field select + label:before {
  content: "";
  width: 10px;
  height: 14px;
  color: var(--field-color);
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyODAuNzIgNDUwIj4KCTxnPgoJCTxwYXRoIGQ9Ik0xNDAuMzYgMEwwIDE0MC4zNmwzMy4wNSAzMi42IDgyLjg2LTgzLjMxVjM1OS45bC04Mi44Ni04Mi44NkwwIDMwOS42NCAxNDAuMzYgNDUwbDE0MC4zNi0xNDAuMzYtMzMuMDUtMzIuNi04My4zMSA4My4zMVY4OS4ybDgzLjMxIDgzLjc2IDMzLjA1LTMyLjZMMTQwLjM2IDB6Ii8+Cgk8L2c+Cjwvc3ZnPgo=") no-repeat;
  margin-left: -28px;
  display: inline-block;
}

.field select + label:after, .field-checkbox.field-checkbox label:after {
  content: "";
}

.field textarea {
  resize: vertical;
  padding: var(--spacer-font-based);
  min-height: var(--field-height);
  max-height: 50vh;
  display: block;
}

.field-textarea:after {
  --textarea-resizer-offset: var(--field-border-width);
  pointer-events: none;
  content: "​";
  width: .75em;
  height: .75em;
  background: transparent var(--arrow-b64) center center no-repeat;
  background-size: contain;
  display: inline-block;
  position: absolute;
  bottom: 2px;
  right: 0;
  transform: rotate(45deg);
}

.field textarea::-webkit-resizer {
  display: none;
}

.field-textarea label {
  padding-top: calc(var(--spacer-font-based)  + .125em);
  align-self: start;
}

.field-required {
  --field-color: var(--color-fg);
}

.field-required:not(.field-checkbox) label, .field-required select, .field-required-hint, fieldset button {
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
}

.field-required label:after {
  content: "*";
}

.field-required-hint, .form-hint {
  font-family: var(--font-family-sans);
}

.field-required-hint {
  font-style: italic;
}

.form-hint {
  margin: var(--padding) 0;
  line-height: 1.4;
}

fieldset button {
  --field-color: var(--color-fg);
  --field-border-width: 10px;
  --field-height: 5rem;
  width: 100%;
  border: var(--field-border-width) solid var(--field-color);
  font-size: var(--font-size-xxl);
  display: block;
}

.fieldgroup {
  margin: 2rem 0 1rem;
}

.field-checkbox {
  min-height: 3rem;
  margin-bottom: 0;
  line-height: 1.5;
  position: relative;
}

.field-checkbox label {
  cursor: pointer;
}

.field-checkbox input[type="checkbox"] {
  position: absolute;
}

.field-required.field-checkbox span {
  --field-color: var(--color-fg);
  border: var(--field-border-width) solid var(--field-color);
}

.field-checkbox span {
  width: 1.8rem;
  height: 1.8rem;
  vertical-align: bottom;
  border: var(--field-border-width) solid var(--field-color);
  background-color: #0000;
  margin-right: 10px;
  line-height: 1;
  display: inline-block;
}

.field-checkbox input[type="checkbox"]:checked + label span:before {
  content: "×";
  speak: none;
  font-size: 27px;
  font-family: var(--font-family-sans);
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 2px;
  font-style: normal;
  font-weight: 400;
  line-height: .75;
}

#website, input[name="website"] {
  display: none;
}

@media (max-width: 480px) {
  .field label:first-child {
    margin-bottom: var(--spacer-font-based-s);
    display: block;
  }

  .field-inline, .field input {
    --field-height: 2.5rem;
  }

  .field input, .field textarea {
    border: var(--field-border-width) solid var(--field-color);
    width: 100%;
    min-height: var(--field-height);
  }
}

@media (max-width: 800px) {
  .field-group .field {
    width: 100%;
  }

  .field-group .field label {
    width: auto;
  }
}

@media (min-width: 481px) {
  .fieldgroup {
    grid-template-columns: 1fr;
    display: grid;
  }

  .field {
    --field-height: 3.4rem;
    border: var(--field-border-width) solid var(--field-color);
    display: flex;
  }

  .field-inline {
    display: inline-flex;
  }

  .field label {
    padding-left: var(--spacer-font-based);
    width: 9em;
  }

  .field textarea {
    min-height: calc(var(--field-height)  - 2 * var(--field-border-width));
  }

  .field-textarea:after {
    --textarea-resizer-offset: 0;
  }

  fieldset button {
    --field-height: 7.5rem;
    font-size: var(--font-size-l);
  }

  .field-group {
    display: flex;
  }

  .field-group > :not(:first-child) {
    margin-left: var(--spacer-font-based);
  }
}

@media (min-width: 768px) {
  .detail .product-contact-form .field-spacer {
    margin-top: 80px;
  }
}

@media (min-width: 1200px) {
  .fieldgroup {
    grid-template-columns: 50% 50%;
    display: grid;
  }
}

.product-contact-form fieldset {
  max-width: initial;
  margin: initial;
}

.product-contact-form .field-group .field {
  width: 100%;
}

.product-contact-form .field-group .field label {
  width: auto;
}

.product-contact-form .field input, .product-contact-form .field select, .product-contact-form .field textarea {
  width: 100%;
}

.detail .product-contact-form input, .detail .product-contact-form textarea, .detail .product-contact-form select, .detail .product-contact-form label {
  font-size: var(--font-size-s);
}

.detail fieldset button {
  font-size: var(--font-size-xm);
}

.product-contact-form small, .product-contact-form small p {
  font-family: var(--font-family-sans);
}

.detail .product-contact-form small, .detail .product-contact-form small p {
  font-size: var(--font-size-s);
}

.product-contact-form small a {
  text-decoration: underline;
}

.detail .product-contact-form__header {
  margin-top: 80px;
}

.product-contact-form__header {
  flex-flow: row;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
}

.product-contact-form__header a {
  font-size: var(--h2-font-size);
  padding-top: 0;
  line-height: 1.1;
}

.product-contact-form__header img {
  margin: 25px 0 0 25px;
}

.w-100 {
  width: 100%;
}

.form-sec {
  opacity: 0;
  height: 0;
  width: 0;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.arrow-before:before, .arrow-after:after {
  width: .75em;
  background: transparent var(--arrow-b64) center center no-repeat;
  background-size: contain;
  display: inline-block;
  content: "​" !important;
}

.arrow-before.arrow--back:before {
  width: .8em;
  background-image: var(--arrow-b64-back) !important;
}

.arrow-before.invert:before, .arrow-after.invert:after {
  filter: invert();
}

.arrow-before:before {
  margin-right: var(--arrow-offset);
}

.arrow-after:after {
  margin-left: var(--arrow-offset);
}

.arrow--right.arrow-before:before, .arrow--right.arrow-after:after {
  transform: rotate(0deg);
}

.arrow--left.arrow-before:before, .arrow--left.arrow-after:after {
  transform: rotate(180deg);
}

.arrow--top.arrow-before:before, .arrow--top.arrow-after:after {
  transform: rotate(-90deg);
}

.arrow--bottom.arrow-before:before, .arrow--bottom.arrow-after:after {
  transform: rotate(90deg);
}

.appointments {
  --appointment-grid-gap: 30px;
  --appointment-image-width: 100%;
  --appointment-spacer: 20px;
}

.appointments .marquee {
  margin-top: var(--appointment-spacer);
  margin-bottom: var(--appointment-spacer);
}

.appointment-count {
  float: right;
  display: none;
}

.appointment {
  padding-top: var(--appointment-spacer);
  padding-bottom: var(--appointment-spacer);
  border-bottom: 1px solid #000;
  margin-top: -1px;
}

.appointment a:hover, .appointment a:active, .appointment a:focus {
  text-decoration: none;
}

.appointment img {
  width: var(--appointment-image-width);
  height: auto;
}

.appointment h1, .appointment h2 {
  display: inline;
}

.appointment h1 {
  margin: var(--spacer-xxs) 0 0;
  font-size: var(--font-size-ml);
}

.appointment h2 {
  text-transform: none;
  font-family: var(--font-family-serif);
  font-size: var(--font-size-ml);
  margin: 0;
  padding: 0;
}

.appointment-date {
  font-size: var(--font-size-s);
  margin: var(--spacer-xxs) 0;
}

.appointment-date .date-from {
  margin-right: .75rem;
}

.appointment-date .date-to {
  margin-left: .75rem;
}

@media (min-width: 768px) {
  .appointment-count {
    display: block;
  }

  .appointments {
    --appointment-image-width: 216px;
    --appointment-spacer: 15px;
  }

  .appointment a {
    grid: auto auto / var(--appointment-image-width) auto;
    display: grid;
  }

  .appointment-detail-teaser.row {
    flex-direction: row-reverse;
  }

  .appointment-teaser {
    margin-left: var(--appointment-grid-gap);
  }

  .appointment-teaser h1 {
    font-size: var(--font-size-ll);
    margin: 0;
  }

  .appointment-teaser h2 {
    font-size: var(--font-size-ll);
  }

  .appointment-date {
    font-size: var(--font-size-m);
    margin-top: 15px;
  }

  .appointment-location {
    font-size: var(--font-size-m);
    margin-top: 15px;
    margin-left: var(--appointment-grid-gap);
  }
}

@media (min-width: 1200px) {
  .appointments {
    --appointment-grid-gap: 60px;
    --appointment-image-width: 285px;
    --appointment-spacer: 30px;
  }

  .appointment a {
    grid: auto auto / var(--appointment-image-width) auto 1fr;
  }

  .appointment-image {
    grid-row: span 2;
  }

  .appointment-teaser {
    grid-column: span 2;
  }

  .appointment-teaser h1, .appointment-teaser h2 {
    font-size: 39px;
  }

  .appointment-date {
    margin: 15px 30px 0 var(--appointment-grid-gap);
    align-self: end;
    font-size: 18px;
  }

  .appointment-location {
    align-self: end;
    margin: 0;
    font-size: 18px;
  }
}

@media (min-width: 1921px) {
  .appointment-teaser h1, .appointment-teaser h2 {
    font-size: max(39px, 2vw);
  }

  .appointment-date, .appointment-location {
    font-size: max(18px, .9375vw);
  }
}

.date-list {
  --date-font-size: 22px;
  --heading-font-size: 22px;
  --heading-spacer: calc(var(--spacer-xxs) / 2);
  margin-top: var(--spacer-responsive);
  margin-bottom: var(--spacer-responsive);
  line-height: 1.1;
}

.date-list header {
  margin-top: var(--heading-spacer);
}

.date-list header h1, .date-list header h2 {
  font-size: var(--heading-font-size);
  margin: 0;
  display: inline;
}

.date-list header h2 {
  font-family: var(--font-family-serif);
  text-transform: none;
}

.date-list .date {
  font-size: var(--date-font-size);
}

@media (min-width: 768px) {
  .date-list {
    --date-font-size: 16px;
    --heading-font-size: 24px;
    --heading-spacer: 0;
  }
}

@media (min-width: 1200px) {
  .date-list {
    --date-font-size: 36px;
    --heading-font-size: 37px;
    --date-font-size: max(36px, 1.6vw);
    --heading-font-size: max(37px, 1.7vw);
  }
}

.ddoi {
  --min-height: 400px;
  --ddoi-header-font-size: 18px;
  min-height: var(--min-height);
  width: 100%;
  text-align: center;
  display: flex;
}

.ddoi header {
  font-size: var(--ddoi-header-font-size);
}

.ddoi .tile h2 {
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .ddoi {
    --min-height: 450px;
    --ddoi-header-font-size: 24px;
  }

  .ddoi .tile {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .ddoi {
    --min-height: 780px;
    --ddoi-header-font-size: 48px;
  }
}

@media (min-width: 1281px) {
  .ddoi header {
    padding: 0 calc(min(100% - 1320px, 20%));
  }
}

@media (min-width: 1921px) {
  .ddoi {
    --ddoi-header-font-size: max(3rem, 2vw);
  }
}

.designers {
  --designers-margin-top: 80px;
  --designers-margin-bottom: var(--spacer-responsive-l);
}

.designers .intro {
  margin-top: var(--designers-margin-top);
  margin-bottom: var(--designers-margin-bottom);
}

.designer-teaser .image {
  margin-top: 0;
  margin-bottom: 0;
}

.designer-teaser figcaption {
  margin-bottom: 15px;
}

.designer-teaser .tile img {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 767px) {
  .designer-teaser .tile img, .designer-teaser.container, .designer-teaser [class*="col-"], .designer-teaser .col {
    padding-left: 0;
    padding-right: 0;
  }

  .designer-teaser .row {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .designers {
    --designers-margin-top: 86px;
  }
}

@media (min-width: 1200px) {
  .designers {
    --designers-margin-top: 133px;
  }
}

.home .showrooms[data-slider] {
  height: 100%;
  flex-wrap: nowrap;
}

.home .dossiers header {
  --font-cut: -.58em;
}

@media (max-width: 767px) {
  .home .showrooms[data-slider] .col {
    width: 87.5% !important;
    flex: 0 0 87.5% !important;
  }

  .home .dossiers .grid .tile:nth-child(n+2) {
    display: none;
  }

  .home .slider-buttons {
    margin-bottom: 20px;
  }
}

@media (max-width: 1199px) {
  .home .dossiers .grid .tile:nth-child(n+4) {
    display: none;
  }
}

@media (min-width: 768px) {
  .home .dossiers .grid .tile:first-child {
    font-size: var(--h1-font-size);
    grid-area: span 4 / span 6;
  }
}

.marquee {
  --marquee-font-size: 26px;
  --marquee-spacer: 27px;
  font-size: var(--marquee-font-size);
  color: #fff;
  padding-top: var(--marquee-spacer);
  padding-bottom: var(--marquee-spacer);
  white-space: nowrap;
  background: #000;
  line-height: 1;
  animation: marquee 20s linear infinite;
  overflow: hidden;
}

.marquee:hover {
  animation-play-state: paused;
}

.marquee a {
  margin-right: var(--spacer-responsive);
}

.marquee .arrow-before:before {
  width: 31px;
}

@keyframes marquee {
  0% {
    text-indent: 100%;
  }

  100% {
    text-indent: -200%;
  }
}

@media (min-width: 768px) {
  .marquee {
    --marquee-font-size: 42px;
    --marquee-spacer: 36px;
  }
}

@media (min-width: 1200px) {
  .marquee {
    --marquee-font-size: 62px;
    --marquee-spacer: 25px;
  }
}

.newsletter-h1 {
  width: 100%;
}

.newsletter-teaser {
  margin: 1rem var(--padding);
  font-size: var(--font-size-xll);
  font-weight: var(--font-weight-normal);
  text-transform: uppercase;
  font-family: var(--font-family-serif);
}

.newsletter-teaser > span {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-xl);
}

.newsletter-form {
  margin-top: var(--spacer-l);
  display: flex;
}

.newsletter-form fieldset {
  max-width: 100%;
  border: 0;
  margin: 0 auto;
}

.newsletter-form input, .newsletter-form .field, .newsletter-form .field.field-required {
  font-size: var(--font-size-xxl);
  border: 0;
}

.newsletter-form .field {
  max-width: 100%;
  border-top: 1px solid #000;
  margin: 0 auto;
}

.newsletter-form .field:nth-child(3) {
  border-bottom: 1px solid #000;
}

.newsletter-form .field input {
  padding: var(--padding-s) 0;
}

.newsletter-form label {
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-m);
  color: #000;
  padding-left: 0;
}

.newsletter-footer {
  margin-top: var(--spacer-xs);
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.newsletter-footer p.form-hint {
  grid-area: 1 / 1;
  margin: 0 10px 0 0;
}

.newsletter-footer button {
  margin-top: var(--spacer-xs);
  grid-area: 2 / 1;
}

.form-error.is-visible {
  align-self: center;
  font-size: 24px;
}

@media (min-width: 481px) {
  .newsletter-footer p.form-hint {
    grid-area: 1 / 1;
  }

  .newsletter-footer button {
    grid-area: 1 / 2;
    justify-items: start;
    margin-top: 0;
  }
}

.showrooms .image figcaption {
  text-align: left;
  font-weight: var(--font-weight-medium);
  font-size: 18px;
}

.showrooms .image {
  margin: calc(var(--figcaption-spacer) * 2) 0 0 0;
}

.showrooms .image img {
  width: 100%;
  aspect-ratio: 570 / 920;
  object-fit: cover;
}

.showroom-heading .h1.h1 {
  display: block;
}

@supports not (aspect-ratio: 570 / 920) {
  .showrooms .image-inner {
    height: 0;
    padding-bottom: 161.404%;
    position: relative;
    overflow: hidden;
  }

  .showrooms .image img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 1921px) {
  .showrooms .image figcaption {
    font-size: max(18px, .9375vw);
  }
}

.page-404 {
  --min-width: 320px;
  --max-width: 1200px;
  --font-size: 47vw;
  --min-font-size: 72px;
  --max-font-size: 690px;
}

.page-404 p {
  font-size: var(--min-font-size);
  text-align: center;
  font-family: var(--font-family-sans);
  background: url("404.ebaaa49c.svg") center / contain no-repeat;
  margin: 0;
}

@media screen and (min-width: 320px) {
  .page-404 p {
    font-size: var(--font-size);
  }
}

@media screen and (min-width: 1200px) {
  .page-404 p {
    font-size: var(--max-font-size);
  }
}

.window {
  z-index: 10;
  width: 100%;
  height: 100%;
  --padding: .8rem 1.5rem 0 2rem;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.window__inner {
  height: 100vh;
  max-width: 1100px;
  background: #fff;
  padding-top: 40px;
  position: relative;
  overflow-y: auto;
}

.window__body {
  padding: var(--padding);
}

.window__close {
  --close-width: 3rem;
  width: var(--close-width);
  height: var(--close-width);
  position: absolute;
  top: 40px;
  right: 0;
}

.window__close:hover {
  text-decoration: none;
}

.window__close:before {
  content: "×";
  font-family: var(--font-family-serif);
  font-size: var(--font-size-ml);
  width: var(--close-width);
  height: var(--close-width);
  justify-content: center;
  align-items: center;
  display: flex;
}

.window .newsletter-teaser {
  --font-size-xl: var(--font-size-ll);
  --font-size-xll: var(--font-size-ll);
  margin: 0 0 -.6rem;
  padding-right: 10px;
}

.newsletter-overlay iframe {
  height: 80vh;
  min-height: 800px;
}

@media (min-width: 768px) {
  .window {
    --padding: 1rem 3.5rem 0 2rem;
  }

  .newsletter-overlay iframe {
    height: 500px;
  }

  .window__inner {
    height: auto;
    max-height: 100vh;
    padding-top: 0;
    box-shadow: 3px 3px 8px #0000004d;
  }

  .window__close {
    --close-width: 4rem;
    top: 0;
  }

  .newsletter-overlay iframe {
    min-height: 500px;
  }
}

@media (min-width: 1921px) {
  .window__inner {
    max-width: max(1100px, 50vw);
  }
}

[data-toggle-initial="closed"] {
  display: none;
}

[data-toggle]:after {
  content: "​";
  width: .75em;
  background: transparent var(--arrow-b64) center center no-repeat;
  margin-left: var(--arrow-offset);
  background-size: contain;
  transform: rotate(90deg);
}

[data-toggle][data-toggle-state="opened"]:after {
  transform: rotate(-90deg);
}

a[data-toggle]:before {
  content: none;
}

:root {
  --slider-arrow-width: .8em;
}

[data-slider] {
  scroll-snap-type: both mandatory;
  position: relative;
  overflow-x: auto;
}

[data-slider]:not(.-scrolling) > * {
  scroll-snap-align: start;
}

[data-slider].safari > * {
  scroll-snap-align: initial;
}

[data-slider]::-webkit-scrollbar {
  display: none;
}

[data-slider] > a {
  user-drag: none;
}

[data-slider] > a img {
  pointer-events: none;
}

.slider-buttons {
  text-align: center;
  margin-bottom: var(--spacer-xxs);
}

.slider-buttons > a, button[data-slider-next], button[data-slider-previous] {
  font-size: var(--h1-font-size);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  width: var(--slider-arrow-width);
  height: 1em;
  font-family: var(--font-family-sans-light);
  background: none;
  border: none;
  padding: 0;
  overflow: hidden;
}

button[data-slider-next]:before, button[data-slider-previous]:before {
  content: "​";
  width: .75em;
  background: transparent var(--arrow-b64) center center no-repeat;
  background-size: contain;
  display: inline-block;
}

button[data-slider-previous]:before {
  transform: rotate(180deg);
}

button[data-slider-previous][aria-disabled="true"], button[data-slider-next][aria-disabled="true"], button[data-slider-previous][disabled], button[data-slider-next][disabled] {
  opacity: .25;
}

.slider[data-slider] {
  height: 100%;
  flex-wrap: nowrap;
}

@media (max-width: 767px) {
  .more[data-slider] .tile {
    width: 87.5%;
    flex: 0 0 87.5%;
  }

  .row.slider-xs, .row.slider-xs .row {
    height: 100%;
    flex-wrap: nowrap;
  }

  .slider-buttons button + button {
    margin-left: var(--spacer-responsive);
  }
}

@media (min-width: 768px) {
  .slider-buttons {
    position: absolute;
    top: 0;
    right: 30px;
  }

  .section-heading .slider-buttons {
    padding-top: var(--h1-padding-y);
  }

  .slider-buttons button + button {
    margin-left: .175em;
  }
}

@media (min-width: 1200px) {
  [data-slider] .tile.col-lg-6 {
    --font-size: var(--font-size-l);
    --font-cut: var(--tile-header-font-cut-l);
    padding: var(--spacer-l) !important;
  }
}

.pswp {
  width: 100%;
  height: 100%;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 1500;
  -webkit-text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  outline: none;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pswp * {
  box-sizing: border-box;
}

.pswp img {
  max-width: none;
}

.pswp--animate_opacity {
  opacity: .001;
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--open {
  display: block;
}

.pswp--zoom-allowed .pswp__img {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.pswp--zoomed-in .pswp__img {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.pswp--dragging .pswp__img {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.pswp__bg {
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(0);
}

.pswp__scroll-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pswp__container, .pswp__zoom-wrap {
  -ms-touch-action: none;
  touch-action: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.pswp__container, .pswp__img {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.pswp__zoom-wrap {
  width: 100%;
  transform-origin: 0 0;
  -webkit-transition: -webkit-transform .333s cubic-bezier(.4, 0, .22, 1);
  transition: transform .333s cubic-bezier(.4, 0, .22, 1);
  position: absolute;
}

.pswp__bg {
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--animated-in .pswp__bg, .pswp--animated-in .pswp__zoom-wrap {
  transition: none;
}

.pswp__container, .pswp__zoom-wrap {
  -webkit-backface-visibility: hidden;
}

.pswp__item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.pswp__img {
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__img--placeholder {
  -webkit-backface-visibility: hidden;
}

.pswp__img--placeholder--blank {
  background: #222;
}

.pswp--ie .pswp__img {
  top: 0;
  left: 0;
  width: 100% !important;
  height: auto !important;
}

.pswp__error-msg {
  width: 100%;
  text-align: center;
  color: #ccc;
  margin-top: -8px;
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: 0;
}

.pswp__error-msg a {
  color: #ccc;
  text-decoration: underline;
}

.pswp {
  height: auto;
  top: 0;
  bottom: 0;
}

.pswp img.pswp__img {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

@media (min-width: 900px) {
  .pswp {
    top: 0;
    bottom: 0;
  }
}

.pswp--zoom-allowed .pswp__img {
  cursor: default !important;
}

.pswp--animated-in .pswp__container {
  transition: all .32s ease-in-out;
  top: 4px;
}

.pswp--animated-in.pswp--touch .pswp__container {
  transition: none;
}

.pswp__button {
  width: 42px;
  height: 42px;
  cursor: pointer;
  -webkit-appearance: none;
  float: right;
  opacity: .75;
  box-shadow: none;
  background: none;
  border: 0;
  margin: 6px 10px 0 0;
  padding: 0;
  transition: opacity .2s;
  display: block;
  position: relative;
  overflow: visible;
}

.pswp__button:focus, .pswp__button:hover {
  opacity: 1;
}

.pswp__button:active {
  opacity: .9;
  outline: none;
}

.pswp__button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.pswp__button:before {
  speak: none;
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 34px;
  line-height: 1;
  font-family: var(--font-family-serif) !important;
}

.pswp__button--close:before {
  content: "×";
}

.pswp__button--share:before {
  content: "c";
}

.pswp__ui--over-close .pswp__button--close {
  opacity: 1;
}

.pswp__button--fs, .pswp__button--zoom {
  display: none;
}

.pswp--zoom-allowed .pswp__button--zoom:before {
  content: "+";
  font-size: 40px;
}

.pswp__button--arrow--left, .pswp__button--arrow--right {
  width: auto;
  height: auto;
  z-index: 1;
  background: none;
  margin-right: 0;
  padding: 10px;
  position: absolute;
  bottom: 0;
}

.pswp__button--arrow--left {
  left: 0;
}

.pswp__button--arrow--right {
  right: 0;
}

.pswp__button--arrow--left:before, .pswp__button--arrow--right:before {
  font-size: 20px;
}

.pswp__button--arrow--left:before {
  content: "←";
  left: 6px;
}

.pswp__button--arrow--right:before {
  content: "→";
  right: 6px;
}

.pswp__counter, .pswp__share-modal {
  -webkit-user-select: none;
  user-select: none;
}

.pswp__share-modal {
  width: 100%;
  height: 100%;
  z-index: 1600;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  background: #00000080;
  padding: 10px;
  transition: opacity .25s ease-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__share-modal--hidden {
  display: none;
}

.pswp__share-tooltip {
  z-index: 1620;
  width: auto;
  -webkit-backface-visibility: hidden;
  will-change: transform;
  background: #fff;
  border-radius: 2px;
  transition: transform .25s;
  display: block;
  position: absolute;
  top: 56px;
  right: 44px;
  transform: translateY(6px);
  box-shadow: 0 2px 5px #00000040;
}

.pswp__share-tooltip a {
  color: #000;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  display: block;
}

.pswp__share-tooltip a:hover {
  color: #000;
  text-decoration: none;
}

.pswp__share-tooltip a:first-child {
  border-radius: 2px 2px 0 0;
}

.pswp__share-tooltip a:last-child {
  border-radius: 0 0 2px 2px;
}

.pswp__share-modal--fade-in {
  opacity: 1;
}

.pswp__share-modal--fade-in .pswp__share-tooltip {
  transform: translateY(0);
}

.pswp--touch .pswp__share-tooltip a {
  padding: 16px 12px;
}

a.pswp__share--facebook:before {
  content: "";
  width: 0;
  height: 0;
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  pointer-events: none;
  border: 6px solid #0000;
  border-bottom-color: #fff;
  display: block;
  position: absolute;
  top: -12px;
  right: 15px;
}

a.pswp__share--facebook:hover {
  color: #fff;
  background: #3e5c9a;
}

a.pswp__share--facebook:hover:before {
  border-bottom-color: #3e5c9a;
}

a.pswp__share--twitter:hover {
  color: #fff;
  background: #55acee;
}

a.pswp__share--pinterest:hover {
  color: #ce272d;
  background: #ccc;
}

a.pswp__share--download:hover {
  background: #ddd;
}

.pswp__counter {
  width: auto;
  text-align: center;
  height: 42px;
  color: #fff;
  opacity: .75;
  font-size: 13px;
  line-height: 42px;
  font-weight: var(--font-weight-light);
  padding: 0 10px;
  position: absolute;
  top: 4px;
  left: 0;
}

.pswp__caption {
  width: 100%;
  min-height: 44px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pswp__caption .foto {
  font-family: var(--font-family-sans);
  font-size: 12px;
  line-height: 1.35;
  display: block;
}

.pswp__caption small {
  color: #bbb;
  font-size: 11px;
}

.pswp__caption .index {
  display: none;
}

.bs-galerie__credit {
  color: #ccc;
  font-size: 12px;
  line-height: 1.2;
}

.bs-galerie__copyright {
  font-size: 14px;
  line-height: 1.2;
}

.pswp__caption__center {
  max-width: 720px;
  text-align: left;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  padding: 10px 40px;
  font-size: 14px;
  line-height: 21px;
}

.pswp__caption__center p {
  margin: 0;
  padding: 0;
}

.pswp__caption--empty {
  display: none;
}

.pswp__caption--fake {
  visibility: hidden;
}

.pswp__preloader {
  width: 44px;
  height: 44px;
  opacity: 0;
  will-change: opacity;
  direction: ltr;
  margin-left: -22px;
  transition: opacity .25s ease-out;
  position: absolute;
  top: 0;
  left: 50%;
}

.pswp__preloader__icn {
  width: 20px;
  height: 20px;
  margin: 12px;
}

.pswp__preloader--active {
  opacity: 1;
}

.pswp__preloader--active .pswp__preloader__icn {
  background: url("preloader.9ad95bd8.gif") no-repeat;
}

.pswp--css_animation .pswp__preloader--active {
  opacity: 1;
}

.pswp--css_animation .pswp__preloader--active .pswp__preloader__icn {
  animation: clockwise .5s linear infinite;
}

.pswp--css_animation .pswp__preloader--active .pswp__preloader__donut {
  animation: donut-rotate 1s cubic-bezier(.4, 0, .22, 1) infinite;
}

.pswp--css_animation .pswp__preloader__icn {
  opacity: .75;
  width: 14px;
  height: 14px;
  background: none;
  margin: 0;
  position: absolute;
  top: 15px;
  left: 15px;
}

.pswp--css_animation .pswp__preloader__cut {
  width: 7px;
  height: 14px;
  position: relative;
  overflow: hidden;
}

.pswp--css_animation .pswp__preloader__donut {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background: none;
  border: 2px solid #fff;
  border-color: #fff #fff #0000 #0000;
  border-radius: 50%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .pswp__preloader {
    float: right;
    margin: 0;
    position: relative;
    top: auto;
    left: auto;
  }
}

@keyframes clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes donut-rotate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-140deg);
  }

  100% {
    transform: rotate(0);
  }
}

.pswp__ui {
  -webkit-font-smoothing: auto;
  visibility: visible;
  opacity: 1;
  z-index: 1550;
}

.pswp__top-bar {
  height: auto;
  width: 100%;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__caption, .pswp__top-bar, .pswp--has_mouse .pswp__button--arrow--left, .pswp--has_mouse .pswp__button--arrow--right {
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  transition: opacity .333s cubic-bezier(.4, 0, .22, 1);
}

.pswp--has_mouse .pswp__button--arrow--left, .pswp--has_mouse .pswp__button--arrow--right {
  visibility: visible;
}

.pswp__top-bar, .pswp__caption {
  background-color: #00000080;
}

.pswp__ui--fit .pswp__top-bar, .pswp__ui--fit .pswp__caption {
  background-color: #0000004d;
}

.pswp__ui--hidden .pswp__top-bar, .pswp__ui--hidden .pswp__caption, .pswp__ui--hidden .pswp__button--arrow--left, .pswp__ui--hidden .pswp__button--arrow--right {
  opacity: .001;
}

.pswp__ui--one-slide .pswp__button--arrow--left, .pswp__ui--one-slide .pswp__button--arrow--right, .pswp__ui--one-slide .pswp__counter {
  display: none;
}

.pswp__element--disabled {
  display: none !important;
}

.pswp--minimal--dark .pswp__top-bar {
  background: none;
}

.article-headline-pswp {
  display: none;
}

@media (min-width: 1200px) {
  .pswp--animated-in .pswp__container {
    top: 41px;
  }

  .pswp__button {
    width: 64px;
    height: 64px;
    margin: 10px 10px 0 0;
  }

  .pswp__button:before {
    font-size: 60px;
  }

  .pswp--supports-fs .pswp__button--fs {
    background: url("fullscreen.329c7bda.svg") 50% 60% / 56% no-repeat;
    display: block;
  }

  .pswp__button--arrow--left, .pswp__button--arrow--right {
    width: 100px;
    height: 100%;
    z-index: 2;
    background: none;
    margin-top: -50px;
    margin-right: 0;
    top: 0;
  }

  .pswp__button--arrow--left {
    z-index: 4;
  }

  .pswp__button--arrow--left:before, .pswp__button--arrow--right:before {
    text-shadow: 0 0 17px #00000080;
  }

  .pswp__caption__center {
    max-width: 1200px;
    margin: 0;
    padding: 0 140px 10px;
  }

  .article-headline-pswp {
    width: 100%;
    pointer-events: none;
    text-align: left;
    color: #fff;
    margin: 0;
    padding: 22px 140px 0;
    display: block;
    position: absolute;
    top: 0;
  }

  .article-headline-pswp .title {
    font-family: var(--font-family-sans);
    width: 47%;
    font-size: 20px;
    line-height: 1;
    display: block;
  }

  .article-headline-pswp .subtitle {
    font-family: var(--font-family-serif);
    width: 47%;
    font-size: 16px;
    line-height: 1.35;
    display: inline-block;
  }

  .pswp__counter {
    text-align: left;
    height: auto;
    padding: 0;
    line-height: 1;
    top: 49px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 1520px) {
  .pswp__caption__center {
    max-width: 1600px;
  }
}

@media (min-width: 1921px) {
  .pswp__caption__center {
    font-size: max(14px, .675vw);
    line-height: 1.5;
  }

  .pswp__caption .foto {
    font-size: max(12px, .625vw);
    line-height: 1.5;
  }

  .pswp__counter {
    font-size: max(13px, .55vw);
  }

  .article-headline-pswp .title {
    font-size: max(20px, .95vw);
  }

  .article-headline-pswp .subtitle {
    font-size: max(16px, .9vw);
  }
}

.author {
  font-family: var(--font-family-sans);
  font-size: calc(var(--text-font-size)  - 2px);
  margin-top: var(--spacer-l);
  margin-bottom: var(--spacer-s);
}

.filter-category ul {
  flex-wrap: wrap;
  padding: 0;
  font-size: 20px;
  line-height: 30px;
  list-style: none;
  display: flex;
}

.filter-category li {
  white-space: nowrap;
  margin-right: 15px;
}

.filter-category.selected li.active {
  text-decoration: underline;
}

.news {
  --image-spacer-bottom: 40px;
}

.news article > a {
  text-decoration: none;
}

.news .image {
  margin-bottom: var(--image-spacer-bottom);
}

.news .image:nth-child(1) {
  margin-top: 0;
}

.news .image img {
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 768px) {
  .news {
    --image-spacer-bottom: 30px;
    margin-left: calc(var(--col-gap) / -2);
    margin-right: calc(var(--col-gap) / -2);
  }

  .news article {
    width: 50%;
    float: left;
    padding-right: calc(var(--col-gap) / 2);
    padding-left: calc(var(--col-gap) / 2);
  }

  .news .image:nth-child(1), .news .image:nth-child(2) {
    margin-top: 0;
  }

  .news article:nth-child(2n) {
    float: right;
  }
}

@media (min-width: 1200px) {
  .news {
    --image-spacer-bottom: 60px;
  }
}

.heading {
  justify-content: space-between;
  display: flex;
}

.heading .arrows {
  line-height: 1;
}

.heading .arrows, .heading aside {
  font-size: var(--h1-font-size);
  margin: auto 0 auto auto;
}

.heading a:active, .heading a:hover, .heading a:focus {
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
}

.arrow-before + .arrow-before {
  margin-left: .7em;
}

.heading .arrow-before:before {
  margin-right: 0;
}

a:not([href]).arrow-before:before, a:not([href]).arrow-after:after {
  background-image: var(--arrow-b64-white);
}

.heading .arrow-after, .heading .arrow--back {
  margin-right: 8px;
}

@media (min-width: 765px) {
  .arrow-before + .arrow-before {
    margin-left: .5em;
  }
}

@media (min-width: 1200px) {
  .arrow-before + .arrow-before {
    margin-left: .175em;
  }
}

.inline-slider .next, .inline-slider .previous {
  display: flex;
}

.inline-slider [data-slider-previous], .inline-slider [data-slider-next] {
  margin-top: auto;
  margin-bottom: auto;
}

.inline-slider.row [data-slider] {
  height: 100%;
  flex-wrap: nowrap;
}

.inline-slider figure {
  width: 100%;
  flex: 0 0 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.inline-slider .row {
  flex-wrap: nowrap;
}

@media (max-width: 767px) {
  .inline-slider .slider {
    margin-top: var(--spacer-xxs);
  }

  .inline-slider .image {
    padding: 0 !important;
  }

  .inline-slider .next, .inline-slider .previous {
    padding: 0 .3em !important;
  }

  .inline-slider [data-slider-previous] {
    margin-left: auto;
  }

  .inline-slider .slider {
    padding-left: var(--col-gap);
    padding-right: var(--col-gap);
    flex-basis: 100%;
  }
}

@media (min-width: 768px) {
  .inline-slider .next {
    order: 9999;
  }

  .inline-slider [data-slider-previous] {
    margin-right: auto;
  }

  .inline-slider [data-slider-next] {
    margin-left: auto;
  }
}

.pageheader {
  --page-header-logo-height: 23px;
  --partner-max-width: 100px;
  --partner-font-size: 12px;
  --page-header-height: 3.5rem;
  --page-header-padding: 0;
  --page-header-icon-height: 22px;
  --page-header-text-menu-spacer: 2.5vw;
  --page-partner-height: 60px;
  background-color: var(--color-bg);
  z-index: 10;
  line-height: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.pageheader.sticky .pageheader-partner > div:last-child {
  display: none;
}

.pageheader .pageheader-content {
  height: var(--page-header-height);
  padding-bottom: var(--page-header-padding);
  align-items: center;
  display: flex;
}

.pageheader h1 a {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  color: inherit;
}

.pageheader h1 a:before {
  content: none;
}

.pageheader-logo {
  display: flex;
}

.pageheader-logo > a {
  margin-top: auto;
}

.pageheader-logo img {
  width: auto;
  height: var(--page-header-logo-height);
  vertical-align: bottom;
}

.pageheader-logo .long {
  display: none;
}

.pageheader-logo .short {
  display: inline-block;
}

.pageheader .toggle-icons [data-toggle="nav.navigation"], .pageheader .toggle-icons [data-toggle=".search"] {
  margin-left: 25px;
}

.pageheader .toggle-icons [data-toggle-state="opened"] .closed, .pageheader .toggle-icons :not([data-toggle-state="opened"]) .open {
  display: none;
}

.pageheader-toggles {
  font-size: var(--font-size-xmm);
  flex: 1;
  margin-left: 1em;
}

.pageheader-toggles.toggle-text {
  white-space: nowrap;
  flex: 0;
  display: none;
}

.pageheader-toggles.toggle-text a {
  text-transform: uppercase;
  margin-right: var(--spacer-font-based-s);
}

.pageheader-toggles.toggle-icons {
  flex: 0;
  margin-left: auto;
  display: flex;
}

.pageheader-toggles.toggle-icons [data-toggle]:after {
  content: none;
}

.pageheader-toggles.toggle-icons img {
  height: var(--page-header-icon-height);
}

.pageheader-partner {
  height: var(--page-partner-height);
  justify-content: center;
  align-items: flex-end;
  display: flex;
  position: relative;
  overflow: hidden;
}

.pageheader-partner ul, .pageheader-partner .caption {
  margin: 0;
}

.pageheader .partner-list-item {
  padding: 0;
}

.pageheader-partner div + div {
  margin-left: 65px;
}

.pageheader.sticky .pageheader-partner > div {
  position: relative;
}

.pageheader-partner .caption {
  color: #666;
  text-transform: uppercase;
  font-size: 10px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 768px) {
  .pageheader {
    --page-header-logo-height: 58px;
    --page-header-padding: 36px;
    --page-header-height: var(--spacer-responsive-l);
  }

  .pageheader ~ main {
    margin-top: 0;
  }

  .pageheader .pageheader-content {
    align-items: flex-end;
  }

  .pageheader.sticky {
    --page-header-logo-height: 23px;
    --page-header-padding: 18px;
    top: -55px;
  }

  .pageheader.sticky .pageheader-partner {
    top: 10px;
  }

  .pageheader.sticky .pageheader-partner .caption {
    left: -60px;
    top: initial;
    bottom: 8px;
  }

  .pageheader-logo {
    position: relative;
    bottom: 2px;
  }

  .pageheader-toggles.toggle-text {
    display: inline;
  }

  .pageheader-toggles.toggle-icons {
    display: none;
  }

  .pageheader-toggles {
    margin-left: var(--page-header-text-menu-spacer);
    display: block;
  }

  .pageheader-partner {
    height: var(--page-header-logo-height);
    justify-content: flex-end;
  }

  .pageheader .partner-list {
    max-width: 110px;
    margin: auto 0;
    padding-left: 0;
  }

  .pageheader-partner .caption {
    font-size: var(--partner-font-size);
    font-weight: var(--font-weight-medium);
    top: 7px;
    left: initial;
    transform: initial;
    position: absolute;
  }

  .pageheader-partner img {
    vertical-align: bottom;
    max-width: var(--partner-max-width);
    height: auto;
  }

  .pageheader-partner + .pageheader-partner {
    margin-left: var(--spacer-m);
  }
}

@media (min-width: 1200px) {
  .pageheader {
    --page-header-logo-height: 58px;
    --page-header-padding: 40px;
    --page-header-text-menu-spacer: 1.1vw;
  }

  .pageheader.sticky {
    --page-header-logo-height: 35px;
    --page-header-padding: 23px;
    top: -51px;
  }

  .pageheader.sticky .pageheader-logo {
    bottom: -6px;
  }

  .pageheader.sticky .pageheader-partner {
    top: 8px;
  }

  .pageheader .short {
    display: none;
  }

  .pageheader .long {
    display: inline-block;
  }

  .pageheader-logo {
    position: relative;
    bottom: -11px;
  }
}

@media (min-width: 1440px) {
  .pageheader {
    --page-header-logo-height: 65px;
  }
}

@media (min-width: 2300px) {
  .pageheader {
    --page-header-height: calc(2.5vw + 120px - 58px);
    --page-header-logo-height: calc(2.5vw + 65px - 58px);
  }

  .pageheader-logo {
    bottom: -16%;
  }

  .pageheader.sticky {
    --page-header-height: max(2.5vw, 70px);
    --page-header-logo-height: calc(.5 * max(2.5vw, 70px) );
    top: -1px;
  }

  .pageheader.sticky .pageheader-content {
    align-items: center;
    padding-bottom: 0;
  }

  .pageheader.sticky .pageheader-logo {
    align-self: center;
    bottom: 0;
  }
}

@media (min-width: 1921px) {
  .pageheader {
    --partner-font-size: max(12px, .4vw);
  }
}

.cnw_translationbox {
  display: none !important;
}

.pagefooter {
  --margin: var(--spacer-xxs);
  --footer-font-size: 18px;
  text-transform: uppercase;
  line-height: 1;
  font-size: var(--footer-font-size);
  margin-top: var(--margin);
  margin-bottom: var(--margin);
}

.pagefooter__content {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.pagefooter .partner-list {
  margin: var(--spacer-responsive) auto;
}

.sociallinks {
  margin-top: 25px;
}

.sociallinks img {
  max-height: 25px;
  width: auto;
  margin-right: 16px;
}

.legal-notice-links {
  margin-top: 25px;
}

.pagefooter .partner-list-item img {
  height: auto;
}

@media (min-width: 768px) {
  .pagefooter {
    --margin: var(--spacer-xs);
  }
}

@media (min-width: 1200px) {
  .pagefooter {
    --margin: var(--spacer-l);
    --footer-font-size: max(18px, .8vw);
  }

  .sociallinks img {
    max-height: initial;
    height: max(25px, 1.15vw);
    margin-right: max(16px, .9vw);
  }
}

:root {
  --navigation-font-size: 24px;
  --subnavigation-font-size: 14px;
  --navigation-line-height: 2;
  --navigation-li-height: calc(var(--navigation-line-height) * var(--navigation-font-size));
}

.heading-nav {
  padding: 0 24px;
}

.heading .arrows, .heading-nav .heading .arrows {
  font-size: 2rem;
}

.heading-footer .arrows {
  margin-right: 24px;
  font-size: 2rem;
}

.navigation ul {
  line-height: 2;
  font-weight: var(--font-weight-medium);
  font-size: var(--navigation-font-size);
  height: 250px;
  flex-flow: column wrap;
  margin: 0;
  list-style: none;
  display: flex;
}

.navigation a.meta {
  font-size: var(--subnavigation-font-size);
}

.navigation li {
  height: var(--navigation-li-height);
  margin: 0 var(--spacer-font-based-s) 0 0;
  display: inline-block;
}

.navigation .active {
  text-decoration: underline;
}

.navigation a {
  white-space: nowrap;
  font-size: var(--font-size-xm);
}

.navigation a.meta {
  font-size: var(--font-size-s);
}

.heading-nav .filter-dropdowns {
  padding-left: 0;
}

.heading-nav .filter-dropdowns .filter-dropdown {
  margin-left: -8px;
}

.heading-nav > .arrows {
  width: 90px;
}

@media (max-width: 767px) {
  .heading-nav .filter-dropdowns {
    padding-left: 0;
  }

  .navigation ul {
    flex: 1 0 50%;
  }
}

@media (min-width: 768px) {
  .navigation {
    display: block !important;
  }

  main.home > h1, main.detail > .heading, .heading .h1, .heading h1 {
    display: none;
  }

  .navigation ul {
    height: auto;
    flex-direction: row;
    padding: 12px 0 2px 24px;
  }

  .heading-nav {
    flex-flow: row;
    align-items: center;
    padding: 0 24px 0 0;
    display: flex;
  }

  .heading-nav .heading {
    width: 110px;
    order: 2;
    align-self: start;
  }

  .heading-nav .filter-container {
    width: 100%;
    z-index: 2;
    order: 1;
    transform: translateX(9px);
  }

  .heading-nav > .arrows {
    width: auto;
    order: 2;
    justify-self: end;
    margin-bottom: 15px;
    font-size: 2rem;
  }

  .heading-nav > .arrows .arrow--right {
    margin-left: 0;
  }

  .heading-nav .filter-dropdown {
    --dropdown-spacer: 15px;
  }

  .heading-nav .heading .arrows {
    margin-bottom: 15px;
  }

  .heading-nav .product-filter-list {
    width: 100%;
    padding-left: 24px;
  }

  .heading-nav .product-filter-list ul {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .heading-nav .filter-dropdowns .filter-dropdown {
    margin-left: 0;
  }

  .product-filter-nav {
    margin-top: -7px;
  }
}

@media (min-width: 1200px) {
  .heading-nav .filter-dropdowns .filter-dropdown {
    margin-left: inherit;
  }

  .filter-dropdowns.filter-dropdowns {
    padding-left: 0;
    margin-left: 0 !important;
  }
}

@media (min-width: 1921px) {
  .navigation ul {
    display: block;
  }
}

:root {
  --search-spacer: 20px;
  --search-font-size: var(--h1-font-size);
}

.search label {
  font-size: var(--search-font-size);
}

.search input {
  margin-top: var(--search-spacer);
  margin-bottom: var(--search-spacer);
  border: 0;
  border-left: 2px solid var(--color-border);
  width: 100%;
  font-size: var(--search-font-size);
  height: calc(var(--search-font-size)  + 12px);
  padding: 7px;
}

.search input::placeholder {
  color: var(--color-light-text);
  text-transform: uppercase;
  overflow: visible;
}

@media (min-width: 768px) {
  .search label {
    display: none;
  }
}

:root {
  --tile-header-font-cut-xl: -.48em;
  --tile-header-font-cut-l: -.48em;
  --tile-header-font-cut-m: -.48em;
  --tile-header-line-height: 1.1;
  --tile-spacer: 20px;
  --tile-header-font-size: 39px;
  --tile-header-max-width: calc(var(--page-content-max-width));
}

.tile {
  --font-size: var(--font-size-m);
  --font-cut: var(--tile-header-font-cut-m);
  grid-column: span var(--grid-col-span-m);
  grid-row: span var(--grid-row-span-m);
  padding: var(--tile-spacer);
  font-size: var(--font-size);
  width: 100%;
  flex-direction: column;
  display: flex;
}

.tile.tile-s {
  --font-size: var(--font-size-m);
  --font-cut: var(--tile-header-font-cut-m);
  grid-column: span var(--grid-col-span-s);
  grid-row: span var(--grid-row-span-s);
}

.tile.tile-m {
  --font-size: var(--font-size-m);
  --font-cut: var(--tile-header-font-cut-m);
  grid-column: span var(--grid-col-span-m);
  grid-row: span var(--grid-row-span-m);
}

.tile.tile-l {
  --font-size: var(--font-size-l);
  --font-cut: var(--tile-header-font-cut-l);
  grid-column: span var(--grid-col-span-l);
  grid-row: span var(--grid-row-span-l);
}

.tile.tile-xl {
  --font-size: var(--font-size-xl);
  --font-cut: var(--tile-header-font-cut-xl);
  grid-column: span var(--grid-col-span-xl);
  grid-row: span var(--grid-row-span-xl);
  padding: var(--spacer-responsive);
}

.tile .tile-inner {
  max-width: var(--tile-header-max-width);
  margin-left: auto;
  margin-right: auto;
}

.tile .tile-inner.-full {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
}

.tile header {
  z-index: 1;
  margin-bottom: var(--font-cut);
  line-height: var(--tile-header-line-height);
  margin-right: auto;
  position: relative;
}

.tile h1, .tile h2 {
  font-size: inherit;
  font-weight: var(--font-weight-medium);
  margin: 0;
  padding: 0;
  display: inline;
}

.tile h1, .tile .h1 {
  font-family: var(--font-family-sans);
  text-transform: uppercase;
}

.tile h2, .tile .h2 {
  font-family: var(--font-family-serif);
  text-transform: none;
}

.tile figure {
  margin: 0 auto;
}

.tile > a {
  display: inherit;
  flex-direction: inherit;
}

.tile > a:hover, .tile > a:active, .tile > a:focus {
  text-decoration: none;
}

.tile .copyright {
  margin: var(--padding) 0 0;
}

.tiles-bold .tile h1 {
  font-weight: var(--font-weight-black);
}

.tiles-large .tile {
  --font-size: var(--font-size-xxl);
}

.tile.teaser {
  --teaser-font-size: 16px;
  --teaser-line-height: 1.17;
  font-size: var(--teaser-font-size);
  line-height: var(--teaser-line-height);
}

.tile.teaser .tile-inner {
  width: 100%;
  word-break: break-word;
}

.tile.teaser header h1 {
  text-transform: none;
}

.tile .tag {
  font-size: var(--text-font-size);
  font-family: var(--font-family-sans);
  font-weight: var(--font-weight-medium);
  text-align: center;
  text-transform: uppercase;
}

.tile p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  :root {
    --tile-spacer: 30px;
  }

  .tiles-large .tile, .tiles-large .tile-m {
    --font-size: var(--font-size-l);
    --font-cut: var(--tile-header-font-cut-l);
  }

  .tiles-large .tile-xl, .tiles-large .tile-l {
    --font-size: var(--font-size-xxl);
    --font-cut: var(--tile-header-font-cut-l);
  }
}

@media (min-width: 1200px) {
  .tile.teaser {
    --teaser-font-size: 24px;
    --teaser-line-height: 1.19;
  }

  .tile.teaser header h1 {
    font-size: 24px;
    line-height: 1.2;
  }

  .tile.header-big-lg {
    padding: var(--spacer-xs);
    font-size: var(--tile-header-font-size) !important;
  }
}

@media (min-width: 1281px) {
  .tile.header-big-lg {
    padding: max(30px, 1.5vw) max(30px, 2.5vw) !important;
  }

  .tile.header-big-lg header {
    max-width: max(580px, 24vw);
  }
}

@media (min-width: 1921px) {
  .tile.teaser {
    --teaser-font-size: max(24px, 1.25vw);
  }

  .tile.header-big-lg {
    --tile-header-font-size: max(2.4375rem, 1.625vw);
  }
}

.intro {
  --margin-top: 40px;
  --margin-bottom: 40px;
  --font-size: 19px;
  --line-height: 1.3;
  font-family: var(--font-family-serif);
  font-size: var(--font-size);
  font-weight: var(--font-weight-bold);
  margin-top: var(--margin-top);
  margin-bottom: var(--margin-bottom);
  line-height: var(--line-height);
}

.intro p {
  margin: 0;
}

@media (min-width: 768px) {
  .intro {
    --margin-top: 30px;
    --margin-bottom: 30px;
    --font-size: 26px;
  }
}

@media (min-width: 1200px) {
  .intro {
    --margin-top: 60px;
    --margin-bottom: 20px;
    --font-size: 40px;
    --line-height: 1.2;
    --font-weight-bold: 500;
  }
}

@media (min-width: 1921px) {
  .intro {
    --font-size: max(40px, 2.083vw);
  }
}

.text, [data-module="textblock"] {
  font-family: var(--font-family-serif);
  font-weight: 300;
  line-height: 1.4;
}

.text a, [data-module="textblock"] a {
  text-decoration: underline;
}

[data-module="textblock"] img {
  max-width: 100%;
}

.image {
  --image-spacer: var(--spacer-m);
  margin: var(--image-spacer) auto;
}

.image.image-teaser {
  margin-top: 0;
}

.image.teaser-logo {
  --image-spacer: var(--spacer-responsive-l);
  margin-top: calc(var(--image-spacer) / 2);
  margin-bottom: calc(var(--image-spacer) / 2);
}

.image img, .image .gallery-icon, .image .gallery-counter {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
}

.image.gallery img {
  margin: auto;
  display: block;
}

.image.gallery .gallery-wrapper {
  display: flex;
}

.image.gallery .gallery-inner {
  cursor: pointer;
  margin: 0 auto;
  position: relative;
}

.image.gallery .gallery-inner:before {
  content: "";
  will-change: background;
  background: none;
}

.image.gallery .gallery-inner:hover:before {
  content: "";
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: #00000080;
  transition: background .3s ease-in;
  position: absolute;
  top: 0;
  left: 0;
}

.image.gallery .gallery-inner:hover:after {
  content: "BILDERGALERIE ANSEHEN";
  z-index: 2;
  color: var(--color-bg);
  border: 1px solid var(--color-bg);
  pointer-events: none;
  font-family: var(--font-family-sans);
  font-size: var(--font-size-xs);
  text-align: center;
  padding: 4px 7px 4px 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.image-inner {
  display: grid;
}

.image-inner canvas, .image-inner svg {
  pointer-events: none;
  visibility: hidden;
  max-width: 100%;
  grid-area: 1 / 1;
  margin: 0 auto;
  display: flex;
}

.image-inner img {
  grid-area: 1 / 1;
}

.grid-layout-1 > :first-child .image img, .grid-layout-1 > :first-child .image canvas, .grid-layout-1 > :first-child .image svg, .grid-layout-4 > :first-child .image img, .grid-layout-4 > :first-child .image canvas, .grid-layout-4 > :first-child .image svg, .detail .tile .image img, .detail .tile .image canvas, .detail .tile .image svg, .ddoi .tile .image img, .ddoi .tile .image canvas, .accordion .image img, .accordion .image canvas, .accordion .image svg {
  max-height: calc(75vh - 56px);
}

@media (max-width: 768px) and (orientation: portrait) {
  .image-inner img, .image-inner canvas {
    object-fit: contain;
    width: 100%;
  }

  .grid-layout-1 .tile:not(.tile-xl):not(:first-child) .image-inner img, .grid-layout-1 .tile:not(.tile-xl):not(:first-child) .image-inner canvas, .grid-layout-4 .tile:not(.tile-xl):not(:first-child) .image-inner img, .grid-layout-4 .tile:not(.tile-xl):not(:first-child) .image-inner canvas, .grid-layout-2 .tile:not(.tile-xl) .image-inner img, .grid-layout-2 .tile:not(.tile-xl) .image-inner canvas, .grid-scroll .image-inner img, .grid-scroll .image-inner canvas, .grid-layout-1 > :nth-child(8n+4) .tile-inner .image .image-inner canvas, .grid-layout-1 > :nth-child(8n+4) .tile-inner .image .image-inner img, .grid-layout-1 > :nth-child(8n+5) .tile-inner .image .image-inner canvas, .grid-layout-1 > :nth-child(8n+5) .tile-inner .image .image-inner img, .grid-layout-4 > :nth-child(4) .tile-inner .image .image-inner canvas, .grid-layout-4 > :nth-child(4) .tile-inner .image .image-inner img, .grid-layout-4 > :nth-child(5) .tile-inner .image .image-inner canvas, .grid-layout-4 > :nth-child(5) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(7) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(7) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(19n+8) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+8) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(19n+12) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+12) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(19n+18) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+18) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(28) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(28) .tile-inner .image .image-inner img, .grid-layout-3 > :nth-child(5n+1) .tile-inner .image .image-inner canvas, .grid-layout-3 > :nth-child(5n+1) .tile-inner .image .image-inner img, .tile.header-big-lg .image-inner canvas, .tile.header-big-lg .image-inner img {
    max-height: calc(70vh - 56px);
  }
}

@media (max-width: 768px) and (orientation: landscape) {
  .image-inner img, .image-inner canvas {
    object-fit: contain;
    width: 100%;
    max-height: calc(75vh - 56px);
  }

  .showrooms .image-inner img, .showrooms .image-inner canvas {
    object-fit: inherit;
    width: inherit;
    max-height: inherit;
  }

  .grid-layout-1 .tile:not(.tile-xl):not(:first-child) .image-inner img, .grid-layout-1 .tile:not(.tile-xl):not(:first-child) .image-inner canvas, .grid-layout-4 .tile:not(.tile-xl):not(:first-child) .image-inner img, .grid-layout-4 .tile:not(.tile-xl):not(:first-child) .image-inner canvas, .grid-layout-2 .tile:not(.tile-xl) .image-inner img, .grid-layout-2 .tile:not(.tile-xl) .image-inner canvas, .grid-scroll .image-inner img, .grid-scroll .image-inner canvas, .grid-layout-1 > :nth-child(8n+4) .tile-inner .image .image-inner canvas, .grid-layout-1 > :nth-child(8n+4) .tile-inner .image .image-inner img, .grid-layout-1 > :nth-child(8n+5) .tile-inner .image .image-inner canvas, .grid-layout-1 > :nth-child(8n+5) .tile-inner .image .image-inner img, .grid-layout-4 > :nth-child(4) .tile-inner .image .image-inner canvas, .grid-layout-4 > :nth-child(4) .tile-inner .image .image-inner img, .grid-layout-4 > :nth-child(5) .tile-inner .image .image-inner canvas, .grid-layout-4 > :nth-child(5) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(7) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(7) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(19n+8) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+8) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(19n+12) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+12) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(19n+18) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(19n+18) .tile-inner .image .image-inner img, .grid-layout-2 > :nth-child(28) .tile-inner .image .image-inner canvas, .grid-layout-2 > :nth-child(28) .tile-inner .image .image-inner img, .grid-layout-3 > :nth-child(5n+1) .tile-inner .image .image-inner canvas, .grid-layout-3 > :nth-child(5n+1) .tile-inner .image .image-inner img, .tile.header-big-lg .image-inner canvas, .tile.header-big-lg .image-inner img {
    max-height: calc(75vh - 56px);
  }
}

.image.inverse-caption {
  flex-direction: column-reverse;
  display: flex;
}

.image.inverse-caption figcaption {
  margin-top: 0;
  margin-bottom: var(--figcaption-spacer);
}

.tile .image.gallery {
  padding: 0 var(--spacer-font-based);
}

.image .gallery-icon, .image .gallery-counter {
  display: none;
}

.image.gallery .gallery-counter {
  z-index: 1;
  color: #fff;
  line-height: 0;
  font-family: var(--font-family-sans);
  font-size: var(--font-size-s);
  text-align: right;
  pointer-events: none;
  display: block;
  position: absolute;
  top: 30px;
  right: 14px;
}

.image.gallery .gallery-icon {
  z-index: 1;
  pointer-events: none;
  width: 28px;
  height: 28px;
  background: url("arrow-lightbox-white.329c7bda.svg") 100% 0 no-repeat;
  display: block;
  position: absolute;
  bottom: 14px;
  right: 14px;
}

@media (min-width: 768px) {
  .grid-layout-1 > :first-child .image img, .grid-layout-1 > :first-child .image canvas, .grid-layout-1 > :first-child .image svg, .grid-layout-4 > :first-child .image img, .grid-layout-4 > :first-child .image canvas, .grid-layout-4 > :first-child .image svg, .detail .tile .image img, .detail .tile .image canvas, .detail .tile .image svg, .ddoi .tile .image img, .ddoi .tile .image canvas, .ddoi .tile .image svg {
    max-height: 645px;
  }

  .accordion .image img, .accordion .image canvas, .accordion .image svg {
    max-height: initial;
  }

  .image.gallery .gallery-icon {
    width: 32px;
    height: 30px;
  }
}

@media (min-width: 1200px) {
  .image.gallery .gallery-counter {
    font-size: var(--font-size-m);
  }

  .image.gallery .gallery-icon {
    width: 38px;
    height: 36px;
  }
}

@media (min-width: 1921px) {
  .grid-layout-1 > :first-child .image img, .grid-layout-1 > :first-child .image canvas, .grid-layout-1 > :first-child .image svg, .grid-layout-4 > :first-child .image img, .grid-layout-4 > :first-child .image canvas, .grid-layout-4 > :first-child .image svg, .detail .tile .image img, .detail .tile .image canvas, .detail .tile .image svg, .ddoi .tile .image img, .ddoi .tile .image canvas {
    max-height: max(645px, 30vw);
  }
}

blockquote {
  --bq-font-size: 22px;
  --bq-line-height: 26px;
  --bq-spacer: 80px;
  font-size: var(--bq-font-size);
  line-height: var(--bq-line-height);
  font-family: var(--font-family-serif);
  text-align: center;
  margin: var(--bq-spacer) auto;
}

.quote:before {
  content: "„";
}

.quote:after {
  content: "“";
}

cite {
  --cite-font-size: 14px;
  --cite-line-height: 18px;
  --cite-spacer: 10px;
  margin-top: var(--cite-spacer);
  width: 100%;
  font-style: normal;
  font-family: var(--font-family-sans);
  font-size: var(--cite-font-size);
  display: block;
}

@media (min-width: 768px) {
  blockquote {
    --bq-font-size: 26px;
    --bq-line-height: 30px;
    --bq-spacer: 60px;
    max-width: 66.6667%;
  }

  cite {
    --cite-spacer: 15px;
  }
}

@media (min-width: 1200px) {
  blockquote {
    --bq-font-size: 39px;
    --bq-line-height: 42px;
    --bq-spacer: 120px;
  }

  cite {
    --cite-font-size: 18px;
    --cite-line-height: 18px;
    --cite-spacer: 30px;
  }
}

@media (min-width: 1921px) {
  blockquote {
    --bq-font-size: max(39px, 2vw);
    --bq-line-height: 1.2;
  }

  cite {
    --cite-font-size: max(18px, .8vw);
    --cite-line-height: 1;
  }
}

.image-parallax {
  background: center / contain no-repeat;
  margin: 15px 0;
  display: block;
}

@media (min-width: 1200px) {
  .image-parallax {
    height: 100vh;
    background-attachment: fixed;
    margin: 0;
    padding-top: 0 !important;
  }
}

.slider {
  color: inherit;
  background-color: #0000;
}

.slider img {
  max-width: 100%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  margin: 0 auto;
  display: flex;
}

.slider figcaption {
  text-align: center;
  font-size: var(--font-size-m);
  font-family: var(--font-family-sans);
}

.copyright {
  --copyright-spacer: 60px;
  --copyright-font-size: 14px;
  margin-top: var(--copyright-spacer);
  margin-bottom: var(--copyright-spacer);
  font-family: var(--font-family-sans);
  font-size: var(--copyright-font-size);
  font-weight: var(--font-weight-light);
  text-align: center;
}

@media (min-width: 768px) {
  .copyright {
    --copyright-spacer: 70px;
    --copyright-font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .copyright {
    --copyright-spacer: 90px;
    --copyright-font-size: 16px;
  }
}

@media (min-width: 1921px) {
  .copyright {
    --copyright-font-size: max(16px, .8vw);
  }
}

.share {
  --font-size: 20px;
  --line-height: 20px;
  --share-spacer: 80px;
  text-align: center;
  font-size: var(--font-size);
  margin: var(--share-spacer) auto;
}

.share > a {
  padding-left: var(--spacer-s);
  padding-right: var(--spacer-s);
  white-space: nowrap;
  display: inline-block;
}

.share > a + a {
  margin-top: var(--spacer-m);
}

.share a {
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  font-size: var(--font-size-xxm);
  font-family: var(--font-family-sans);
}

@media (min-width: 481px) {
  .share > a {
    padding-left: var(--spacer-m);
    padding-right: var(--spacer-m);
  }
}

@media (min-width: 768px) {
  .share {
    --share-spacer: 60px;
  }
}

@media (min-width: 1200px) {
  .share {
    --font-size: 26px;
    --line-height: 30px;
    --share-spacer: 120px;
  }

  .share > a + a {
    margin-top: 0;
  }
}

@media (min-width: 1921px) {
  .share img {
    width: auto;
    height: max(25px, 1.15vw);
  }
}

.tag-list {
  --tag-list-font-size: 26px;
  --tag-list-line-height: 26px;
  --tag-list-spacer: 40px;
  --tag-list-outer-spacer: var(--spacer-responsive-l);
  font-size: var(--tag-list-font-size);
  line-height: var(--tag-list-line-height);
  font-weight: var(--font-weight-medium);
}

.tag-list h1 {
  font-size: var(--tag-list-font-size);
  font-weight: var(--font-weight-medium);
  line-height: var(--tag-list-line-height);
  margin-top: 0;
}

.tag-list ul {
  padding: 0;
  list-style: none;
}

.tag-list li {
  margin-top: var(--tag-list-spacer);
  margin-bottom: var(--tag-list-spacer);
}

@media (min-width: 768px) {
  .tag-list {
    --tag-list-font-size: 40px;
    --tag-list-line-height: 40px;
    --tag-list-spacer: 30px;
  }
}

@media (min-width: 1200px) {
  .tag-list {
    --tag-list-font-size: 58px;
    --tag-list-line-height: 58px;
  }
}

:root {
  --accordion-offset: var(--spacer-responsive-l);
  --accordion-spacer: 26px;
  --accordion-link-font-size: 26px;
  --accordion-text-font-size: 16px;
  --accordion-line-height: 26px;
}

.accordion-wrapper {
  margin-top: var(--accordion-offset);
  margin-bottom: var(--accordion-offset);
}

.accordion {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin: -1px 0;
  line-height: 1.3;
}

.accordion-content * {
  font-family: var(--font-family-sans);
}

a:hover.accordion-link, a:active.accordion-link, a:focus.accordion-link {
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;
}

.accordion-link {
  font-size: var(--accordion-link-font-size);
  text-transform: uppercase;
  line-height: var(--accordion-line-height);
  padding-top: var(--accordion-spacer);
  padding-bottom: var(--accordion-spacer);
  font-weight: var(--font-weight-medium);
  display: block;
}

.accordion-content {
  padding-top: .5em;
  padding-bottom: var(--accordion-spacer);
  font-size: var(--accordion-text-font-size);
}

.accordion-content a:not(.arrow-before) {
  text-decoration: underline;
}

.accordion-content table {
  width: 100%;
}

.accordion-content table td {
  vertical-align: top;
}

.accordion-content table td:first-child {
  font-weight: var(--font-weight-medium);
  width: 25%;
}

.accordion a.link-download {
  margin-bottom: 1em;
}

.accordion address {
  font-weight: var(--font-weight-medium) !important;
}

.accordion .image {
  margin-top: 0;
  margin-bottom: 0;
}

.accordion-information h2 {
  margin-top: var(--spacer-responsive);
}

.accordion-information h3 {
  margin-top: 0;
  margin-bottom: 1em;
}

.accordion-information .image {
  margin-bottom: calc(var(--spacer-responsive) / 2);
}

.accordion-information .row + .row, .accordion-information .text-cols {
  margin-top: var(--spacer-responsive);
}

.accordion .accordion-content p, .accordion .accordion-content table {
  max-width: none;
  margin: 0 0 1.3em;
}

.accordion li, .accordion p + p {
  margin-top: 1em;
}

@media (max-width: 767px) {
  .accordion .contact [class*="col-"] + [class*="col-"], .accordion .contact .col + .col {
    margin-top: 1.3em;
  }
}

@media (min-width: 768px) {
  :root {
    --accordion-spacer: 35px;
    --accordion-link-font-size: 40px;
    --accordion-line-height: 40px;
    --accordion-text-font-size: 18px;
  }
}

@media (min-width: 1200px) {
  :root {
    --accordion-spacer: 30px;
    --accordion-link-font-size: 58px;
    --accordion-line-height: 58px;
  }
}

@media (min-width: 1921px) {
  :root {
    --accordion-text-font-size: max(18px, .9375vw);
  }
}

.filter-dropdown {
  --dropdown-spacer: 15px;
  margin-bottom: var(--dropdown-spacer);
  position: relative;
}

.filter-dropdown select {
  width: 100%;
  border: none;
}

.filter-dropdown select:before {
  content: attr(data-placeholder);
}

.filter-dropdown a.chosen-single {
  text-decoration: none;
  display: flex;
}

.filter-dropdown .chosen-container-single-nosearch .chosen-search {
  display: none;
}

.filter-dropdown a.chosen-single div {
  display: inline;
}

.filter-dropdown a.chosen-single.chosen-default div b:after {
  content: "";
  width: 10px;
  height: 12px;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyODAuNzIgNDUwIj4KCTxnPgoJCTxwYXRoIGQ9Ik0xNDAuMzYgMEwwIDE0MC4zNmwzMy4wNSAzMi42IDgyLjg2LTgzLjMxVjM1OS45bC04Mi44Ni04Mi44NkwwIDMwOS42NCAxNDAuMzYgNDUwbDE0MC4zNi0xNDAuMzYtMzMuMDUtMzIuNi04My4zMSA4My4zMVY4OS4ybDgzLjMxIDgzLjc2IDMzLjA1LTMyLjZMMTQwLjM2IDB6Ii8+Cgk8L2c+Cjwvc3ZnPgo=") no-repeat;
  margin-left: 4px;
  display: inline-block;
}

.filter-dropdown a.chosen-single div b:after {
  content: none;
}

.filter-dropdown .search-choice-close:after {
  content: "×";
  font-family: var(--font-family-serif);
  font-weight: var(--font-weight-bold);
  padding: 0 3px;
}

.filter-dropdown .chosen-drop {
  z-index: 100;
  padding: var(--padding-s);
  width: 100%;
  background: var(--color-bg);
  display: none;
  position: absolute;
}

.filter-dropdown .chosen-results {
  cursor: pointer;
}

.filter-dropdown .chosen-container-active .chosen-drop {
  display: block;
}

.filter-dropdown input {
  margin-bottom: var(--spacer-font-based);
  border: 1px solid var(--color-border);
  padding: var(--spacer-font-based-s) var(--spacer-font-based);
  width: 100%;
}

.filter-dropdown ul {
  margin: 0 0 0 var(--spacer-font-based);
  max-height: 50vh;
  padding: 1px 0;
  list-style: none;
  overflow: auto;
}

.filter-dropdown .chosen-unsupported {
  width: 100%;
  -webkit-appearance: textfield !important;
  appearance: textfield !important;
  outline: initial !important;
}

.filter-dropdowns {
  padding-left: 20px;
}

@media (min-width: 768px) {
  .filter-dropdowns {
    padding-left: 10px;
  }

  .filter-container .filter-dropdown .chosen-drop {
    width: -webkit-max-content;
    width: max-content;
    max-width: 345px;
    padding-right: 20px;
  }

  .filter-dropdown {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
}

@media (min-width: 1200px) {
  .filter-dropdowns.filter-dropdowns {
    padding-left: 0;
    margin-left: -8px !important;
  }

  .filter-dropdown {
    --dropdown-spacer: 30px;
  }
}

@media (min-width: 1921px) {
  .filter-dropdown a.chosen-single.chosen-default div b:after {
    width: max(10px, 1vw);
    height: max(12px, .6vw);
  }
}



:root {
  --partner-container-max-width: 810px;
  --partner-list-offset: var(--spacer-xl);
  --partner-item-spacer: 10px;
}

.baunetz-superheader .bn-partner {
  text-align: initial;
}

.partner {
  flex-wrap: wrap;
  display: flex;
}

.partner-list {
  width: 100%;
  max-width: var(--partner-container-max-width);
  will-change: opacity;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding-left: 0;
  list-style: none;
  transition: opacity 1s ease-in-out;
  display: inline-flex;
  overflow: hidden;
}

.partner-list-item {
  padding: 0 var(--partner-item-spacer);
  text-align: center;
  width: 100%;
  flex: 1 0 100%;
  margin: 10px 0;
}

.partner-list-item:nth-child(n+7) {
  display: none;
}

.partner-list-item a {
  height: 100%;
  display: inline-flex;
}

.partner-list-item img {
  max-width: 100%;
  height: auto;
  align-self: flex-start;
}

@media (min-width: 768px) {
  .partner {
    flex-wrap: nowrap;
  }

  .partner-list {
    max-width: var(--partner-container-max-width);
    padding-left: var(--spacer-xs);
    flex-wrap: nowrap;
    margin: auto 0 auto auto;
  }

  .partner-list-item {
    max-width: 100%;
    margin-bottom: 0;
  }

  .pageheader-partner .partner-list-item a {
    width: 110px;
  }
}

@media (min-width: 1200px) {
  .partner-list {
    padding-left: var(--spacer-xs);
    margin-right: var(--partner-list-offset);
  }
}

.video-extern.video-extern {
  width: 100%;
  height: auto;
  max-width: 1050px;
  margin: var(--spacer-m) auto;
  position: relative;
}

.video-extern:after {
  content: "";
  padding-bottom: 56.25%;
  display: block;
}

.video-extern iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-extern figcaption {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.download-list {
  --font-cut: var(--tile-header-font-cut-m);
}

.download-list .download-list-item {
  width: calc(100% - 1em);
  position: relative;
  top: -1.3em;
  left: 1em;
}

.download-list .download-list-item span {
  font-size: var(--font-size-xm);
  position: relative;
}

.download-list .download-list-item figure {
  margin-top: var(--font-cut);
}

.download-list .link-big {
  font-size: var(--font-size-xm);
}

.dossier {
  --link-font-size: 20px;
  --big-link-spacer: var(--spacer-responsive);
  --text-font-size: 18px;
  font-size: var(--text-font-size);
}

.dossier .intro {
  --font-size: 22px;
}

.dossier .grid-layout-1 {
  padding-bottom: var(--spacer-xl);
}

.dossier a.link-big {
  margin-top: var(--spacer-responsive);
}

.dossier .main-dossier .tile header, .dossier .grid.tiles-bold.tiles-large header {
  --font-cut: -.6em;
  margin-left: 0;
}

.dossier .main-dossier .tile header {
  --font-cut: -.8em;
}

.dossier .main-dossier {
  padding-bottom: var(--spacer-responsive-l);
}

.dossier .main-dossier h1 {
  font-size: var(--h1-font-size);
}

.dossier .main-dossier .tile {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.dossier .link-download {
  font-weight: var(--font-weight-medium);
  font-size: var(--link-font-size);
}

@media (min-width: 768px) {
  .dossier {
    --link-font-size: 26px;
    --text-font-size: 20px;
  }

  .dossier .intro {
    --font-size: 26px;
    margin-top: 55px;
  }

  .dossier .grid-layout-1 {
    padding-bottom: var(--spacer-l);
  }

  .dossier .main-dossier .tile header {
    --font-cut: -1.5em;
  }
}

@media (min-width: 1200px) {
  .dossier {
    --link-font-size: 20px;
  }

  .dossier .intro {
    --font-size: 39px;
    margin-top: 70px;
  }

  .dossier .text-cols .intro + p {
    margin-top: 0;
  }

  .dossier .main-dossier .tile header {
    --font-cut: -1.8em;
  }
}

@media (min-width: 1921px) {
  .dossier {
    --text-font-size: max(20px, 1.04vw);
    --link-font-size: max(20px, 1.04vw);
  }

  .dossier .intro {
    --font-size: max(39px, 2.03vw);
  }
}

.detail {
  --text-font-size: 16px;
  font-size: var(--text-font-size);
  line-height: var(--text-line-height);
}

.detail .heading h1, .detail .heading .h1 {
  line-height: 1.4;
  display: block;
}

.detail h2 {
  margin-top: calc(var(--spacer-responsive) * 2);
  margin-bottom: calc(var(--spacer-responsive) * 2);
}

@media (min-width: 768px) {
  .detail {
    --text-font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .detail {
    --text-font-size: 20px;
  }
}

@media (min-width: 1921px) {
  .detail {
    --text-font-size: max(20px, 1vw);
  }
}

.glossar-overlay {
  font-family: var(--font-family-serif);
  font-size: var(--font-size-s);
}

.glossar-overlay .window__close {
  top: 0;
}

.glossar-overlay .window__content {
  max-width: 500px;
  border-top: 1px solid #000;
  margin-top: 1rem;
}

.glossar-overlay h1 {
  font-family: var(--font-family-sans);
  -webkit-hyphens: auto;
  hyphens: auto;
  font-size: max(30px, 1.6vw);
}

.glossar-overlay {
  --padding: .8rem 1rem;
}

@media (min-width: 768px) {
  .glossar-overlay {
    --padding: 1rem 3.5rem 0 2rem;
  }

  .glossar-overlay .window__inner {
    min-width: 600px;
    max-width: 1100px;
    padding-bottom: 2rem;
  }
}

[data-module="textblock"] a[href^="/glossar/"] {
  background: url("data:image/gif;base64,R0lGODlhAQAFAIAAAM3NzQAAACH5BAAAAAAALAAAAAABAAUAAAIChF0AOw==") 0 95% repeat-x;
  text-decoration: none;
}

[data-module="textblock"] a[href^="/glossar/"]:hover, [data-module="textblock"] a[href^="/glossar/"]:focus, [data-module="textblock"] a[href^="/glossar/"]:active {
  background-color: #cdcdcd;
}

.glossar-gallery {
  grid-template-columns: 15px 1fr 15px;
  grid-template-areas: "prev main next";
  display: grid;
  position: relative;
}

.glossar-gallery [data-slider-previous] {
  height: 100%;
  font-size: var(--font-size-l);
  grid-area: prev;
  align-content: center;
}

.glossar-gallery [data-slider-next] {
  height: 100%;
  font-size: var(--font-size-l);
  grid-area: next;
  align-content: center;
  justify-self: end;
}

.glossar-footer {
  border-top: 1px solid #000;
  margin-top: 2rem;
  padding-top: 1rem;
}

.glossar-az-list {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-xxl);
  padding: 0;
  list-style: none;
}

.glossar-az-list span {
  color: var(--color-light-text);
}

.glossar-az-list li {
  display: inline-block;
}

.glossar-letters {
  overflow-x: hidden;
}

.glossar-has-article .glossar-letters {
  display: none;
}

.glossar-has-article .glossar-letters.is-visible {
  display: block;
}

.glossar-letter a {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.glossar-letter a.active {
  font-weight: bold;
}

.glossar-letter h2 {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-xxl);
  margin: 0;
  font-weight: 400;
}

.glossar-article {
  width: 100%;
  padding: 30px 15px 30px 0;
  position: relative;
}

.glossar-article h1 {
  -webkit-hyphens: auto;
  hyphens: auto;
  border-top: 1px solid #000;
}

.glossar-article .close {
  --close-width: 50px;
  --close-height: 50px;
  text-decoration: none;
  position: absolute;
  top: 42px;
  right: 0;
}

.glossar-article .close:before {
  content: "×";
  font-family: var(--font-family-serif);
  font-size: var(--font-size-ml);
  width: var(--close-width);
  height: var(--close-width);
  justify-content: center;
  align-items: center;
  display: flex;
}

.glossar-overlay .container {
  padding-left: 0;
  padding-right: 0;
}

.glossar-overlay .inline-slider .previous, .glossar-overlay .inline-slider .next {
  z-index: 1;
}

.glossar-placeholder-img {
  max-width: 100%;
  height: 83%;
  color: #000;
  font-family: var(--font-family-sans-light);
  background-color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 10rem;
  font-weight: 500;
  display: flex;
}

@media (min-width: 1000px) {
  .glossar-placeholder-img {
    font-size: max(15rem, 10vw);
  }

  .glossar-has-article .glossar-letters {
    display: block;
  }

  .glossar-section {
    flex-flow: row;
    gap: 20px;
    display: flex;
  }

  .glossar-article .close {
    display: none;
  }

  .glossar-letters {
    min-width: 150px;
  }
}

@media (min-width: 1200px) {
  .glossar-section {
    gap: 80px;
  }

  .glossar-article {
    padding: 30px 40px 30px 0;
    box-shadow: 20px 20px 10px #999;
  }
}

@media (min-width: 1400px) {
  .glossar-letters {
    max-width: max(300px, 20%);
    flex-shrink: 0;
  }
}



/*# sourceMappingURL=index.css.map */
