.ddoi {
	--min-height: 400px; /* 400px | 450px | 780px */
	--ddoi-header-font-size: 18px; /* 18px | 24px | 48px */

	min-height: var(--min-height);
	display: flex;
	width: 100%;
	text-align: center;
}

.ddoi header {
	font-size: var(--ddoi-header-font-size);
}

.ddoi .tile h2 {
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.ddoi {
		--min-height: 450px; /* 400px | 450px | 780px */
		--ddoi-header-font-size: 24px; /* 18px | 24px | 48px */
	}

	.ddoi .tile {
		max-width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 1200px) {
	.ddoi {
		--min-height: 780px; /* 400px | 450px | 780px */
		--ddoi-header-font-size: 48px; /* 18px | 24px | 48px */
	}
}

@media (min-width: 1281px) {
	.ddoi header {
		padding: 0 calc(min(100% - 1320px, 20%)); /* 1440 - 2*60 = 1320 */
	}
}

@media (min-width: 1921px) {
	.ddoi {
		/* > 48px */
		--ddoi-header-font-size: max(3rem, 2vw); /* wächst ab 2.400px über 48px (3 * 16) */
	}
}
