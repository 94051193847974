:root {
	--partner-container-max-width: 810px;
	--partner-list-offset: var(--spacer-xl);
	/*--partner-items-visible: calc(100% / 6);*/
	--partner-item-spacer: 10px;
}

.baunetz-superheader .bn-partner {
	text-align: initial;
}

.partner {
	display: flex;
	flex-wrap: wrap;
}

.partner-list {
	display: inline-flex;
	justify-content: space-between;
	flex-wrap: wrap;
	list-style: none;
	padding-left: 0;
	margin: 0;
	width: 100%;
	max-width: var(--partner-container-max-width);
	overflow: hidden;
	transition: opacity ease-in-out 1000ms;
	will-change: opacity;
}

.partner-list-item {
	padding: 0 var(--partner-item-spacer);
	text-align: center;
	width: 100%; /* will be overridden by partners.js */
	flex: 1 0 100%; /* will be overridden by partners.js */
	margin: 10px 0;
}

/* 6 visible elements + 1 */
.partner-list-item:nth-child(n+7) {
	display: none;
}

.partner-list-item a {
	height: 100%;
	display: inline-flex;
}
.partner-list-item img {
	max-width: 100%;
	align-self: flex-start;
	height: auto;
}

@media (min-width: 768px) {
	.partner {
		flex-wrap: nowrap;
	}

	.partner-list {
		max-width: var(--partner-container-max-width);
		flex-wrap: nowrap;
		margin: auto 0 auto auto;
		padding-left: var(--spacer-xs);
	}

	.partner-list-item {
		max-width: 100%;
		margin-bottom: 0;
	}

	.pageheader-partner .partner-list-item a {
		width: 110px;
	}
}

@media (min-width: 1200px) {
	.partner-list {
		padding-left: var(--spacer-xs);
		margin-right: var(--partner-list-offset);
	}
}
