/* noto-serif-kr-200 - latin */
@font-face {
	font-family: 'Noto Serif KR';
	font-style: normal;
	font-weight: 200;
	src: local('Noto Serif KR ExtraLight'), local('NotoSerifKR-ExtraLight'),
	url('../fonts/noto-serif-kr-v6-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../fonts/noto-serif-kr-v6-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* noto-serif-kr-300 - latin */
@font-face {
	font-family: 'Noto Serif KR';
	font-style: normal;
	font-weight: 300;
	src: local('Noto Serif KR Light'), local('NotoSerifKR-Light'),
	url('../fonts/noto-serif-kr-v6-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../fonts/noto-serif-kr-v6-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* noto-serif-kr-regular - latin */
@font-face {
	font-family: 'Noto Serif KR';
	font-style: normal;
	font-weight: 400;
	src: local('Noto Serif KR'), local('NotoSerifKR-Regular'),
	url('../fonts/noto-serif-kr-v6-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../fonts/noto-serif-kr-v6-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* noto-serif-kr-500 - latin */
@font-face {
	font-family: 'Noto Serif KR';
	font-style: normal;
	font-weight: 500;
	src: local('Noto Serif KR Medium'), local('NotoSerifKR-Medium'),
	url('../fonts/noto-serif-kr-v6-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../fonts/noto-serif-kr-v6-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* noto-serif-kr-600 - latin */
@font-face {
	font-family: 'Noto Serif KR';
	font-style: normal;
	font-weight: 600;
	src: local('Noto Serif KR SemiBold'), local('NotoSerifKR-SemiBold'),
	url('../fonts/noto-serif-kr-v6-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../fonts/noto-serif-kr-v6-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* noto-serif-kr-700 - latin */
@font-face {
	font-family: 'Noto Serif KR';
	font-style: normal;
	font-weight: 700;
	src: local('Noto Serif KR Bold'), local('NotoSerifKR-Bold'),
	url('../fonts/noto-serif-kr-v6-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../fonts/noto-serif-kr-v6-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* noto-serif-kr-900 - latin */
@font-face {
	font-family: 'Noto Serif KR';
	font-style: normal;
	font-weight: 900;
	src: local('Noto Serif KR Black'), local('NotoSerifKR-Black'),
	url('../fonts/noto-serif-kr-v6-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../fonts/noto-serif-kr-v6-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/* noto-sans-tc-100 - latin */
@font-face {
	font-family: 'Noto Sans TC';
	font-style: normal;
	font-weight: 100;
	src: local('Noto Sans TC Thin'), local('NotoSansTC-Thin'),
	url('../fonts/noto-sans-tc-v10-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../fonts/noto-sans-tc-v10-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* noto-sans-tc-regular - latin */
@font-face {
	font-family: 'Noto Sans TC';
	font-style: normal;
	font-weight: 400;
	src: local('Noto Sans TC Regular'), local('NotoSansTC-Regular'),
	url('../fonts/noto-sans-tc-v10-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../fonts/noto-sans-tc-v10-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* noto-sans-tc-500 - latin */
@font-face {
	font-family: 'Noto Sans TC';
	font-style: normal;
	font-weight: 500;
	src: local('Noto Sans TC Medium'), local('NotoSansTC-Medium'),
	url('../fonts/noto-sans-tc-v10-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../fonts/noto-sans-tc-v10-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* noto-sans-tc-300 - latin */
@font-face {
	font-family: 'Noto Sans TC';
	font-style: normal;
	font-weight: 300;
	src: local('Noto Sans TC Light'), local('NotoSansTC-Light'),
	url('../fonts/noto-sans-tc-v10-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../fonts/noto-sans-tc-v10-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* noto-sans-tc-700 - latin */
@font-face {
	font-family: 'Noto Sans TC';
	font-style: normal;
	font-weight: 700;
	src: local('Noto Sans TC Bold'), local('NotoSansTC-Bold'),
	url('../fonts/noto-sans-tc-v10-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../fonts/noto-sans-tc-v10-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}
/* noto-sans-tc-900 - latin */
@font-face {
	font-family: 'Noto Sans TC';
	font-style: normal;
	font-weight: 900;
	src: local('Noto Sans TC Black'), local('NotoSansTC-Black'),
	url('../fonts/noto-sans-tc-v10-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../fonts/noto-sans-tc-v10-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
	font-display: swap;
}

/*
	Suisse Intl
*/

@font-face {
	font-family: 'Suisse Intl';
	font-style: normal;
	font-weight: 400;
	src: local('Suisse Intl'),
		url('../fonts/SuisseIntl-Book-WebXL.woff2') format('woff2'),
		url('../fonts/SuisseIntl-Book-WebXL.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Suisse Intl';
	font-style: normal;
	font-weight: 500;
	src: local('Suisse Intl'),
		url('../fonts/SuisseIntl-Medium-WebXL.woff2') format('woff2'),
		url('../fonts/SuisseIntl-Medium-WebXL.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Suisse Intl';
	font-style: normal;
	font-weight: 800;
	src: local('Suisse Intl'),
		url('../fonts/SuisseIntl-Black-WebXL.woff2') format('woff2'),
		url('../fonts/SuisseIntl-Black-WebXL.woff') format('woff');
	font-display: swap;
}


/*
	serif
*/

/*@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	src: local('Playfair Display'),
		url('../fonts/playfair-display-v20-latin-regular.woff2') format('woff2'),
		url('../fonts/playfair-display-v20-latin-regular.woff') format('woff');
	font-display: swap;
}*/

@font-face {
	font-family: 'Suisse Works';
	font-style: normal;
	font-weight: 400;
	src: local('Suisse Works'),
		url('../fonts/SuisseWorks-Regular-WebXL.woff2') format('woff2'),
		url('../fonts/SuisseWorks-Regular-WebXL.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'Suisse Works';
	font-style: normal;
	font-weight: 700;
	src: local('Suisse Intl'),
	url('../fonts/SuisseWorks-Bold-WebXL.woff2') format('woff2'),
	url('../fonts/SuisseWorks-Bold-WebXL.woff') format('woff');
	font-display: swap;
}
