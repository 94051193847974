.detail {
	--text-font-size: 16px;

	font-size: var(--text-font-size);
	line-height: var(--text-line-height);
}

.detail .heading h1, .detail .heading .h1 {
	line-height: 1.4;
	display: block;
}

.detail h2 {
	margin-top: calc(var(--spacer-responsive) * 2);
	margin-bottom: calc(var(--spacer-responsive) * 2);
}

@media (min-width: 768px) {
	.detail {
		--text-font-size: 18px;
	}
}

@media (min-width: 1200px) {
	.detail {
		--text-font-size: 20px;
	}
}

@media (min-width: 1921px) {
	.detail {
		--text-font-size: max(20px, 1vw);
	}
}
