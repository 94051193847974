.image-parallax {
	display: block;
	background: scroll center center no-repeat;
	background-size: contain;
	margin: 15px 0;
}

@media (min-width: 1200px) {
	.image-parallax {
		background-attachment: fixed;
		height: 100vh;
		padding-top: 0 !important;
		margin: 0;
	}
}
