:root {
	/* tile header */
	--tile-header-font-cut-xl: -.48em;
	--tile-header-font-cut-l: -.48em;
	--tile-header-font-cut-m: -.48em;
	/* unitless is required here to work with different font sizes / fonts in one line */
	--tile-header-line-height: 1.1;
	--tile-spacer: 20px; /* 20 / 30 / 30 */

	--tile-header-font-size: 39px;
	--tile-header-max-width: calc(var(--page-content-max-width));
}

/* .tile-m, (default) */
.tile {
	--font-size: var(--font-size-m);
	--font-cut: var(--tile-header-font-cut-m);
	display: flex;
	flex-direction: column;
	grid-column: span var(--grid-col-span-m);
	grid-row: span var(--grid-row-span-m);
	padding: var(--tile-spacer);
	font-size: var(--font-size);
	width: 100%;
}

.tile.tile-s {
	--font-size: var(--font-size-m);
	--font-cut: var(--tile-header-font-cut-m);
	grid-column: span var(--grid-col-span-s);
	grid-row: span var(--grid-row-span-s);
}

.tile.tile-m {
	--font-size: var(--font-size-m);
	--font-cut: var(--tile-header-font-cut-m);
	grid-column: span var(--grid-col-span-m);
	grid-row: span var(--grid-row-span-m);
}

.tile.tile-l {
	--font-size: var(--font-size-l);
	--font-cut: var(--tile-header-font-cut-l);
	grid-column: span var(--grid-col-span-l);
	grid-row: span var(--grid-row-span-l);
}

.tile.tile-xl {
	--font-size: var(--font-size-xl);
	--font-cut: var(--tile-header-font-cut-xl);
	grid-column: span var(--grid-col-span-xl);
	grid-row: span var(--grid-row-span-xl);
	padding: var(--spacer-responsive);
}

.tile .tile-inner {
	max-width: var(--tile-header-max-width);
	margin-left: auto;
	margin-right: auto;
}

.tile .tile-inner.-full {
	height: 100%;
	margin-right: 0;
	margin-left: 0;
}

.tile header {
	position: relative;
	z-index: 1;
	margin-bottom: var(--font-cut);
	line-height: var(--tile-header-line-height);
	margin-right: auto;
}

.tile h1,
.tile h2 {
	display: inline;
	margin: 0;
	padding: 0;
	font-size: inherit;
	font-weight: var(--font-weight-medium);
}

.tile h1,
.tile .h1 {
	font-family: var(--font-family-sans);
	text-transform: uppercase;
}

.tile h2,
.tile .h2 {
	font-family: var(--font-family-serif);
	text-transform: none;
}

.tile figure {
	margin: 0 auto;
}

/*
.tile img {
	padding: 0 var(--spacer-font-based);
}
*/

.tile > a {
	display: inherit;
	flex-direction: inherit;
	/*margin: calc(var(--spacer-font-based) * -1);*/
	/*padding: inherit;*/
}

.tile > a:hover,
.tile > a:active,
.tile > a:focus {
	text-decoration: none;
}

.tile .copyright {
	margin: var(--padding) 0 0;
}

/* variant: bold font */
.tiles-bold .tile h1 {
	font-weight: var(--font-weight-black);
}

/* variant: large font */
.tiles-large .tile {
	--font-size: var(--font-size-xxl);
}

.tile.teaser {
	--teaser-font-size: 16px; /* - | 16px | 24px */
	--teaser-line-height: 1.17;

	font-size: var(--teaser-font-size);
	line-height: var(--teaser-line-height);
}

.tile.teaser .tile-inner {
	width: 100%;
	word-break: break-word;
}

.tile.teaser header h1 {
	text-transform: none;
}

.tile .tag {
	font-size: var(--text-font-size);
	font-family: var(--font-family-sans);
	font-weight: var(--font-weight-medium);
	text-align: center;
	text-transform: uppercase;
}

.tile p {
	margin-bottom: 0;
}

@media (min-width: 768px) {
	:root {
		--tile-spacer: 30px;
	}

	/* variant: large font */
	.tiles-large .tile,
	.tiles-large .tile-m {
		--font-size: var(--font-size-l);
		--font-cut: var(--tile-header-font-cut-l);
	}

	.tiles-large .tile-xl,
	.tiles-large .tile-l {
		--font-size: var(--font-size-xxl);
		--font-cut: var(--tile-header-font-cut-l);
	}
}

@media (min-width: 1200px) {
	.tile.teaser {
		--teaser-font-size: 24px;
		--teaser-line-height: 1.19;
	}

	.tile.teaser header h1 {
		font-size: 24px;
		line-height: 1.2;
	}

	/* stories slider for example has large font size only on desktop */
	.tile.header-big-lg {
		padding: var(--spacer-xs);
		font-size: var(--tile-header-font-size) !important;
	}
}

@media (min-width: 1281px) {
	/* stories slider for example has large font size only on desktop */
	.tile.header-big-lg {
		padding: max(30px, 1.5vw) max(30px, 2.5vw) !important;
	}

	.tile.header-big-lg header {
		max-width: max(580px, 24vw);
	}
}

@media (min-width: 1921px) {
	.tile.teaser {
		--teaser-font-size: max(24px, 1.25vw);
	}

	.tile.teaser header h1 {
		/*font-size: max(24px, 1vw);*/
	}

	.tile.header-big-lg {
		--tile-header-font-size: max(2.4375rem, 1.625vw);
	}
}
